import type { ProjectConfig } from '@/types/project-config/ProjectConfig'

const projectConfig: ProjectConfig = {
  emailSuffixs: [
    '@hotmail.com.mx',
    '@live.com.mx',
    '@outlook.com.mx',
    '@yahoo.com.mx',
    '@gmail.com',
    '@terra.com.mx',
    '@prodigy.net.mx',
    '@icloud.com',
    '@correo.com.mx',
    '@iname.com',
  ],
}

export default projectConfig

import { useCallback, useMemo } from 'react'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'

import atomGtag from '@/utils/gtag/atom-gtag'

import type { RequestRes } from '../../common/request'
import type { CommonBetInfo, RequestState } from '../atoms/common-state'
import { commonBetInfoAtom, commonBetRequestAtom } from '../atoms/common-state'
import useBetTmp from './useBetTmp'

export type BetParams = CommonBetInfo['value']

export function useGameBet<P extends BetParams, R>() {
  const [requestState, setRequestState] = useRecoilState(commonBetRequestAtom)
  const resetBetState = useResetRecoilState(commonBetRequestAtom)
  const setCommonBetInfo = useSetRecoilState(commonBetInfoAtom)
  const [_, setBetTmp] = useBetTmp()

  const bet = useCallback(
    async <P extends BetParams, R = any>(
      request: (params: P) => Promise<RequestRes<R>>,
      params: P,
      onSuccess?: () => void,
    ) => {
      setRequestState(
        getRequestBaseState({
          stage: 'loading',
          params,
        }),
      )

      const res = await request(params)

      if (res.code === 200 || !res.rsp.code) {
        // console.log('beted: bets successfully, run onSuccess')

        if (onSuccess) onSuccess()

        // 1 - set request status
        setRequestState(
          getRequestBaseState({
            stage: 'loaded',
            params,
            data: res.data || res.rsp.data,
          }),
        )
        // 2 - set common bet info
        setCommonBetInfo({
          id: new Date().getTime(),
          value: {
            amount: params.amount,
            odds: params.odds,
          },
        })
        setBetTmp(undefined)
      } else {
        setRequestState(
          getRequestBaseState({
            stage: 'loadfail',
            params,
            data: res?.rsp?.data || res,
          }),
        )
        setBetTmp(undefined)

        atomGtag('event', 'bet_failed', {
          event_name: 'bet_failed',
          event_label: 'bet_failed',
          value: res.message,
        })
      }
    },
    [],
  )

  return useMemo(
    () => ({
      bet,
      betRequestState: requestState as RequestState<P, R>,
      resetBetState,
    }),
    [bet, requestState, resetBetState],
  )
}

function getRequestBaseState<S, T>({
  stage,
  params,
  data,
}: {
  stage: 'loading' | 'loaded' | 'loadfail'
  params?: S
  data?: T
}) {
  return {
    loading: stage === 'loading',
    loaded: stage === 'loaded',
    loadfail: stage === 'loadfail',
    params,
    data,
  }
}

export default useGameBet

/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal.js'
import Long from 'long'
$protobuf.util.Long = Long
$protobuf.configure()

const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util

const $root = {}

export const protocols_game_config = ($root.protocols_game_config = (() => {
  const protocols_game_config = {}

  protocols_game_config.GameConfigReq = (function () {
    function GameConfigReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameConfigReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    GameConfigReq.prototype.configId = ''

    GameConfigReq.create = function create(properties) {
      return new GameConfigReq(properties)
    }

    GameConfigReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.configId != null && Object.hasOwnProperty.call(m, 'configId'))
        w.uint32(18).string(m.configId)
      return w
    }

    GameConfigReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.GameConfigReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.configId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameConfigReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.GameConfigReq'
    }

    return GameConfigReq
  })()

  protocols_game_config.GameConfigRes = (function () {
    function GameConfigRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameConfigRes.prototype.code = 0
    GameConfigRes.prototype.msg = ''
    GameConfigRes.prototype.data = ''

    GameConfigRes.create = function create(properties) {
      return new GameConfigRes(properties)
    }

    GameConfigRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        w.uint32(26).string(m.data)
      return w
    }

    GameConfigRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.GameConfigRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameConfigRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.GameConfigRes'
    }

    return GameConfigRes
  })()

  protocols_game_config.Empty = (function () {
    function Empty(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    Empty.create = function create(properties) {
      return new Empty(properties)
    }

    Empty.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    Empty.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.Empty()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.Empty'
    }

    return Empty
  })()

  protocols_game_config.GameListRes = (function () {
    function GameListRes(p) {
      this.data = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameListRes.prototype.code = 0
    GameListRes.prototype.msg = ''
    GameListRes.prototype.data = $util.emptyArray

    GameListRes.create = function create(properties) {
      return new GameListRes(properties)
    }

    GameListRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && m.data.length) {
        for (var i = 0; i < m.data.length; ++i)
          $root.protocols_game_config.GameDetail.encode(
            m.data[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      return w
    }

    GameListRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.GameListRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.data && m.data.length)) m.data = []
            m.data.push(
              $root.protocols_game_config.GameDetail.decode(r, r.uint32()),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameListRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.GameListRes'
    }

    return GameListRes
  })()

  protocols_game_config.GameDetail = (function () {
    function GameDetail(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameDetail.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0
    GameDetail.prototype.name = ''
    GameDetail.prototype.category = ''
    GameDetail.prototype.provider = ''
    GameDetail.prototype.coverUrl = ''
    GameDetail.prototype.status = 0
    GameDetail.prototype.updatedAt = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    GameDetail.prototype.tryPlaySwitch = 0
    GameDetail.prototype.hCoverUrl = ''
    GameDetail.prototype.gameCode = ''

    GameDetail.create = function create(properties) {
      return new GameDetail(properties)
    }

    GameDetail.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.id != null && Object.hasOwnProperty.call(m, 'id'))
        w.uint32(8).int64(m.id)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.category != null && Object.hasOwnProperty.call(m, 'category'))
        w.uint32(26).string(m.category)
      if (m.provider != null && Object.hasOwnProperty.call(m, 'provider'))
        w.uint32(34).string(m.provider)
      if (m.coverUrl != null && Object.hasOwnProperty.call(m, 'coverUrl'))
        w.uint32(42).string(m.coverUrl)
      if (m.status != null && Object.hasOwnProperty.call(m, 'status'))
        w.uint32(48).int32(m.status)
      if (m.updatedAt != null && Object.hasOwnProperty.call(m, 'updatedAt'))
        w.uint32(56).int64(m.updatedAt)
      if (
        m.tryPlaySwitch != null &&
        Object.hasOwnProperty.call(m, 'tryPlaySwitch')
      )
        w.uint32(64).int32(m.tryPlaySwitch)
      if (m.hCoverUrl != null && Object.hasOwnProperty.call(m, 'hCoverUrl'))
        w.uint32(74).string(m.hCoverUrl)
      if (m.gameCode != null && Object.hasOwnProperty.call(m, 'gameCode'))
        w.uint32(82).string(m.gameCode)
      return w
    }

    GameDetail.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.GameDetail()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.id = r.int64()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.category = r.string()
            break
          }
          case 4: {
            m.provider = r.string()
            break
          }
          case 5: {
            m.coverUrl = r.string()
            break
          }
          case 6: {
            m.status = r.int32()
            break
          }
          case 7: {
            m.updatedAt = r.int64()
            break
          }
          case 8: {
            m.tryPlaySwitch = r.int32()
            break
          }
          case 9: {
            m.hCoverUrl = r.string()
            break
          }
          case 10: {
            m.gameCode = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.GameDetail'
    }

    return GameDetail
  })()

  protocols_game_config.ClientConfigReq = (function () {
    function ClientConfigReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ClientConfigReq.prototype.gameId = ''
    ClientConfigReq.prototype.currency = ''

    ClientConfigReq.create = function create(properties) {
      return new ClientConfigReq(properties)
    }

    ClientConfigReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(10).string(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      return w
    }

    ClientConfigReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.ClientConfigReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.string()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ClientConfigReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.ClientConfigReq'
    }

    return ClientConfigReq
  })()

  protocols_game_config.ClientConfigRes = (function () {
    function ClientConfigRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ClientConfigRes.prototype.code = 0
    ClientConfigRes.prototype.msg = ''
    ClientConfigRes.prototype.data = null

    ClientConfigRes.create = function create(properties) {
      return new ClientConfigRes(properties)
    }

    ClientConfigRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_config.CliConfig.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    ClientConfigRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.ClientConfigRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_config.CliConfig.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ClientConfigRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.ClientConfigRes'
    }

    return ClientConfigRes
  })()

  protocols_game_config.CliConfig = (function () {
    function CliConfig(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CliConfig.prototype.common = ''
    CliConfig.prototype.clientConfig = ''
    CliConfig.prototype.tierDetail = null
    CliConfig.prototype.currencyDetail = null

    CliConfig.create = function create(properties) {
      return new CliConfig(properties)
    }

    CliConfig.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.common != null && Object.hasOwnProperty.call(m, 'common'))
        w.uint32(10).string(m.common)
      if (
        m.clientConfig != null &&
        Object.hasOwnProperty.call(m, 'clientConfig')
      )
        w.uint32(18).string(m.clientConfig)
      if (m.tierDetail != null && Object.hasOwnProperty.call(m, 'tierDetail'))
        $root.protocols_game_config.Tier.encode(
          m.tierDetail,
          w.uint32(26).fork(),
        ).ldelim()
      if (
        m.currencyDetail != null &&
        Object.hasOwnProperty.call(m, 'currencyDetail')
      )
        $root.protocols_game_config.Currency.encode(
          m.currencyDetail,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    CliConfig.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.CliConfig()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.common = r.string()
            break
          }
          case 2: {
            m.clientConfig = r.string()
            break
          }
          case 3: {
            m.tierDetail = $root.protocols_game_config.Tier.decode(
              r,
              r.uint32(),
            )
            break
          }
          case 4: {
            m.currencyDetail = $root.protocols_game_config.Currency.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CliConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.CliConfig'
    }

    return CliConfig
  })()

  protocols_game_config.Tier = (function () {
    function Tier(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    Tier.prototype.currency = ''
    Tier.prototype.minBet = ''
    Tier.prototype.maxBet = ''
    Tier.prototype.maxPayout = ''
    Tier.prototype.grades = ''
    Tier.prototype.jackpot = ''
    Tier.prototype.defaultBet = ''
    Tier.prototype.jackpotCoefficient = ''
    Tier.prototype.chips = ''

    Tier.create = function create(properties) {
      return new Tier(properties)
    }

    Tier.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.minBet != null && Object.hasOwnProperty.call(m, 'minBet'))
        w.uint32(18).string(m.minBet)
      if (m.maxBet != null && Object.hasOwnProperty.call(m, 'maxBet'))
        w.uint32(26).string(m.maxBet)
      if (m.maxPayout != null && Object.hasOwnProperty.call(m, 'maxPayout'))
        w.uint32(34).string(m.maxPayout)
      if (m.grades != null && Object.hasOwnProperty.call(m, 'grades'))
        w.uint32(42).string(m.grades)
      if (m.jackpot != null && Object.hasOwnProperty.call(m, 'jackpot'))
        w.uint32(50).string(m.jackpot)
      if (m.defaultBet != null && Object.hasOwnProperty.call(m, 'defaultBet'))
        w.uint32(58).string(m.defaultBet)
      if (
        m.jackpotCoefficient != null &&
        Object.hasOwnProperty.call(m, 'jackpotCoefficient')
      )
        w.uint32(66).string(m.jackpotCoefficient)
      if (m.chips != null && Object.hasOwnProperty.call(m, 'chips'))
        w.uint32(74).string(m.chips)
      return w
    }

    Tier.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.Tier()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.minBet = r.string()
            break
          }
          case 3: {
            m.maxBet = r.string()
            break
          }
          case 4: {
            m.maxPayout = r.string()
            break
          }
          case 5: {
            m.grades = r.string()
            break
          }
          case 6: {
            m.jackpot = r.string()
            break
          }
          case 7: {
            m.defaultBet = r.string()
            break
          }
          case 8: {
            m.jackpotCoefficient = r.string()
            break
          }
          case 9: {
            m.chips = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    Tier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.Tier'
    }

    return Tier
  })()

  protocols_game_config.Currency = (function () {
    function Currency(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    Currency.prototype.currency = ''
    Currency.prototype.currencyName = ''
    Currency.prototype.currencySign = ''
    Currency.prototype.currencyLayout = ''
    Currency.prototype.currencyLayoutInteger = ''

    Currency.create = function create(properties) {
      return new Currency(properties)
    }

    Currency.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (
        m.currencyName != null &&
        Object.hasOwnProperty.call(m, 'currencyName')
      )
        w.uint32(18).string(m.currencyName)
      if (
        m.currencySign != null &&
        Object.hasOwnProperty.call(m, 'currencySign')
      )
        w.uint32(26).string(m.currencySign)
      if (
        m.currencyLayout != null &&
        Object.hasOwnProperty.call(m, 'currencyLayout')
      )
        w.uint32(34).string(m.currencyLayout)
      if (
        m.currencyLayoutInteger != null &&
        Object.hasOwnProperty.call(m, 'currencyLayoutInteger')
      )
        w.uint32(42).string(m.currencyLayoutInteger)
      return w
    }

    Currency.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.Currency()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.currencyName = r.string()
            break
          }
          case 3: {
            m.currencySign = r.string()
            break
          }
          case 4: {
            m.currencyLayout = r.string()
            break
          }
          case 5: {
            m.currencyLayoutInteger = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    Currency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.Currency'
    }

    return Currency
  })()

  protocols_game_config.ProviderListRes = (function () {
    function ProviderListRes(p) {
      this.data = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ProviderListRes.prototype.code = 0
    ProviderListRes.prototype.msg = ''
    ProviderListRes.prototype.data = $util.emptyArray

    ProviderListRes.create = function create(properties) {
      return new ProviderListRes(properties)
    }

    ProviderListRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && m.data.length) {
        for (var i = 0; i < m.data.length; ++i)
          $root.protocols_game_config.ProviderDetail.encode(
            m.data[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      return w
    }

    ProviderListRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.ProviderListRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.data && m.data.length)) m.data = []
            m.data.push(
              $root.protocols_game_config.ProviderDetail.decode(r, r.uint32()),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ProviderListRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.ProviderListRes'
    }

    return ProviderListRes
  })()

  protocols_game_config.ProviderDetail = (function () {
    function ProviderDetail(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ProviderDetail.prototype.id = ''
    ProviderDetail.prototype.name = ''
    ProviderDetail.prototype.icon = ''

    ProviderDetail.create = function create(properties) {
      return new ProviderDetail(properties)
    }

    ProviderDetail.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.id != null && Object.hasOwnProperty.call(m, 'id'))
        w.uint32(10).string(m.id)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.icon != null && Object.hasOwnProperty.call(m, 'icon'))
        w.uint32(26).string(m.icon)
      return w
    }

    ProviderDetail.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.ProviderDetail()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.id = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.icon = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ProviderDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.ProviderDetail'
    }

    return ProviderDetail
  })()

  protocols_game_config.MatchRoomRankListReq = (function () {
    function MatchRoomRankListReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MatchRoomRankListReq.prototype.gameId = ''
    MatchRoomRankListReq.prototype.currency = ''

    MatchRoomRankListReq.create = function create(properties) {
      return new MatchRoomRankListReq(properties)
    }

    MatchRoomRankListReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(10).string(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      return w
    }

    MatchRoomRankListReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.MatchRoomRankListReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.string()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MatchRoomRankListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.MatchRoomRankListReq'
    }

    return MatchRoomRankListReq
  })()

  protocols_game_config.MatchRoomRankListResp = (function () {
    function MatchRoomRankListResp(p) {
      this.data = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MatchRoomRankListResp.prototype.code = 0
    MatchRoomRankListResp.prototype.msg = ''
    MatchRoomRankListResp.prototype.data = $util.emptyArray

    MatchRoomRankListResp.create = function create(properties) {
      return new MatchRoomRankListResp(properties)
    }

    MatchRoomRankListResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && m.data.length) {
        for (var i = 0; i < m.data.length; ++i)
          $root.protocols_game_config.MatchRoomRank.encode(
            m.data[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      return w
    }

    MatchRoomRankListResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.MatchRoomRankListResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.data && m.data.length)) m.data = []
            m.data.push(
              $root.protocols_game_config.MatchRoomRank.decode(r, r.uint32()),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MatchRoomRankListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.MatchRoomRankListResp'
    }

    return MatchRoomRankListResp
  })()

  protocols_game_config.MatchRoomRank = (function () {
    function MatchRoomRank(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MatchRoomRank.prototype.rankId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MatchRoomRank.prototype.name = ''
    MatchRoomRank.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MatchRoomRank.prototype.entryFee = ''
    MatchRoomRank.prototype.winReward = ''
    MatchRoomRank.prototype.lock = false
    MatchRoomRank.prototype.roomSize = 0
    MatchRoomRank.prototype.roomType = 0

    MatchRoomRank.create = function create(properties) {
      return new MatchRoomRank(properties)
    }

    MatchRoomRank.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.rankId != null && Object.hasOwnProperty.call(m, 'rankId'))
        w.uint32(8).uint64(m.rankId)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(24).uint64(m.gameId)
      if (m.entryFee != null && Object.hasOwnProperty.call(m, 'entryFee'))
        w.uint32(34).string(m.entryFee)
      if (m.winReward != null && Object.hasOwnProperty.call(m, 'winReward'))
        w.uint32(42).string(m.winReward)
      if (m.lock != null && Object.hasOwnProperty.call(m, 'lock'))
        w.uint32(48).bool(m.lock)
      if (m.roomSize != null && Object.hasOwnProperty.call(m, 'roomSize'))
        w.uint32(56).int32(m.roomSize)
      if (m.roomType != null && Object.hasOwnProperty.call(m, 'roomType'))
        w.uint32(64).int32(m.roomType)
      return w
    }

    MatchRoomRank.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_config.MatchRoomRank()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.rankId = r.uint64()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.gameId = r.uint64()
            break
          }
          case 4: {
            m.entryFee = r.string()
            break
          }
          case 5: {
            m.winReward = r.string()
            break
          }
          case 6: {
            m.lock = r.bool()
            break
          }
          case 7: {
            m.roomSize = r.int32()
            break
          }
          case 8: {
            m.roomType = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MatchRoomRank.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_config.MatchRoomRank'
    }

    return MatchRoomRank
  })()

  return protocols_game_config
})())

export const protocols_game = ($root.protocols_game = (() => {
  const protocols_game = {}

  protocols_game.GameError = (function () {
    function GameError(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameError.prototype.code = 0
    GameError.prototype.msg = ''

    GameError.create = function create(properties) {
      return new GameError(properties)
    }

    GameError.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      return w
    }

    GameError.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.GameError()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.GameError'
    }

    return GameError
  })()

  protocols_game.Balance = (function () {
    function Balance(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    Balance.prototype.currency = ''
    Balance.prototype.amount = ''

    Balance.create = function create(properties) {
      return new Balance(properties)
    }

    Balance.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(18).string(m.amount)
      return w
    }

    Balance.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.Balance()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.amount = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    Balance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.Balance'
    }

    return Balance
  })()

  protocols_game.AssetInfo = (function () {
    function AssetInfo(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    AssetInfo.prototype.currency = ''
    AssetInfo.prototype.balance = ''
    AssetInfo.prototype.change = ''

    AssetInfo.create = function create(properties) {
      return new AssetInfo(properties)
    }

    AssetInfo.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        w.uint32(18).string(m.balance)
      if (m.change != null && Object.hasOwnProperty.call(m, 'change'))
        w.uint32(26).string(m.change)
      return w
    }

    AssetInfo.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.AssetInfo()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.balance = r.string()
            break
          }
          case 3: {
            m.change = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    AssetInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.AssetInfo'
    }

    return AssetInfo
  })()

  protocols_game.BalanceInfo = (function () {
    function BalanceInfo(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BalanceInfo.prototype.balance = null
    BalanceInfo.prototype.updateTime = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    BalanceInfo.create = function create(properties) {
      return new BalanceInfo(properties)
    }

    BalanceInfo.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.updateTime != null && Object.hasOwnProperty.call(m, 'updateTime'))
        w.uint32(16).int64(m.updateTime)
      return w
    }

    BalanceInfo.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.BalanceInfo()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.updateTime = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BalanceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.BalanceInfo'
    }

    return BalanceInfo
  })()

  protocols_game.BalanceReq = (function () {
    function BalanceReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BalanceReq.prototype.currency = ''
    BalanceReq.prototype.merchantId = ''
    BalanceReq.prototype.userId = ''

    BalanceReq.create = function create(properties) {
      return new BalanceReq(properties)
    }

    BalanceReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(18).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(26).string(m.userId)
      return w
    }

    BalanceReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.BalanceReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.merchantId = r.string()
            break
          }
          case 3: {
            m.userId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BalanceReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.BalanceReq'
    }

    return BalanceReq
  })()

  protocols_game.BalanceResp = (function () {
    function BalanceResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BalanceResp.prototype.code = 0
    BalanceResp.prototype.msg = ''
    BalanceResp.prototype.data = null

    BalanceResp.create = function create(properties) {
      return new BalanceResp(properties)
    }

    BalanceResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game.BalanceInfo.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    BalanceResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.BalanceResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game.BalanceInfo.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BalanceResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.BalanceResp'
    }

    return BalanceResp
  })()

  protocols_game.PushGameRecord = (function () {
    function PushGameRecord(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    PushGameRecord.prototype.merchantId = ''
    PushGameRecord.prototype.userId = ''
    PushGameRecord.prototype.username = ''
    PushGameRecord.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    PushGameRecord.prototype.gameCode = ''
    PushGameRecord.prototype.amount = ''
    PushGameRecord.prototype.payout = ''
    PushGameRecord.prototype.multiplier = ''
    PushGameRecord.prototype.updatedAt = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    PushGameRecord.create = function create(properties) {
      return new PushGameRecord(properties)
    }

    PushGameRecord.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(10).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(18).string(m.userId)
      if (m.username != null && Object.hasOwnProperty.call(m, 'username'))
        w.uint32(26).string(m.username)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(32).int64(m.gameId)
      if (m.gameCode != null && Object.hasOwnProperty.call(m, 'gameCode'))
        w.uint32(42).string(m.gameCode)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(50).string(m.amount)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(58).string(m.payout)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(66).string(m.multiplier)
      if (m.updatedAt != null && Object.hasOwnProperty.call(m, 'updatedAt'))
        w.uint32(72).int64(m.updatedAt)
      return w
    }

    PushGameRecord.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.PushGameRecord()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.merchantId = r.string()
            break
          }
          case 2: {
            m.userId = r.string()
            break
          }
          case 3: {
            m.username = r.string()
            break
          }
          case 4: {
            m.gameId = r.int64()
            break
          }
          case 5: {
            m.gameCode = r.string()
            break
          }
          case 6: {
            m.amount = r.string()
            break
          }
          case 7: {
            m.payout = r.string()
            break
          }
          case 8: {
            m.multiplier = r.string()
            break
          }
          case 9: {
            m.updatedAt = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    PushGameRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.PushGameRecord'
    }

    return PushGameRecord
  })()

  protocols_game.GetLastestGameRecordReq = (function () {
    function GetLastestGameRecordReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GetLastestGameRecordReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    GetLastestGameRecordReq.create = function create(properties) {
      return new GetLastestGameRecordReq(properties)
    }

    GetLastestGameRecordReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).int64(m.gameId)
      return w
    }

    GetLastestGameRecordReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.GetLastestGameRecordReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GetLastestGameRecordReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.GetLastestGameRecordReq'
    }

    return GetLastestGameRecordReq
  })()

  protocols_game.GetLastestGameRecordResp = (function () {
    function GetLastestGameRecordResp(p) {
      this.data = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GetLastestGameRecordResp.prototype.code = 0
    GetLastestGameRecordResp.prototype.msg = ''
    GetLastestGameRecordResp.prototype.data = $util.emptyArray

    GetLastestGameRecordResp.create = function create(properties) {
      return new GetLastestGameRecordResp(properties)
    }

    GetLastestGameRecordResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && m.data.length) {
        for (var i = 0; i < m.data.length; ++i)
          $root.protocols_game.GameRecord.encode(
            m.data[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      return w
    }

    GetLastestGameRecordResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.GetLastestGameRecordResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.data && m.data.length)) m.data = []
            m.data.push($root.protocols_game.GameRecord.decode(r, r.uint32()))
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GetLastestGameRecordResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.GetLastestGameRecordResp'
    }

    return GetLastestGameRecordResp
  })()

  protocols_game.GameRecord = (function () {
    function GameRecord(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GameRecord.prototype.merchantId = ''
    GameRecord.prototype.userId = ''
    GameRecord.prototype.username = ''
    GameRecord.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    GameRecord.prototype.gameCode = ''
    GameRecord.prototype.amount = ''
    GameRecord.prototype.payout = ''
    GameRecord.prototype.multiplier = ''
    GameRecord.prototype.updatedAt = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    GameRecord.create = function create(properties) {
      return new GameRecord(properties)
    }

    GameRecord.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(10).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(18).string(m.userId)
      if (m.username != null && Object.hasOwnProperty.call(m, 'username'))
        w.uint32(26).string(m.username)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(32).int64(m.gameId)
      if (m.gameCode != null && Object.hasOwnProperty.call(m, 'gameCode'))
        w.uint32(42).string(m.gameCode)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(50).string(m.amount)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(58).string(m.payout)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(66).string(m.multiplier)
      if (m.updatedAt != null && Object.hasOwnProperty.call(m, 'updatedAt'))
        w.uint32(72).int64(m.updatedAt)
      return w
    }

    GameRecord.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.GameRecord()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.merchantId = r.string()
            break
          }
          case 2: {
            m.userId = r.string()
            break
          }
          case 3: {
            m.username = r.string()
            break
          }
          case 4: {
            m.gameId = r.int64()
            break
          }
          case 5: {
            m.gameCode = r.string()
            break
          }
          case 6: {
            m.amount = r.string()
            break
          }
          case 7: {
            m.payout = r.string()
            break
          }
          case 8: {
            m.multiplier = r.string()
            break
          }
          case 9: {
            m.updatedAt = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GameRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.GameRecord'
    }

    return GameRecord
  })()

  protocols_game.BroadJoin = (function () {
    function BroadJoin(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BroadJoin.prototype.uid = ''
    BroadJoin.prototype.name = ''
    BroadJoin.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    BroadJoin.prototype.roomId = ''
    BroadJoin.prototype.merchantId = ''
    BroadJoin.prototype.userId = ''

    BroadJoin.create = function create(properties) {
      return new BroadJoin(properties)
    }

    BroadJoin.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(24).uint64(m.gameId)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(34).string(m.roomId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(42).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(50).string(m.userId)
      return w
    }

    BroadJoin.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.BroadJoin()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.gameId = r.uint64()
            break
          }
          case 4: {
            m.roomId = r.string()
            break
          }
          case 5: {
            m.merchantId = r.string()
            break
          }
          case 6: {
            m.userId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BroadJoin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.BroadJoin'
    }

    return BroadJoin
  })()

  protocols_game.BroadLeave = (function () {
    function BroadLeave(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BroadLeave.prototype.uid = ''
    BroadLeave.prototype.name = ''
    BroadLeave.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    BroadLeave.prototype.roomId = ''
    BroadLeave.prototype.merchantId = ''
    BroadLeave.prototype.userId = ''

    BroadLeave.create = function create(properties) {
      return new BroadLeave(properties)
    }

    BroadLeave.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(24).uint64(m.gameId)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(34).string(m.roomId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(42).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(50).string(m.userId)
      return w
    }

    BroadLeave.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.BroadLeave()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.gameId = r.uint64()
            break
          }
          case 4: {
            m.roomId = r.string()
            break
          }
          case 5: {
            m.merchantId = r.string()
            break
          }
          case 6: {
            m.userId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BroadLeave.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.BroadLeave'
    }

    return BroadLeave
  })()

  protocols_game.State = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'Initial')] = 0
    values[(valuesById[1] = 'Win')] = 1
    values[(valuesById[2] = 'Lose')] = 2
    values[(valuesById[3] = 'CashOut')] = 3
    values[(valuesById[4] = 'Error')] = 4
    values[(valuesById[5] = 'Gaming')] = 5
    return values
  })()

  protocols_game.MatchState = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'Init')] = 0
    values[(valuesById[1] = 'Matching')] = 1
    values[(valuesById[2] = 'Confirming')] = 2
    values[(valuesById[3] = 'Canceled')] = 3
    values[(valuesById[4] = 'Success')] = 4
    values[(valuesById[5] = 'Fail')] = 5
    return values
  })()

  protocols_game.MatchRoomType = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'Low')] = 0
    values[(valuesById[1] = 'Medium')] = 1
    values[(valuesById[2] = 'High')] = 2
    return values
  })()

  protocols_game.MatchRankBalance = (function () {
    function MatchRankBalance(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MatchRankBalance.prototype.amount = ''
    MatchRankBalance.prototype.payout = ''

    MatchRankBalance.create = function create(properties) {
      return new MatchRankBalance(properties)
    }

    MatchRankBalance.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(10).string(m.amount)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(18).string(m.payout)
      return w
    }

    MatchRankBalance.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.MatchRankBalance()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.amount = r.string()
            break
          }
          case 2: {
            m.payout = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MatchRankBalance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.MatchRankBalance'
    }

    return MatchRankBalance
  })()

  protocols_game.UserGameRecordReq = (function () {
    function UserGameRecordReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    UserGameRecordReq.prototype.startTime = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    UserGameRecordReq.prototype.endTime = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    UserGameRecordReq.prototype.gameId = ''
    UserGameRecordReq.prototype.size = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    UserGameRecordReq.prototype.current = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    UserGameRecordReq.create = function create(properties) {
      return new UserGameRecordReq(properties)
    }

    UserGameRecordReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.startTime != null && Object.hasOwnProperty.call(m, 'startTime'))
        w.uint32(8).int64(m.startTime)
      if (m.endTime != null && Object.hasOwnProperty.call(m, 'endTime'))
        w.uint32(16).int64(m.endTime)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(26).string(m.gameId)
      if (m.size != null && Object.hasOwnProperty.call(m, 'size'))
        w.uint32(32).int64(m.size)
      if (m.current != null && Object.hasOwnProperty.call(m, 'current'))
        w.uint32(40).int64(m.current)
      return w
    }

    UserGameRecordReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.UserGameRecordReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.startTime = r.int64()
            break
          }
          case 2: {
            m.endTime = r.int64()
            break
          }
          case 3: {
            m.gameId = r.string()
            break
          }
          case 4: {
            m.size = r.int64()
            break
          }
          case 5: {
            m.current = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    UserGameRecordReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.UserGameRecordReq'
    }

    return UserGameRecordReq
  })()

  protocols_game.UserGameRecordResp = (function () {
    function UserGameRecordResp(p) {
      this.list = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    UserGameRecordResp.prototype.code = 0
    UserGameRecordResp.prototype.msg = ''
    UserGameRecordResp.prototype.list = $util.emptyArray
    UserGameRecordResp.prototype.total = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    UserGameRecordResp.create = function create(properties) {
      return new UserGameRecordResp(properties)
    }

    UserGameRecordResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.list != null && m.list.length) {
        for (var i = 0; i < m.list.length; ++i)
          $root.protocols_game.UserGameRecord.encode(
            m.list[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      if (m.total != null && Object.hasOwnProperty.call(m, 'total'))
        w.uint32(40).int64(m.total)
      return w
    }

    UserGameRecordResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.UserGameRecordResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.list && m.list.length)) m.list = []
            m.list.push(
              $root.protocols_game.UserGameRecord.decode(r, r.uint32()),
            )
            break
          }
          case 5: {
            m.total = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    UserGameRecordResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.UserGameRecordResp'
    }

    return UserGameRecordResp
  })()

  protocols_game.UserGameRecord = (function () {
    function UserGameRecord(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    UserGameRecord.prototype.id = ''
    UserGameRecord.prototype.uid = ''
    UserGameRecord.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    UserGameRecord.prototype.merchantId = ''
    UserGameRecord.prototype.currency = ''
    UserGameRecord.prototype.multiplier = ''
    UserGameRecord.prototype.balance = ''
    UserGameRecord.prototype.amount = ''
    UserGameRecord.prototype.payout = ''
    UserGameRecord.prototype.state = 0
    UserGameRecord.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    UserGameRecord.create = function create(properties) {
      return new UserGameRecord(properties)
    }

    UserGameRecord.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.id != null && Object.hasOwnProperty.call(m, 'id'))
        w.uint32(10).string(m.id)
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(18).string(m.uid)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(24).int64(m.gameId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(34).string(m.merchantId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(42).string(m.currency)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(50).string(m.multiplier)
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        w.uint32(58).string(m.balance)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(66).string(m.amount)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(74).string(m.payout)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(80).int32(m.state)
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(88).int64(m.createdAt)
      return w
    }

    UserGameRecord.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game.UserGameRecord()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.id = r.string()
            break
          }
          case 2: {
            m.uid = r.string()
            break
          }
          case 3: {
            m.gameId = r.int64()
            break
          }
          case 4: {
            m.merchantId = r.string()
            break
          }
          case 5: {
            m.currency = r.string()
            break
          }
          case 6: {
            m.multiplier = r.string()
            break
          }
          case 7: {
            m.balance = r.string()
            break
          }
          case 8: {
            m.amount = r.string()
            break
          }
          case 9: {
            m.payout = r.string()
            break
          }
          case 10: {
            m.state = r.int32()
            break
          }
          case 11: {
            m.createdAt = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    UserGameRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game.UserGameRecord'
    }

    return UserGameRecord
  })()

  return protocols_game
})())

export const protocols_game_crash = ($root.protocols_game_crash = (() => {
  const protocols_game_crash = {}

  protocols_game_crash.CrashState = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'standby')] = 0
    values[(valuesById[1] = 'bet')] = 1
    values[(valuesById[2] = 'readyfly')] = 2
    values[(valuesById[3] = 'fly')] = 3
    values[(valuesById[4] = 'endFly')] = 4
    values[(valuesById[5] = 'settle')] = 5
    return values
  })()

  protocols_game_crash.CrashJoinReqParams = (function () {
    function CrashJoinReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashJoinReqParams.create = function create(properties) {
      return new CrashJoinReqParams(properties)
    }

    CrashJoinReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    CrashJoinReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashJoinReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashJoinReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashJoinReqParams'
    }

    return CrashJoinReqParams
  })()

  protocols_game_crash.CrashJoinReq = (function () {
    function CrashJoinReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashJoinReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    CrashJoinReq.prototype.roomId = ''
    CrashJoinReq.prototype.currency = ''
    CrashJoinReq.prototype.params = null

    CrashJoinReq.create = function create(properties) {
      return new CrashJoinReq(properties)
    }

    CrashJoinReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(26).string(m.roomId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(34).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_game_crash.CrashJoinReqParams.encode(
          m.params,
          w.uint32(42).fork(),
        ).ldelim()
      return w
    }

    CrashJoinReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashJoinReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 3: {
            m.roomId = r.string()
            break
          }
          case 4: {
            m.currency = r.string()
            break
          }
          case 5: {
            m.params = $root.protocols_game_crash.CrashJoinReqParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashJoinReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashJoinReq'
    }

    return CrashJoinReq
  })()

  protocols_game_crash.CrashJoinResData = (function () {
    function CrashJoinResData(p) {
      this.history = []
      this.bet = []
      this.cashout = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashJoinResData.prototype.balance = null
    CrashJoinResData.prototype.roundId = ''
    CrashJoinResData.prototype.player = null
    CrashJoinResData.prototype.history = $util.emptyArray
    CrashJoinResData.prototype.bet = $util.emptyArray
    CrashJoinResData.prototype.cashout = $util.emptyArray
    CrashJoinResData.prototype.state = 0

    CrashJoinResData.create = function create(properties) {
      return new CrashJoinResData(properties)
    }

    CrashJoinResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(18).string(m.roundId)
      if (m.player != null && Object.hasOwnProperty.call(m, 'player'))
        $root.protocols_game_crash.CrashPlayer.encode(
          m.player,
          w.uint32(26).fork(),
        ).ldelim()
      if (m.history != null && m.history.length) {
        for (var i = 0; i < m.history.length; ++i)
          $root.protocols_game_crash.CrashHistory.encode(
            m.history[i],
            w.uint32(34).fork(),
          ).ldelim()
      }
      if (m.bet != null && m.bet.length) {
        for (var i = 0; i < m.bet.length; ++i)
          $root.protocols_game_crash.CrashBroadBet.encode(
            m.bet[i],
            w.uint32(42).fork(),
          ).ldelim()
      }
      if (m.cashout != null && m.cashout.length) {
        for (var i = 0; i < m.cashout.length; ++i)
          $root.protocols_game_crash.CrashBroadCashOut.encode(
            m.cashout[i],
            w.uint32(50).fork(),
          ).ldelim()
      }
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(56).int32(m.state)
      return w
    }

    CrashJoinResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashJoinResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.roundId = r.string()
            break
          }
          case 3: {
            m.player = $root.protocols_game_crash.CrashPlayer.decode(
              r,
              r.uint32(),
            )
            break
          }
          case 4: {
            if (!(m.history && m.history.length)) m.history = []
            m.history.push(
              $root.protocols_game_crash.CrashHistory.decode(r, r.uint32()),
            )
            break
          }
          case 5: {
            if (!(m.bet && m.bet.length)) m.bet = []
            m.bet.push(
              $root.protocols_game_crash.CrashBroadBet.decode(r, r.uint32()),
            )
            break
          }
          case 6: {
            if (!(m.cashout && m.cashout.length)) m.cashout = []
            m.cashout.push(
              $root.protocols_game_crash.CrashBroadCashOut.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 7: {
            m.state = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashJoinResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashJoinResData'
    }

    return CrashJoinResData
  })()

  protocols_game_crash.CrashJoinRes = (function () {
    function CrashJoinRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashJoinRes.prototype.code = 0
    CrashJoinRes.prototype.msg = ''
    CrashJoinRes.prototype.data = null

    CrashJoinRes.create = function create(properties) {
      return new CrashJoinRes(properties)
    }

    CrashJoinRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_crash.CrashJoinResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    CrashJoinRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashJoinRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_crash.CrashJoinResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashJoinRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashJoinRes'
    }

    return CrashJoinRes
  })()

  protocols_game_crash.CrashStartReqParams = (function () {
    function CrashStartReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashStartReqParams.prototype.prediction = 0
    CrashStartReqParams.prototype.auto = false

    CrashStartReqParams.create = function create(properties) {
      return new CrashStartReqParams(properties)
    }

    CrashStartReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.prediction != null && Object.hasOwnProperty.call(m, 'prediction'))
        w.uint32(8).uint32(m.prediction)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(16).bool(m.auto)
      return w
    }

    CrashStartReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashStartReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.prediction = r.uint32()
            break
          }
          case 2: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashStartReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashStartReqParams'
    }

    return CrashStartReqParams
  })()

  protocols_game_crash.CrashStartReq = (function () {
    function CrashStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    CrashStartReq.prototype.currency = ''
    CrashStartReq.prototype.amount = ''
    CrashStartReq.prototype.roomId = ''
    CrashStartReq.prototype.params = null

    CrashStartReq.create = function create(properties) {
      return new CrashStartReq(properties)
    }

    CrashStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(34).string(m.roomId)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_game_crash.CrashStartReqParams.encode(
          m.params,
          w.uint32(42).fork(),
        ).ldelim()
      return w
    }

    CrashStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.roomId = r.string()
            break
          }
          case 5: {
            m.params = $root.protocols_game_crash.CrashStartReqParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashStartReq'
    }

    return CrashStartReq
  })()

  protocols_game_crash.CrashStartResData = (function () {
    function CrashStartResData(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashStartResData.prototype.balance = null
    CrashStartResData.prototype.currency = ''
    CrashStartResData.prototype.amount = ''

    CrashStartResData.create = function create(properties) {
      return new CrashStartResData(properties)
    }

    CrashStartResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      return w
    }

    CrashStartResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashStartResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashStartResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashStartResData'
    }

    return CrashStartResData
  })()

  protocols_game_crash.CrashStartRes = (function () {
    function CrashStartRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashStartRes.prototype.code = 0
    CrashStartRes.prototype.msg = ''
    CrashStartRes.prototype.data = null

    CrashStartRes.create = function create(properties) {
      return new CrashStartRes(properties)
    }

    CrashStartRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_crash.CrashStartResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    CrashStartRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashStartRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_crash.CrashStartResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashStartRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashStartRes'
    }

    return CrashStartRes
  })()

  protocols_game_crash.CrashCashOutReq = (function () {
    function CrashCashOutReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashCashOutReq.create = function create(properties) {
      return new CrashCashOutReq(properties)
    }

    CrashCashOutReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    CrashCashOutReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashCashOutReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashCashOutReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashCashOutReq'
    }

    return CrashCashOutReq
  })()

  protocols_game_crash.CrashCashOutResData = (function () {
    function CrashCashOutResData(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashCashOutResData.prototype.balance = null
    CrashCashOutResData.prototype.multi = ''
    CrashCashOutResData.prototype.win = ''

    CrashCashOutResData.create = function create(properties) {
      return new CrashCashOutResData(properties)
    }

    CrashCashOutResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(18).string(m.multi)
      if (m.win != null && Object.hasOwnProperty.call(m, 'win'))
        w.uint32(26).string(m.win)
      return w
    }

    CrashCashOutResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashCashOutResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.multi = r.string()
            break
          }
          case 3: {
            m.win = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashCashOutResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashCashOutResData'
    }

    return CrashCashOutResData
  })()

  protocols_game_crash.CrashCashOutRes = (function () {
    function CrashCashOutRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashCashOutRes.prototype.code = 0
    CrashCashOutRes.prototype.msg = ''
    CrashCashOutRes.prototype.data = null

    CrashCashOutRes.create = function create(properties) {
      return new CrashCashOutRes(properties)
    }

    CrashCashOutRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_crash.CrashCashOutResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    CrashCashOutRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashCashOutRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_crash.CrashCashOutResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashCashOutRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashCashOutRes'
    }

    return CrashCashOutRes
  })()

  protocols_game_crash.CrashAutoReq = (function () {
    function CrashAutoReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashAutoReq.prototype.open = false
    CrashAutoReq.prototype.multi = 0

    CrashAutoReq.create = function create(properties) {
      return new CrashAutoReq(properties)
    }

    CrashAutoReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.open != null && Object.hasOwnProperty.call(m, 'open'))
        w.uint32(8).bool(m.open)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(16).uint32(m.multi)
      return w
    }

    CrashAutoReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashAutoReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.open = r.bool()
            break
          }
          case 2: {
            m.multi = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashAutoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashAutoReq'
    }

    return CrashAutoReq
  })()

  protocols_game_crash.CrashAutoResData = (function () {
    function CrashAutoResData(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashAutoResData.prototype.open = false
    CrashAutoResData.prototype.multi = 0

    CrashAutoResData.create = function create(properties) {
      return new CrashAutoResData(properties)
    }

    CrashAutoResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.open != null && Object.hasOwnProperty.call(m, 'open'))
        w.uint32(8).bool(m.open)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(16).uint32(m.multi)
      return w
    }

    CrashAutoResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashAutoResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.open = r.bool()
            break
          }
          case 2: {
            m.multi = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashAutoResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashAutoResData'
    }

    return CrashAutoResData
  })()

  protocols_game_crash.CrashAutoRes = (function () {
    function CrashAutoRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashAutoRes.prototype.code = 0
    CrashAutoRes.prototype.msg = ''
    CrashAutoRes.prototype.data = null

    CrashAutoRes.create = function create(properties) {
      return new CrashAutoRes(properties)
    }

    CrashAutoRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_crash.CrashAutoResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    CrashAutoRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashAutoRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_crash.CrashAutoResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashAutoRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashAutoRes'
    }

    return CrashAutoRes
  })()

  protocols_game_crash.CrashPlayer = (function () {
    function CrashPlayer(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashPlayer.prototype.bet = ''
    CrashPlayer.prototype.cashout = false
    CrashPlayer.prototype.payout = ''
    CrashPlayer.prototype.multi = ''
    CrashPlayer.prototype.auto = false
    CrashPlayer.prototype.prediction = 0
    CrashPlayer.prototype.uid = ''
    CrashPlayer.prototype.name = ''

    CrashPlayer.create = function create(properties) {
      return new CrashPlayer(properties)
    }

    CrashPlayer.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(10).string(m.bet)
      if (m.cashout != null && Object.hasOwnProperty.call(m, 'cashout'))
        w.uint32(16).bool(m.cashout)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(26).string(m.payout)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(34).string(m.multi)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(40).bool(m.auto)
      if (m.prediction != null && Object.hasOwnProperty.call(m, 'prediction'))
        w.uint32(48).uint32(m.prediction)
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(58).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(66).string(m.name)
      return w
    }

    CrashPlayer.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashPlayer()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.bet = r.string()
            break
          }
          case 2: {
            m.cashout = r.bool()
            break
          }
          case 3: {
            m.payout = r.string()
            break
          }
          case 4: {
            m.multi = r.string()
            break
          }
          case 5: {
            m.auto = r.bool()
            break
          }
          case 6: {
            m.prediction = r.uint32()
            break
          }
          case 7: {
            m.uid = r.string()
            break
          }
          case 8: {
            m.name = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashPlayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashPlayer'
    }

    return CrashPlayer
  })()

  protocols_game_crash.CrashBox = (function () {
    function CrashBox(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashBox.prototype.id = 0
    CrashBox.prototype.dis = 0

    CrashBox.create = function create(properties) {
      return new CrashBox(properties)
    }

    CrashBox.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.id != null && Object.hasOwnProperty.call(m, 'id'))
        w.uint32(8).int32(m.id)
      if (m.dis != null && Object.hasOwnProperty.call(m, 'dis'))
        w.uint32(16).int32(m.dis)
      return w
    }

    CrashBox.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashBox()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.id = r.int32()
            break
          }
          case 2: {
            m.dis = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashBox.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashBox'
    }

    return CrashBox
  })()

  protocols_game_crash.CrashGameFrame = (function () {
    function CrashGameFrame(p) {
      this.box = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashGameFrame.prototype.roomId = ''
    CrashGameFrame.prototype.roundId = ''
    CrashGameFrame.prototype.state = 0
    CrashGameFrame.prototype.countDown = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    CrashGameFrame.prototype.progress = 0
    CrashGameFrame.prototype.box = $util.emptyArray
    CrashGameFrame.prototype.result = 0
    CrashGameFrame.prototype.pool = ''

    CrashGameFrame.create = function create(properties) {
      return new CrashGameFrame(properties)
    }

    CrashGameFrame.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(10).string(m.roomId)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(18).string(m.roundId)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(24).int32(m.state)
      if (m.countDown != null && Object.hasOwnProperty.call(m, 'countDown'))
        w.uint32(32).int64(m.countDown)
      if (m.progress != null && Object.hasOwnProperty.call(m, 'progress'))
        w.uint32(40).uint32(m.progress)
      if (m.box != null && m.box.length) {
        for (var i = 0; i < m.box.length; ++i)
          $root.protocols_game_crash.CrashBox.encode(
            m.box[i],
            w.uint32(50).fork(),
          ).ldelim()
      }
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(56).uint32(m.result)
      if (m.pool != null && Object.hasOwnProperty.call(m, 'pool'))
        w.uint32(66).string(m.pool)
      return w
    }

    CrashGameFrame.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashGameFrame()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roomId = r.string()
            break
          }
          case 2: {
            m.roundId = r.string()
            break
          }
          case 3: {
            m.state = r.int32()
            break
          }
          case 4: {
            m.countDown = r.int64()
            break
          }
          case 5: {
            m.progress = r.uint32()
            break
          }
          case 6: {
            if (!(m.box && m.box.length)) m.box = []
            m.box.push(
              $root.protocols_game_crash.CrashBox.decode(r, r.uint32()),
            )
            break
          }
          case 7: {
            m.result = r.uint32()
            break
          }
          case 8: {
            m.pool = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashGameFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashGameFrame'
    }

    return CrashGameFrame
  })()

  protocols_game_crash.CrashHistory = (function () {
    function CrashHistory(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashHistory.prototype.roundId = ''
    CrashHistory.prototype.result = ''
    CrashHistory.prototype.ts = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    CrashHistory.create = function create(properties) {
      return new CrashHistory(properties)
    }

    CrashHistory.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(10).string(m.roundId)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(18).string(m.result)
      if (m.ts != null && Object.hasOwnProperty.call(m, 'ts'))
        w.uint32(24).int64(m.ts)
      return w
    }

    CrashHistory.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashHistory()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roundId = r.string()
            break
          }
          case 2: {
            m.result = r.string()
            break
          }
          case 3: {
            m.ts = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashHistory'
    }

    return CrashHistory
  })()

  protocols_game_crash.CrashBroadBet = (function () {
    function CrashBroadBet(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashBroadBet.prototype.uid = ''
    CrashBroadBet.prototype.name = ''
    CrashBroadBet.prototype.amount = ''
    CrashBroadBet.prototype.prediction = 0

    CrashBroadBet.create = function create(properties) {
      return new CrashBroadBet(properties)
    }

    CrashBroadBet.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.prediction != null && Object.hasOwnProperty.call(m, 'prediction'))
        w.uint32(32).uint32(m.prediction)
      return w
    }

    CrashBroadBet.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashBroadBet()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.prediction = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashBroadBet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashBroadBet'
    }

    return CrashBroadBet
  })()

  protocols_game_crash.CrashBroadCashOut = (function () {
    function CrashBroadCashOut(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashBroadCashOut.prototype.uid = ''
    CrashBroadCashOut.prototype.name = ''
    CrashBroadCashOut.prototype.bet = ''
    CrashBroadCashOut.prototype.payout = ''
    CrashBroadCashOut.prototype.multi = 0

    CrashBroadCashOut.create = function create(properties) {
      return new CrashBroadCashOut(properties)
    }

    CrashBroadCashOut.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(26).string(m.bet)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(34).string(m.payout)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(40).uint32(m.multi)
      return w
    }

    CrashBroadCashOut.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashBroadCashOut()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.bet = r.string()
            break
          }
          case 4: {
            m.payout = r.string()
            break
          }
          case 5: {
            m.multi = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashBroadCashOut.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashBroadCashOut'
    }

    return CrashBroadCashOut
  })()

  protocols_game_crash.CrashPlayerCashOut = (function () {
    function CrashPlayerCashOut(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashPlayerCashOut.prototype.bet = ''
    CrashPlayerCashOut.prototype.payout = ''
    CrashPlayerCashOut.prototype.multi = 0
    CrashPlayerCashOut.prototype.balance = null

    CrashPlayerCashOut.create = function create(properties) {
      return new CrashPlayerCashOut(properties)
    }

    CrashPlayerCashOut.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(10).string(m.bet)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(18).string(m.payout)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(24).uint32(m.multi)
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    CrashPlayerCashOut.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashPlayerCashOut()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.bet = r.string()
            break
          }
          case 2: {
            m.payout = r.string()
            break
          }
          case 3: {
            m.multi = r.uint32()
            break
          }
          case 4: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashPlayerCashOut.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashPlayerCashOut'
    }

    return CrashPlayerCashOut
  })()

  protocols_game_crash.CrashPlayerSettle = (function () {
    function CrashPlayerSettle(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashPlayerSettle.prototype.roundId = ''
    CrashPlayerSettle.prototype.bet = ''
    CrashPlayerSettle.prototype.payout = ''
    CrashPlayerSettle.prototype.multi = ''
    CrashPlayerSettle.prototype.prediction = 0
    CrashPlayerSettle.prototype.result = 0

    CrashPlayerSettle.create = function create(properties) {
      return new CrashPlayerSettle(properties)
    }

    CrashPlayerSettle.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(10).string(m.roundId)
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(18).string(m.bet)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(26).string(m.payout)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(34).string(m.multi)
      if (m.prediction != null && Object.hasOwnProperty.call(m, 'prediction'))
        w.uint32(40).uint32(m.prediction)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(48).uint32(m.result)
      return w
    }

    CrashPlayerSettle.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashPlayerSettle()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roundId = r.string()
            break
          }
          case 2: {
            m.bet = r.string()
            break
          }
          case 3: {
            m.payout = r.string()
            break
          }
          case 4: {
            m.multi = r.string()
            break
          }
          case 5: {
            m.prediction = r.uint32()
            break
          }
          case 6: {
            m.result = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashPlayerSettle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashPlayerSettle'
    }

    return CrashPlayerSettle
  })()

  protocols_game_crash.CrashBroadSettle = (function () {
    function CrashBroadSettle(p) {
      this.list = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    CrashBroadSettle.prototype.list = $util.emptyArray

    CrashBroadSettle.create = function create(properties) {
      return new CrashBroadSettle(properties)
    }

    CrashBroadSettle.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.list != null && m.list.length) {
        for (var i = 0; i < m.list.length; ++i)
          $root.protocols_game_crash.CrashBroadCashOut.encode(
            m.list[i],
            w.uint32(10).fork(),
          ).ldelim()
      }
      return w
    }

    CrashBroadSettle.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_crash.CrashBroadSettle()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            if (!(m.list && m.list.length)) m.list = []
            m.list.push(
              $root.protocols_game_crash.CrashBroadCashOut.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    CrashBroadSettle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_crash.CrashBroadSettle'
    }

    return CrashBroadSettle
  })()

  return protocols_game_crash
})())

export const protocols_dice = ($root.protocols_dice = (() => {
  const protocols_dice = {}

  protocols_dice.DiceStartReq = (function () {
    function DiceStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    DiceStartReq.prototype.currency = ''
    DiceStartReq.prototype.amount = ''
    DiceStartReq.prototype.params = null

    DiceStartReq.create = function create(properties) {
      return new DiceStartReq(properties)
    }

    DiceStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_dice.DiceStartParams.encode(
          m.params,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    DiceStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.params = $root.protocols_dice.DiceStartParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStartReq'
    }

    return DiceStartReq
  })()

  protocols_dice.DiceStartParams = (function () {
    function DiceStartParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStartParams.prototype.chosen = 0
    DiceStartParams.prototype.direction = 0
    DiceStartParams.prototype.auto = false

    DiceStartParams.create = function create(properties) {
      return new DiceStartParams(properties)
    }

    DiceStartParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.chosen != null && Object.hasOwnProperty.call(m, 'chosen'))
        w.uint32(8).uint32(m.chosen)
      if (m.direction != null && Object.hasOwnProperty.call(m, 'direction'))
        w.uint32(16).uint32(m.direction)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(24).bool(m.auto)
      return w
    }

    DiceStartParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStartParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.chosen = r.uint32()
            break
          }
          case 2: {
            m.direction = r.uint32()
            break
          }
          case 3: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStartParams'
    }

    return DiceStartParams
  })()

  protocols_dice.DiceStartResp = (function () {
    function DiceStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStartResp.prototype.code = 0
    DiceStartResp.prototype.msg = ''
    DiceStartResp.prototype.data = null

    DiceStartResp.create = function create(properties) {
      return new DiceStartResp(properties)
    }

    DiceStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_dice.DiceStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    DiceStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_dice.DiceStartRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStartResp'
    }

    return DiceStartResp
  })()

  protocols_dice.DiceStartRespParams = (function () {
    function DiceStartRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStartRespParams.prototype.balance = null
    DiceStartRespParams.prototype.multiplier = ''
    DiceStartRespParams.prototype.number = 0
    DiceStartRespParams.prototype.hash = ''
    DiceStartRespParams.prototype.payOut = ''
    DiceStartRespParams.prototype.result = 0
    DiceStartRespParams.prototype.roundId = ''
    DiceStartRespParams.prototype.updatedAt = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    DiceStartRespParams.create = function create(properties) {
      return new DiceStartRespParams(properties)
    }

    DiceStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(18).string(m.multiplier)
      if (m.number != null && Object.hasOwnProperty.call(m, 'number'))
        w.uint32(24).uint32(m.number)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(34).string(m.hash)
      if (m.payOut != null && Object.hasOwnProperty.call(m, 'payOut'))
        w.uint32(42).string(m.payOut)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(48).uint32(m.result)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(58).string(m.roundId)
      if (m.updatedAt != null && Object.hasOwnProperty.call(m, 'updatedAt'))
        w.uint32(64).int64(m.updatedAt)
      return w
    }

    DiceStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.multiplier = r.string()
            break
          }
          case 3: {
            m.number = r.uint32()
            break
          }
          case 4: {
            m.hash = r.string()
            break
          }
          case 5: {
            m.payOut = r.string()
            break
          }
          case 6: {
            m.result = r.uint32()
            break
          }
          case 7: {
            m.roundId = r.string()
            break
          }
          case 8: {
            m.updatedAt = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStartRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStartRespParams'
    }

    return DiceStartRespParams
  })()

  protocols_dice.DiceStatusReq = (function () {
    function DiceStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStatusReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    DiceStatusReq.prototype.currency = ''
    DiceStatusReq.prototype.params = null

    DiceStatusReq.create = function create(properties) {
      return new DiceStatusReq(properties)
    }

    DiceStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_dice.DiceStatusParams.encode(
          m.params,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    DiceStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.params = $root.protocols_dice.DiceStatusParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStatusReq'
    }

    return DiceStatusReq
  })()

  protocols_dice.DiceStatusParams = (function () {
    function DiceStatusParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStatusParams.create = function create(properties) {
      return new DiceStatusParams(properties)
    }

    DiceStatusParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    DiceStatusParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStatusParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStatusParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStatusParams'
    }

    return DiceStatusParams
  })()

  protocols_dice.DiceStatusResp = (function () {
    function DiceStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStatusResp.prototype.code = 0
    DiceStatusResp.prototype.msg = ''
    DiceStatusResp.prototype.data = null

    DiceStatusResp.create = function create(properties) {
      return new DiceStatusResp(properties)
    }

    DiceStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_dice.DiceStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    DiceStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_dice.DiceStatusRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStatusResp'
    }

    return DiceStatusResp
  })()

  protocols_dice.DiceStatusRespParams = (function () {
    function DiceStatusRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DiceStatusRespParams.prototype.balance = null
    DiceStatusRespParams.prototype.hash = ''

    DiceStatusRespParams.create = function create(properties) {
      return new DiceStatusRespParams(properties)
    }

    DiceStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(18).string(m.hash)
      return w
    }

    DiceStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_dice.DiceStatusRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.hash = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DiceStatusRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_dice.DiceStatusRespParams'
    }

    return DiceStatusRespParams
  })()

  return protocols_dice
})())

export const protocols_game_double = ($root.protocols_game_double = (() => {
  const protocols_game_double = {}

  protocols_game_double.DoubleState = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'Init')] = 0
    values[(valuesById[1] = 'Bet')] = 1
    values[(valuesById[2] = 'Open')] = 2
    values[(valuesById[3] = 'EndOpen')] = 3
    values[(valuesById[4] = 'Settle')] = 4
    return values
  })()

  protocols_game_double.DoubleColor = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'unknow')] = 0
    values[(valuesById[1] = 'white')] = 1
    values[(valuesById[2] = 'red')] = 2
    values[(valuesById[3] = 'black')] = 3
    return values
  })()

  protocols_game_double.ColorResult = (function () {
    function ColorResult(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ColorResult.prototype.color = 0
    ColorResult.prototype.num = ''
    ColorResult.prototype.ts = $util.Long ? $util.Long.fromBits(0, 0, false) : 0
    ColorResult.prototype.seed = ''
    ColorResult.prototype.hash = ''

    ColorResult.create = function create(properties) {
      return new ColorResult(properties)
    }

    ColorResult.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(8).int32(m.color)
      if (m.num != null && Object.hasOwnProperty.call(m, 'num'))
        w.uint32(18).string(m.num)
      if (m.ts != null && Object.hasOwnProperty.call(m, 'ts'))
        w.uint32(24).int64(m.ts)
      if (m.seed != null && Object.hasOwnProperty.call(m, 'seed'))
        w.uint32(34).string(m.seed)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      return w
    }

    ColorResult.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.ColorResult()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.color = r.int32()
            break
          }
          case 2: {
            m.num = r.string()
            break
          }
          case 3: {
            m.ts = r.int64()
            break
          }
          case 4: {
            m.seed = r.string()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ColorResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.ColorResult'
    }

    return ColorResult
  })()

  protocols_game_double.DoubleJoinReqParams = (function () {
    function DoubleJoinReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleJoinReqParams.create = function create(properties) {
      return new DoubleJoinReqParams(properties)
    }

    DoubleJoinReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    DoubleJoinReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleJoinReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleJoinReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleJoinReqParams'
    }

    return DoubleJoinReqParams
  })()

  protocols_game_double.DoubleJoinReq = (function () {
    function DoubleJoinReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleJoinReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    DoubleJoinReq.prototype.roomId = ''
    DoubleJoinReq.prototype.currency = ''
    DoubleJoinReq.prototype.params = null

    DoubleJoinReq.create = function create(properties) {
      return new DoubleJoinReq(properties)
    }

    DoubleJoinReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(26).string(m.roomId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(34).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_game_double.DoubleJoinReqParams.encode(
          m.params,
          w.uint32(42).fork(),
        ).ldelim()
      return w
    }

    DoubleJoinReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleJoinReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 3: {
            m.roomId = r.string()
            break
          }
          case 4: {
            m.currency = r.string()
            break
          }
          case 5: {
            m.params = $root.protocols_game_double.DoubleJoinReqParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleJoinReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleJoinReq'
    }

    return DoubleJoinReq
  })()

  protocols_game_double.DoubleJoinResData = (function () {
    function DoubleJoinResData(p) {
      this.player = []
      this.history = []
      this.bet = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleJoinResData.prototype.balance = null
    DoubleJoinResData.prototype.roundId = ''
    DoubleJoinResData.prototype.player = $util.emptyArray
    DoubleJoinResData.prototype.history = $util.emptyArray
    DoubleJoinResData.prototype.bet = $util.emptyArray
    DoubleJoinResData.prototype.state = 0
    DoubleJoinResData.prototype.countDown = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0
    DoubleJoinResData.prototype.uid = ''

    DoubleJoinResData.create = function create(properties) {
      return new DoubleJoinResData(properties)
    }

    DoubleJoinResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(18).string(m.roundId)
      if (m.player != null && m.player.length) {
        for (var i = 0; i < m.player.length; ++i)
          $root.protocols_game_double.DoublePlayer.encode(
            m.player[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      if (m.history != null && m.history.length) {
        for (var i = 0; i < m.history.length; ++i)
          $root.protocols_game_double.ColorResult.encode(
            m.history[i],
            w.uint32(34).fork(),
          ).ldelim()
      }
      if (m.bet != null && m.bet.length) {
        for (var i = 0; i < m.bet.length; ++i)
          $root.protocols_game_double.DoublePlayer.encode(
            m.bet[i],
            w.uint32(42).fork(),
          ).ldelim()
      }
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(48).int32(m.state)
      if (m.countDown != null && Object.hasOwnProperty.call(m, 'countDown'))
        w.uint32(56).int64(m.countDown)
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(66).string(m.uid)
      return w
    }

    DoubleJoinResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleJoinResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.roundId = r.string()
            break
          }
          case 3: {
            if (!(m.player && m.player.length)) m.player = []
            m.player.push(
              $root.protocols_game_double.DoublePlayer.decode(r, r.uint32()),
            )
            break
          }
          case 4: {
            if (!(m.history && m.history.length)) m.history = []
            m.history.push(
              $root.protocols_game_double.ColorResult.decode(r, r.uint32()),
            )
            break
          }
          case 5: {
            if (!(m.bet && m.bet.length)) m.bet = []
            m.bet.push(
              $root.protocols_game_double.DoublePlayer.decode(r, r.uint32()),
            )
            break
          }
          case 6: {
            m.state = r.int32()
            break
          }
          case 7: {
            m.countDown = r.int64()
            break
          }
          case 8: {
            m.uid = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleJoinResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleJoinResData'
    }

    return DoubleJoinResData
  })()

  protocols_game_double.DoubleJoinRes = (function () {
    function DoubleJoinRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleJoinRes.prototype.code = 0
    DoubleJoinRes.prototype.msg = ''
    DoubleJoinRes.prototype.data = null

    DoubleJoinRes.create = function create(properties) {
      return new DoubleJoinRes(properties)
    }

    DoubleJoinRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_double.DoubleJoinResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    DoubleJoinRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleJoinRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_double.DoubleJoinResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleJoinRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleJoinRes'
    }

    return DoubleJoinRes
  })()

  protocols_game_double.DoubleStartReqParams = (function () {
    function DoubleStartReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleStartReqParams.prototype.color = 0
    DoubleStartReqParams.prototype.auto = false

    DoubleStartReqParams.create = function create(properties) {
      return new DoubleStartReqParams(properties)
    }

    DoubleStartReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(8).int32(m.color)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(16).bool(m.auto)
      return w
    }

    DoubleStartReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleStartReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.color = r.int32()
            break
          }
          case 2: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleStartReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleStartReqParams'
    }

    return DoubleStartReqParams
  })()

  protocols_game_double.DoubleStartReq = (function () {
    function DoubleStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    DoubleStartReq.prototype.currency = ''
    DoubleStartReq.prototype.amount = ''
    DoubleStartReq.prototype.roomId = ''
    DoubleStartReq.prototype.params = null

    DoubleStartReq.create = function create(properties) {
      return new DoubleStartReq(properties)
    }

    DoubleStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(34).string(m.roomId)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_game_double.DoubleStartReqParams.encode(
          m.params,
          w.uint32(42).fork(),
        ).ldelim()
      return w
    }

    DoubleStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.roomId = r.string()
            break
          }
          case 5: {
            m.params = $root.protocols_game_double.DoubleStartReqParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleStartReq'
    }

    return DoubleStartReq
  })()

  protocols_game_double.DoubleStartResData = (function () {
    function DoubleStartResData(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleStartResData.prototype.balance = null
    DoubleStartResData.prototype.color = 0
    DoubleStartResData.prototype.amount = ''

    DoubleStartResData.create = function create(properties) {
      return new DoubleStartResData(properties)
    }

    DoubleStartResData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(16).int32(m.color)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      return w
    }

    DoubleStartResData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleStartResData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.color = r.int32()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleStartResData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleStartResData'
    }

    return DoubleStartResData
  })()

  protocols_game_double.DoubleStartRes = (function () {
    function DoubleStartRes(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleStartRes.prototype.code = 0
    DoubleStartRes.prototype.msg = ''
    DoubleStartRes.prototype.data = null

    DoubleStartRes.create = function create(properties) {
      return new DoubleStartRes(properties)
    }

    DoubleStartRes.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_game_double.DoubleStartResData.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    DoubleStartRes.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleStartRes()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_game_double.DoubleStartResData.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleStartRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleStartRes'
    }

    return DoubleStartRes
  })()

  protocols_game_double.DoubleBetInfo = (function () {
    function DoubleBetInfo(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleBetInfo.prototype.color = 0
    DoubleBetInfo.prototype.amount = ''

    DoubleBetInfo.create = function create(properties) {
      return new DoubleBetInfo(properties)
    }

    DoubleBetInfo.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(8).int32(m.color)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(18).string(m.amount)
      return w
    }

    DoubleBetInfo.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleBetInfo()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.color = r.int32()
            break
          }
          case 2: {
            m.amount = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleBetInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleBetInfo'
    }

    return DoubleBetInfo
  })()

  protocols_game_double.DoublePlayer = (function () {
    function DoublePlayer(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoublePlayer.prototype.uid = ''
    DoublePlayer.prototype.name = ''
    DoublePlayer.prototype.bet = ''
    DoublePlayer.prototype.payout = ''
    DoublePlayer.prototype.color = 0
    DoublePlayer.prototype.ts = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    DoublePlayer.create = function create(properties) {
      return new DoublePlayer(properties)
    }

    DoublePlayer.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(26).string(m.bet)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(34).string(m.payout)
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(40).int32(m.color)
      if (m.ts != null && Object.hasOwnProperty.call(m, 'ts'))
        w.uint32(48).int64(m.ts)
      return w
    }

    DoublePlayer.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoublePlayer()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.bet = r.string()
            break
          }
          case 4: {
            m.payout = r.string()
            break
          }
          case 5: {
            m.color = r.int32()
            break
          }
          case 6: {
            m.ts = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoublePlayer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoublePlayer'
    }

    return DoublePlayer
  })()

  protocols_game_double.DoubleGameFrame = (function () {
    function DoubleGameFrame(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleGameFrame.prototype.roomId = ''
    DoubleGameFrame.prototype.roundId = ''
    DoubleGameFrame.prototype.state = 0
    DoubleGameFrame.prototype.result = null
    DoubleGameFrame.prototype.pool = ''
    DoubleGameFrame.prototype.countDown = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    DoubleGameFrame.create = function create(properties) {
      return new DoubleGameFrame(properties)
    }

    DoubleGameFrame.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(10).string(m.roomId)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(18).string(m.roundId)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(24).int32(m.state)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        $root.protocols_game_double.ColorResult.encode(
          m.result,
          w.uint32(34).fork(),
        ).ldelim()
      if (m.pool != null && Object.hasOwnProperty.call(m, 'pool'))
        w.uint32(42).string(m.pool)
      if (m.countDown != null && Object.hasOwnProperty.call(m, 'countDown'))
        w.uint32(48).int64(m.countDown)
      return w
    }

    DoubleGameFrame.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleGameFrame()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roomId = r.string()
            break
          }
          case 2: {
            m.roundId = r.string()
            break
          }
          case 3: {
            m.state = r.int32()
            break
          }
          case 4: {
            m.result = $root.protocols_game_double.ColorResult.decode(
              r,
              r.uint32(),
            )
            break
          }
          case 5: {
            m.pool = r.string()
            break
          }
          case 6: {
            m.countDown = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleGameFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleGameFrame'
    }

    return DoubleGameFrame
  })()

  protocols_game_double.DoubleHistory = (function () {
    function DoubleHistory(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleHistory.prototype.roundId = ''
    DoubleHistory.prototype.result = null
    DoubleHistory.prototype.ts = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0

    DoubleHistory.create = function create(properties) {
      return new DoubleHistory(properties)
    }

    DoubleHistory.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(10).string(m.roundId)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        $root.protocols_game_double.ColorResult.encode(
          m.result,
          w.uint32(18).fork(),
        ).ldelim()
      if (m.ts != null && Object.hasOwnProperty.call(m, 'ts'))
        w.uint32(24).int64(m.ts)
      return w
    }

    DoubleHistory.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleHistory()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roundId = r.string()
            break
          }
          case 2: {
            m.result = $root.protocols_game_double.ColorResult.decode(
              r,
              r.uint32(),
            )
            break
          }
          case 3: {
            m.ts = r.int64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleHistory'
    }

    return DoubleHistory
  })()

  protocols_game_double.DoubleBroadBet = (function () {
    function DoubleBroadBet(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoubleBroadBet.prototype.uid = ''
    DoubleBroadBet.prototype.name = ''
    DoubleBroadBet.prototype.amount = ''
    DoubleBroadBet.prototype.color = 0

    DoubleBroadBet.create = function create(properties) {
      return new DoubleBroadBet(properties)
    }

    DoubleBroadBet.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.uid != null && Object.hasOwnProperty.call(m, 'uid'))
        w.uint32(10).string(m.uid)
      if (m.name != null && Object.hasOwnProperty.call(m, 'name'))
        w.uint32(18).string(m.name)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.color != null && Object.hasOwnProperty.call(m, 'color'))
        w.uint32(32).int32(m.color)
      return w
    }

    DoubleBroadBet.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoubleBroadBet()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.uid = r.string()
            break
          }
          case 2: {
            m.name = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.color = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoubleBroadBet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoubleBroadBet'
    }

    return DoubleBroadBet
  })()

  protocols_game_double.DoublePlayerSettle = (function () {
    function DoublePlayerSettle(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    DoublePlayerSettle.prototype.roundId = ''
    DoublePlayerSettle.prototype.bet = ''
    DoublePlayerSettle.prototype.payout = ''
    DoublePlayerSettle.prototype.multi = ''
    DoublePlayerSettle.prototype.result = null
    DoublePlayerSettle.prototype.balance = null

    DoublePlayerSettle.create = function create(properties) {
      return new DoublePlayerSettle(properties)
    }

    DoublePlayerSettle.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(10).string(m.roundId)
      if (m.bet != null && Object.hasOwnProperty.call(m, 'bet'))
        w.uint32(18).string(m.bet)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(26).string(m.payout)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(34).string(m.multi)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        $root.protocols_game_double.ColorResult.encode(
          m.result,
          w.uint32(50).fork(),
        ).ldelim()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(58).fork(),
        ).ldelim()
      return w
    }

    DoublePlayerSettle.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_game_double.DoublePlayerSettle()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.roundId = r.string()
            break
          }
          case 2: {
            m.bet = r.string()
            break
          }
          case 3: {
            m.payout = r.string()
            break
          }
          case 4: {
            m.multi = r.string()
            break
          }
          case 6: {
            m.result = $root.protocols_game_double.ColorResult.decode(
              r,
              r.uint32(),
            )
            break
          }
          case 7: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    DoublePlayerSettle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_game_double.DoublePlayerSettle'
    }

    return DoublePlayerSettle
  })()

  return protocols_game_double
})())

export const protocols_common = ($root.protocols_common = (() => {
  const protocols_common = {}

  protocols_common.LoginType = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'LOGINTYPE')] = 0
    values[(valuesById[1] = 'ACCOUNTPASSWORD')] = 1
    values[(valuesById[2] = 'VISITOR')] = 2
    values[(valuesById[3] = 'EMAIL')] = 3
    values[(valuesById[4] = 'PHONE')] = 4
    values[(valuesById[5] = 'GOOGLE')] = 5
    values[(valuesById[6] = 'FACEBOOK')] = 6
    values[(valuesById[7] = 'USERID')] = 7
    return values
  })()

  protocols_common.TokenExpire = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'NOEXPIRE')] = 0
    values[(valuesById[604800] = 'DEFAULT')] = 604800
    return values
  })()

  protocols_common.UserStatus = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'NULL')] = 0
    values[(valuesById[1] = 'VALID')] = 1
    values[(valuesById[2] = 'INVALID')] = 2
    return values
  })()

  protocols_common.MerchantClient = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'MERCHANTCLIENT')] = 0
    values[(valuesById[1] = 'UNITY')] = 1
    values[(valuesById[2] = 'H5')] = 2
    return values
  })()

  protocols_common.ERROR_COMMON = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'SUCCESS')] = 0
    values[(valuesById[100000] = 'UNKNOWN')] = 100000
    values[(valuesById[100001] = 'SYSTEM_ERROR')] = 100001
    values[(valuesById[100002] = 'PARAM_ERROR')] = 100002
    values[(valuesById[100004] = 'FORBIDDEN')] = 100004
    values[(valuesById[100005] = 'PERMISSIONDENIED')] = 100005
    values[(valuesById[100006] = 'REPEATED_REQUEST')] = 100006
    values[(valuesById[100007] = 'CONFIG_NOT_EXIT')] = 100007
    return values
  })()

  protocols_common.ERROR_GATE = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'ET_NULL')] = 0
    values[(valuesById[100500] = 'SIGN_PARAMS_ERR')] = 100500
    values[(valuesById[100501] = 'SIGN_ERR')] = 100501
    values[(valuesById[100102] = 'MERCHANT_ERR')] = 100102
    return values
  })()

  protocols_common.ERROR_LOGIN = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'EL_NULL')] = 0
    values[(valuesById[101001] = 'LOGIN_FAIL')] = 101001
    values[(valuesById[101002] = 'TOKEN_EXPIRE')] = 101002
    values[(valuesById[101003] = 'TYPE_ERROR')] = 101003
    values[(valuesById[101004] = 'BLACK')] = 101004
    values[(valuesById[101005] = 'USER_EXISTS')] = 101005
    values[(valuesById[101006] = 'USER_NOT_EXISTS')] = 101006
    values[(valuesById[101007] = 'GOOGLE_AUTH_FAIL')] = 101007
    values[(valuesById[101008] = 'FACEBOOK_AUTH_FAIL')] = 101008
    values[(valuesById[101009] = 'BIND_FAIL')] = 101009
    values[(valuesById[101010] = 'ACCOUNT_ERROR')] = 101010
    values[(valuesById[101011] = 'GAME_NOT_FOUND')] = 101011
    values[(valuesById[101012] = 'GAME_NOT_ENABLE')] = 101012
    return values
  })()

  protocols_common.ERROR_USERS = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'EU_NULL')] = 0
    values[(valuesById[102001] = 'PASSWORD_NOT_CORRECT')] = 102001
    values[(valuesById[102002] = 'UIN_EMPTY')] = 102002
    values[(valuesById[102003] = 'ACCOUNT_NOT_EXIST')] = 102003
    values[(valuesById[102004] = 'OPENID_NOT_EXIST')] = 102004
    values[(valuesById[102005] = 'EMAIL_NOT_EXIST')] = 102005
    values[(valuesById[102006] = 'VISITOR_OPERATE_FAIL')] = 102006
    values[(valuesById[102007] = 'USERID_OPERATE_FAIL')] = 102007
    return values
  })()

  protocols_common.ERROR_GAME = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'EG_NULL')] = 0
    values[(valuesById[103001] = 'NOT_IMPLEMENTED')] = 103001
    values[(valuesById[103002] = 'ROOM_FULL')] = 103002
    values[(valuesById[103003] = 'INVALID_RANG')] = 103003
    values[(valuesById[103004] = 'OUT_RANGE')] = 103004
    values[(valuesById[103005] = 'GAME_NOT_OVER')] = 103005
    values[(valuesById[103006] = 'GAME_IS_OVER')] = 103006
    values[(valuesById[103007] = 'INVALID_CURRENCY')] = 103007
    values[(valuesById[103008] = 'CURRENCY_AMOUNT_OVERFLOW')] = 103008
    values[(valuesById[103009] = 'MALFORMED_PARAMETER')] = 103009
    values[(valuesById[103010] = 'INVALID_PLAY_CODE')] = 103010
    values[(valuesById[103011] = 'CONFIG_NOT_EXIST')] = 103011
    values[(valuesById[103012] = 'GAME_NOT_EXIST')] = 103012
    values[(valuesById[103013] = 'USER_LOCK')] = 103013
    values[(valuesById[103014] = 'GAME_RULE')] = 103014
    values[(valuesById[103015] = 'MERCHANT_NOT_FOUND')] = 103015
    values[(valuesById[103016] = 'DECIMAL_TRANSLATE')] = 103016
    values[(valuesById[103017] = 'ASSET_PARAMS')] = 103017
    values[(valuesById[103018] = 'PLAYER_RECORD_COMMIT')] = 103018
    values[(valuesById[103019] = 'ROOM_NOT_FOUND')] = 103019
    values[(valuesById[103020] = 'ASSET_CHANGE')] = 103020
    values[(valuesById[103021] = 'ASSET_BALANCE')] = 103021
    values[(valuesById[103022] = 'USER_NOT_INROOM')] = 103022
    values[(valuesById[103023] = 'USER_ALREADY_PLAYED')] = 103023
    values[(valuesById[103024] = 'GAME_MSG_BOX_MAX')] = 103024
    values[(valuesById[103025] = 'GAME_ORDER_DUPLICATE')] = 103025
    values[(valuesById[103026] = 'GAME_USER_EXIST')] = 103026
    values[(valuesById[103027] = 'GAME_NOT_PAY')] = 103027
    values[(valuesById[103028] = 'JACKPOT_NOT_OPEN')] = 103028
    values[(valuesById[103029] = 'JACKPOT_PARAMS')] = 103029
    values[(valuesById[103031] = 'JACKPOT_MAX')] = 103031
    values[(valuesById[103032] = 'ROOM_SAVE')] = 103032
    values[(valuesById[103033] = 'ROOM_CREATE')] = 103033
    values[(valuesById[103034] = 'NEED4X_NEED_BOX')] = 103034
    values[(valuesById[103035] = 'ROOM_STAGE_FAILD')] = 103035
    values[(valuesById[103036] = 'PLAYER_CASHOUT')] = 103036
    values[(valuesById[103037] = 'CONFIG_MALFORMED')] = 103037
    values[(valuesById[103038] = 'ASSET_BALANCE_INSUFFICIENT')] = 103038
    values[(valuesById[103039] = 'BEGIN_MATCH_ROOM')] = 103039
    values[(valuesById[103040] = 'CANCEL_MATCH_ROOM')] = 103040
    values[(valuesById[103041] = 'GAME_RECORD')] = 103041
    return values
  })()

  protocols_common.ERROR_PROVIDER = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'EP_NULL')] = 0
    values[(valuesById[104001] = 'MERCHANT_PROVIDER_NOT_FOUND')] = 104001
    values[(valuesById[104002] = 'GAME_URL')] = 104002
    return values
  })()

  protocols_common.ERROR_GAMEV2 = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'EG2_NULL')] = 0
    values[(valuesById[105001] = 'ASSET_HTTP_FAILED')] = 105001
    values[(valuesById[105002] = 'ASSET_GET_BALANCE_FAILED')] = 105002
    values[(valuesById[105003] = 'ASSET_DEBIT_FAILED')] = 105003
    values[(valuesById[105004] = 'ASSET_CREDIT_FAILED')] = 105004
    values[(valuesById[105005] = 'ASSET_SETTLE_FAILED')] = 105005
    values[(valuesById[105006] = 'ASSET_BALANCE_NO_ENOUGH_FAILED')] = 105006
    values[(valuesById[105007] = 'GAME_GER_LIST_FAILED')] = 105007
    values[(valuesById[105008] = 'GAME_START_FAILED')] = 105008
    values[(valuesById[105009] = 'GAME_CLIENT_RECORD_LIST_FAILED')] = 105009
    values[(valuesById[105010] = 'GAME_BACKEND_RECORD_LIST_FAILED')] = 105010
    values[(valuesById[105011] = 'IMPORT_GAME_RECORD_LIST_FAILED')] = 105011
    values[(valuesById[105012] = 'FIELD_TYPE_CONVERSION_FAILED')] = 105012
    return values
  })()

  protocols_common.MsgID = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'CMD_BEGIN')] = 0
    values[(valuesById[9999] = 'CMD_END')] = 9999
    values[(valuesById[100000] = 'GATE_CMD_BEGIN')] = 100000
    values[(valuesById[100001] = 'GATE_CMD_HEARTBEAT_REQ')] = 100001
    values[(valuesById[100002] = 'GATE_CMD_HEARTBEAT_RSP')] = 100002
    values[(valuesById[100003] = 'GATE_CMD_AUTH_REQ')] = 100003
    values[(valuesById[100004] = 'GATE_CMD_AUTH_RSP')] = 100004
    values[(valuesById[100005] = 'GATE_CMD_HANDSHAKE_REQ')] = 100005
    values[(valuesById[100006] = 'GATE_CMD_HANDSHAKE_RSP')] = 100006
    values[(valuesById[109999] = 'GATE_CMD_END')] = 109999
    values[(valuesById[110000] = 'LOGIN_CMD_BEGIN')] = 110000
    values[(valuesById[110001] = 'LOGIN_CMD_REGISTER_REQ')] = 110001
    values[(valuesById[110002] = 'LOGIN_CMD_REGISTER_RSP')] = 110002
    values[(valuesById[110003] = 'LOGIN_CMD_LOGIN_REQ')] = 110003
    values[(valuesById[110004] = 'LOGIN_CMD_LOGIN_RSP')] = 110004
    values[(valuesById[110005] = 'LOGIN_CMD_ACCOUNT_REGISTER_REQ')] = 110005
    values[(valuesById[110006] = 'LOGIN_CMD_ACCOUNT_REGISTER_RSP')] = 110006
    values[(valuesById[110007] = 'LOGIN_CMD_ACCOUNT_LOGIN_REQ')] = 110007
    values[(valuesById[110008] = 'LOGIN_CMD_ACCOUNT_LOGIN_RSP')] = 110008
    values[
      (valuesById[110009] = 'LOGIN_CMD_VISITOR_REGISTER_LOGIN_REQ')
    ] = 110009
    values[
      (valuesById[110010] = 'LOGIN_CMD_VISITOR_REGISTER_LOGIN_RSP')
    ] = 110010
    values[(valuesById[110011] = 'LOGIN_CMD_GOOGLE_LOGIN_REQ')] = 110011
    values[(valuesById[110012] = 'LOGIN_CMD_GOOGLE_LOGIN_RSP')] = 110012
    values[(valuesById[110013] = 'LOGIN_CMD_FACEBOOK_LOGIN_REQ')] = 110013
    values[(valuesById[110014] = 'LOGIN_CMD_FACEBOOK_LOGIN_RSP')] = 110014
    values[(valuesById[110101] = 'LOGIN_CMD_CHECKTOKEN_REQ')] = 110101
    values[(valuesById[110102] = 'LOGIN_CMD_CHECKTOKEN_RSP')] = 110102
    values[(valuesById[110103] = 'LOGIN_CMD_GETTOKEN_REQ')] = 110103
    values[(valuesById[110104] = 'LOGIN_CMD_GETTOKEN_RSP')] = 110104
    values[(valuesById[110201] = 'LOGIN_CMD_GOOGLE_BIND_REQ')] = 110201
    values[(valuesById[110202] = 'LOGIN_CMD_GOOGLE_BIND_RSP')] = 110202
    values[(valuesById[110203] = 'LOGIN_CMD_FACEBOOK_BIND_REQ')] = 110203
    values[(valuesById[110204] = 'LOGIN_CMD_FACEBOOK_BIND_RSP')] = 110204
    values[(valuesById[110301] = 'LOGIN_CMD_MERCHANT_USER_LOGIN_REQ')] = 110301
    values[(valuesById[110302] = 'LOGIN_CMD_MERCHANT_USER_LOGIN_RSP')] = 110302
    values[(valuesById[119999] = 'LOGIN_CMD_END')] = 119999
    values[(valuesById[120000] = 'USER_CMD_BEGIN')] = 120000
    values[(valuesById[120001] = 'USER_CMD_GET_BY_UIN_REQ')] = 120001
    values[(valuesById[120002] = 'USER_CMD_GET_BY_UIN_RSP')] = 120002
    values[(valuesById[129999] = 'USER_CMD_END')] = 129999
    values[(valuesById[130000] = 'GAME_CMD_BEGIN')] = 130000
    values[(valuesById[130001] = 'GAME_CMD_START_REQ')] = 130001
    values[(valuesById[130002] = 'GAME_CMD_START_RESP')] = 130002
    values[(valuesById[130003] = 'GAME_CMD_PLAY_REQ')] = 130003
    values[(valuesById[130004] = 'GAME_CMD_PLAY_RESP')] = 130004
    values[(valuesById[130005] = 'GAME_CMD_BALANCE_REQ')] = 130005
    values[(valuesById[130006] = 'GAME_CMD_BALANCE_RESP')] = 130006
    values[(valuesById[130007] = 'GAME_CMD_GAME_RECORD_RESP')] = 130007
    values[(valuesById[130011] = 'ROOM_CMD_JOIN_REQ')] = 130011
    values[(valuesById[130012] = 'ROOM_CMD_JOIN_RESP')] = 130012
    values[(valuesById[130013] = 'ROOM_CMD_START_REQ')] = 130013
    values[(valuesById[130014] = 'ROOM_CMD_START_RESP')] = 130014
    values[(valuesById[130015] = 'ROOM_CMD_PLAY_REQ')] = 130015
    values[(valuesById[130016] = 'ROOM_CMD_PLAY_RESP')] = 130016
    values[(valuesById[130017] = 'ROOM_CMD_CLIENT_CONFIG_REQ')] = 130017
    values[(valuesById[130018] = 'ROOM_CMD_CLIENT_CONFIG_RES')] = 130018
    values[(valuesById[130019] = 'ROOM_CMD_MATCH_REQ')] = 130019
    values[(valuesById[130020] = 'ROOM_CMD_MATCH_RES')] = 130020
    values[(valuesById[130021] = 'GAME_CMD_JACKPOT_POOL_CLI_REQ')] = 130021
    values[(valuesById[130022] = 'GAME_CMD_JACKPOT_POOL_CLI_RESP')] = 130022
    values[(valuesById[130023] = 'GAME_CMD_JACKPOT_HIS_CLI_REQ')] = 130023
    values[(valuesById[130024] = 'GAME_CMD_JACKPOT_HIS_CLI_RESP')] = 130024
    values[(valuesById[130026] = 'GAME_CMD_JACKPOT_HIS_PUSH_RESP')] = 130026
    values[(valuesById[130031] = 'GAME_CMD_JACKPOT_POOL_REQ')] = 130031
    values[(valuesById[130032] = 'GAME_CMD_JACKPOT_POOL_RESP')] = 130032
    values[(valuesById[130033] = 'GAME_CMD_JACKPOT_USER_REQ')] = 130033
    values[(valuesById[130034] = 'GAME_CMD_JACKPOT_USER_RESP')] = 130034
    values[(valuesById[130035] = 'GAME_CMD_JACKPOT_HIS_REQ')] = 130035
    values[(valuesById[130036] = 'GAME_CMD_JACKPOT_HIS_RESP')] = 130036
    values[(valuesById[130037] = 'GAME_CMD_JACKPOT_QUERY_REQ')] = 130037
    values[(valuesById[130038] = 'GAME_CMD_JACKPOT_QUERY_RESP')] = 130038
    values[(valuesById[130041] = 'GAME_CMD_MINES_START_REQ')] = 130041
    values[(valuesById[130042] = 'GAME_CMD_MINES_START_RESP')] = 130042
    values[(valuesById[130043] = 'GAME_CMD_MINES_PLAY_REQ')] = 130043
    values[(valuesById[130044] = 'GAME_CMD_MINES_PLAY_RESP')] = 130044
    values[(valuesById[130045] = 'GAME_CMD_MINES_STATUS_REQ')] = 130045
    values[(valuesById[130046] = 'GAME_CMD_MINES_STATUS_RESP')] = 130046
    values[(valuesById[130100] = 'GAME_CMD_NEED4X_JOIN_REQ')] = 130100
    values[(valuesById[130101] = 'GAME_CMD_NEED4X_JOIN_RES')] = 130101
    values[(valuesById[130102] = 'GAME_CMD_NEED4X_START_REQ')] = 130102
    values[(valuesById[130103] = 'GAME_CMD_NEED4X_START_RES')] = 130103
    values[(valuesById[130104] = 'GAME_CMD_NEED4X_ACTION_TURN_REQ')] = 130104
    values[(valuesById[130105] = 'GAME_CMD_NEED4X_ACTION_TURN_RES')] = 130105
    values[(valuesById[130106] = 'GAME_CMD_NEED4X_ACTION_CASHOUT_REQ')] = 130106
    values[(valuesById[130107] = 'GAME_CMD_NEED4X_ACTION_CASHOUT_RES')] = 130107
    values[(valuesById[130108] = 'GAME_CMD_NEED4X_ACTION_TARGET_REQ')] = 130108
    values[(valuesById[130109] = 'GAME_CMD_NEED4X_ACTION_TARGET_RES')] = 130109
    values[(valuesById[130110] = 'GAME_CMD_NEED4X_SHOW_CARD')] = 130110
    values[(valuesById[130111] = 'GAME_CMD_NEED4X_BOX_CREATE_LIST')] = 130111
    values[(valuesById[130112] = 'GAME_CMD_NEED4X_BOX_OPEN_LIST')] = 130112
    values[(valuesById[130113] = 'GAME_CMD_NEED4X_SETTLE')] = 130113
    values[(valuesById[130114] = 'GAME_CMD_NEED4X_HISTORY_BOX')] = 130114
    values[(valuesById[130115] = 'GAME_CMD_NEED4X_MY_CAR')] = 130115
    values[(valuesById[130116] = 'GAME_CMD_NEED4X_ACTION_AllBet_REQ')] = 130116
    values[(valuesById[130117] = 'GAME_CMD_NEED4X_ACTION_AllBet_RES')] = 130117
    values[(valuesById[130118] = 'GAME_CMD_LIST_REQ')] = 130118
    values[(valuesById[130119] = 'GAME_CMD_LIST_RES')] = 130119
    values[(valuesById[130120] = 'GAME_CMD_LINE_SLOT_STATUS_REQ')] = 130120
    values[(valuesById[130121] = 'GAME_CMD_LINE_SLOT_STATUS_RES')] = 130121
    values[(valuesById[130122] = 'GAME_CMD_LINE_SLOT_START_REQ')] = 130122
    values[(valuesById[130123] = 'GAME_CMD_LINE_SLOT_START_RES')] = 130123
    values[(valuesById[130127] = 'GAME_CMD_CRASH_JOIN_REQ')] = 130127
    values[(valuesById[130128] = 'GAME_CMD_CRASH_JOIN_RES')] = 130128
    values[(valuesById[130129] = 'GAME_CMD_CRASH_START_REQ')] = 130129
    values[(valuesById[130130] = 'GAME_CMD_CRASH_START_RES')] = 130130
    values[(valuesById[130131] = 'GAME_CMD_CRASH_CASHOUT_REQ')] = 130131
    values[(valuesById[130132] = 'GAME_CMD_CRASH_CASHOUT_RES')] = 130132
    values[(valuesById[130133] = 'GAME_CMD_CRASH_AUTO_REQ')] = 130133
    values[(valuesById[130134] = 'GAME_CMD_CRASH_AUTO_RES')] = 130134
    values[(valuesById[130135] = 'GAME_CMD_CRASH_GAME_FRAME')] = 130135
    values[(valuesById[130136] = 'GAME_CMD_CRASH_BROAD_BET')] = 130136
    values[(valuesById[130137] = 'GAME_CMD_CRASH_BROAD_CASHOUT')] = 130137
    values[(valuesById[130138] = 'GAME_CMD_CRASH_PLAYER_CASHOUT')] = 130138
    values[(valuesById[130139] = 'GAME_CMD_CRASH_PLAYER_SETTLE')] = 130139
    values[(valuesById[130140] = 'GAME_CMD_CRASH_BROAD_SETTLE')] = 130140
    values[(valuesById[130141] = 'GAME_CMD_LIMBO_START_REQ')] = 130141
    values[(valuesById[130142] = 'GAME_CMD_LIMBO_START_RESP')] = 130142
    values[(valuesById[130143] = 'GAME_CMD_LIMBO_STATUS_REQ')] = 130143
    values[(valuesById[130144] = 'GAME_CMD_LIMBO_STATUS_RESP')] = 130144
    values[(valuesById[130151] = 'GAME_CMD_DICE_START_REQ')] = 130151
    values[(valuesById[130152] = 'GAME_CMD_DICE_START_RESP')] = 130152
    values[(valuesById[130153] = 'GAME_CMD_DICE_STATUS_REQ')] = 130153
    values[(valuesById[130154] = 'GAME_CMD_DICE_STATUS_RESP')] = 130154
    values[(valuesById[130161] = 'GAME_CMD_RING_START_REQ')] = 130161
    values[(valuesById[130162] = 'GAME_CMD_RING_START_RESP')] = 130162
    values[(valuesById[130163] = 'GAME_CMD_RING_STATUS_REQ')] = 130163
    values[(valuesById[130164] = 'GAME_CMD_RING_STATUS_RESP')] = 130164
    values[(valuesById[130171] = 'GAME_CMD_KENO_START_REQ')] = 130171
    values[(valuesById[130172] = 'GAME_CMD_KENO_START_RESP')] = 130172
    values[(valuesById[130173] = 'GAME_CMD_KENO_STATUS_REQ')] = 130173
    values[(valuesById[130174] = 'GAME_CMD_KENO_STATUS_RESP')] = 130174
    values[(valuesById[130181] = 'GAME_CMD_STAIRS_START_REQ')] = 130181
    values[(valuesById[130182] = 'GAME_CMD_STAIRS_START_RESP')] = 130182
    values[(valuesById[130183] = 'GAME_CMD_STAIRS_PLAY_REQ')] = 130183
    values[(valuesById[130184] = 'GAME_CMD_STAIRS_PLAY_RESP')] = 130184
    values[(valuesById[130185] = 'GAME_CMD_STAIRS_STATUS_REQ')] = 130185
    values[(valuesById[130186] = 'GAME_CMD_STAIRS_STATUS_RESP')] = 130186
    values[(valuesById[130201] = 'GAME_CMD_ROOM_MATCH_REQ')] = 130201
    values[(valuesById[130202] = 'GAME_CMD_ROOM_MATCH_RES')] = 130202
    values[(valuesById[130203] = 'GAME_CMD_BROAD_JOIN')] = 130203
    values[(valuesById[130204] = 'GAME_CMD_BROAD_LEAVE')] = 130204
    values[(valuesById[130211] = 'GAME_CMD_DOUBLE_JOIN_REQ')] = 130211
    values[(valuesById[130212] = 'GAME_CMD_DOUBLE_JOIN_RES')] = 130212
    values[(valuesById[130213] = 'GAME_CMD_DOUBLE_START_REQ')] = 130213
    values[(valuesById[130214] = 'GAME_CMD_DOUBLE_START_RES')] = 130214
    values[(valuesById[130215] = 'GAME_CMD_DOUBLE_GAME_FRAME')] = 130215
    values[(valuesById[130216] = 'GAME_CMD_DOUBLE_BROAD_BET')] = 130216
    values[(valuesById[130217] = 'GAME_CMD_DOUBLE_PLAYER_SETTLE')] = 130217
    values[(valuesById[130221] = 'GAME_CMD_CRICKET_CRASH_JOIN_REQ')] = 130221
    values[(valuesById[130222] = 'GAME_CMD_CRICKET_CRASH_JOIN_RES')] = 130222
    values[(valuesById[130223] = 'GAME_CMD_CRICKET_CRASH_START_REQ')] = 130223
    values[(valuesById[130224] = 'GAME_CMD_CRICKET_CRASH_START_RES')] = 130224
    values[
      (valuesById[130225] = 'GAME_CMD_CRICKET_CRASH_CASH_OUT_REQ')
    ] = 130225
    values[
      (valuesById[130226] = 'GAME_CMD_CRICKET_CRASH_CASH_OUT_RES')
    ] = 130226
    values[(valuesById[130227] = 'GAME_CMD_CRICKET_CRASH_AUTO_REQ')] = 130227
    values[(valuesById[130228] = 'GAME_CMD_CRICKET_CRASH_AUTO_RES')] = 130228
    values[(valuesById[130229] = 'GAME_CMD_CRICKET_CRASH_GAME_FRAME')] = 130229
    values[(valuesById[130230] = 'GAME_CMD_CRICKET_CRASH_BROAD_BET')] = 130230
    values[
      (valuesById[130231] = 'GAME_CMD_CRICKET_CRASH_BROAD_CASH_OUT')
    ] = 130231
    values[
      (valuesById[130232] = 'GAME_CMD_CRICKET_CRASH_PLAYER_CASH_OUT')
    ] = 130232
    values[
      (valuesById[130233] = 'GAME_CMD_CRICKET_CRASH_PLAYER_SETTLE')
    ] = 130233
    values[
      (valuesById[130234] = 'GAME_CMD_CRICKET_CRASH_BROAD_SETTLE')
    ] = 130234
    values[(valuesById[130250] = 'GAME_CMD_COLOR_JOIN_REQ')] = 130250
    values[(valuesById[130251] = 'GAME_CMD_COLOR_JOIN_RES')] = 130251
    values[(valuesById[130252] = 'GAME_CMD_COLOR_START_REQ')] = 130252
    values[(valuesById[130253] = 'GAME_CMD_COLOR_START_RES')] = 130253
    values[(valuesById[130254] = 'GAME_CMD_COLOR_GAME_FRAME')] = 130254
    values[(valuesById[130255] = 'GAME_CMD_COLOR_BROAD_BET')] = 130255
    values[(valuesById[130256] = 'GAME_CMD_COLOR_PLAYER_SETTLE')] = 130256
    values[(valuesById[130257] = 'GAME_CMD_FIERY_BOT_STATUS_REQ')] = 130257
    values[(valuesById[130258] = 'GAME_CMD_FIERY_BOT_STATUS_RES')] = 130258
    values[(valuesById[130259] = 'GAME_CMD_FIERY_BOT_START_REQ')] = 130259
    values[(valuesById[130260] = 'GAME_CMD_FIERY_BOT_START_RES')] = 130260
    values[(valuesById[130261] = 'GAME_CMD_PLINKO_STATUS_REQ')] = 130261
    values[(valuesById[130262] = 'GAME_CMD_PLINKO_STATUS_RES')] = 130262
    values[(valuesById[130263] = 'GAME_CMD_PLINKO_START_REQ')] = 130263
    values[(valuesById[130264] = 'GAME_CMD_PLINKO_START_RES')] = 130264
    values[(valuesById[130270] = 'GAME_CMD_ROULETTE_JOIN_REQ')] = 130270
    values[(valuesById[130271] = 'GAME_CMD_ROULETTE_JOIN_RES')] = 130271
    values[(valuesById[130272] = 'GAME_CMD_ROULETTE_START_REQ')] = 130272
    values[(valuesById[130273] = 'GAME_CMD_ROULETTE_START_RES')] = 130273
    values[(valuesById[130274] = 'GAME_CMD_ROULETTE_GAME_FRAME')] = 130274
    values[(valuesById[130275] = 'GAME_CMD_ROULETTE_BROAD_BET')] = 130275
    values[(valuesById[130276] = 'GAME_CMD_ROULETTE_PLAYER_SETTLE')] = 130276
    values[(valuesById[130281] = 'GAME_CMD_NINJA_STATUS_REQ')] = 130281
    values[(valuesById[130282] = 'GAME_CMD_NINJA_STATUS_RESP')] = 130282
    values[(valuesById[130283] = 'GAME_CMD_NINJA_START_REQ')] = 130283
    values[(valuesById[130284] = 'GAME_CMD_NINJA_START_RESP')] = 130284
    values[(valuesById[130285] = 'GAME_CMD_NINJA_PLAY_REQ')] = 130285
    values[(valuesById[130286] = 'GAME_CMD_NINJA_PLAY_RESP')] = 130286
    values[(valuesById[130287] = 'GAME_CMD_NINJA_PLAYER_SETTLE')] = 130287
    values[(valuesById[130289] = 'GAME_CMD_NINJA_PLAY_SPECIAL_REQ')] = 130289
    values[(valuesById[130290] = 'GAME_CMD_NINJA_PLAY_SPECIAL_RESP')] = 130290
    values[(valuesById[130291] = 'GAME_CMD_BANK_HEIST_START_REQ')] = 130291
    values[(valuesById[130292] = 'GAME_CMD_BANK_HEIST_START_RESP')] = 130292
    values[(valuesById[130293] = 'GAME_CMD_BANK_HEIST_PLAY_REQ')] = 130293
    values[(valuesById[130294] = 'GAME_CMD_BANK_HEIST_PLAY_RESP')] = 130294
    values[(valuesById[130295] = 'GAME_CMD_BANK_HEIST_STATUS_REQ')] = 130295
    values[(valuesById[130296] = 'GAME_CMD_BANK_HEIST_STATUS_RESP')] = 130296
    values[(valuesById[130301] = 'GAME_CMD_FAST_FURIOUS_JOIN_REQ')] = 130301
    values[(valuesById[130302] = 'GAME_CMD_FAST_FURIOUS_JOIN_RES')] = 130302
    values[(valuesById[130303] = 'GAME_CMD_FAST_FURIOUS_START_REQ')] = 130303
    values[(valuesById[130304] = 'GAME_CMD_FAST_FURIOUS_START_RES')] = 130304
    values[(valuesById[130305] = 'GAME_CMD_FAST_FURIOUS_GAME_FRAME')] = 130305
    values[(valuesById[130306] = 'GAME_CMD_FAST_FURIOUS_BROAD_BET')] = 130306
    values[
      (valuesById[130307] = 'GAME_CMD_FAST_FURIOUS_PLAYER_SETTLE')
    ] = 130307
    values[(valuesById[130308] = 'GAME_CMD_FAST_FURIOUS_BROAD_SETTLE')] = 130308
    values[(valuesById[130311] = 'GAME_CMD_FOOTBALLX_STATUS_REQ')] = 130311
    values[(valuesById[130312] = 'GAME_CMD_FOOTBALLX_STATUS_RES')] = 130312
    values[(valuesById[130313] = 'GAME_CMD_FOOTBALLX_START_REQ')] = 130313
    values[(valuesById[130314] = 'GAME_CMD_FOOTBALLX_START_RES')] = 130314
    values[(valuesById[130315] = 'GAME_CMD_FOOTBALLX_PLAY_REQ')] = 130315
    values[(valuesById[130316] = 'GAME_CMD_FOOTBALLX_PLAY_RES')] = 130316
    values[(valuesById[130321] = 'GAME_CMD_MONOPOLY_START_REQ')] = 130321
    values[(valuesById[130322] = 'GAME_CMD_MONOPOLY_START_RESP')] = 130322
    values[(valuesById[130323] = 'GAME_CMD_MONOPOLY_PLAY_REQ')] = 130323
    values[(valuesById[130324] = 'GAME_CMD_MONOPOLY_PLAY_RESP')] = 130324
    values[(valuesById[130325] = 'GAME_CMD_MONOPOLY_STATUS_REQ')] = 130325
    values[(valuesById[130326] = 'GAME_CMD_MONOPOLY_STATUS_RESP')] = 130326
    values[(valuesById[130331] = 'GAME_CMD_PANDA_CRASH_STATUS_REQ')] = 130331
    values[(valuesById[130332] = 'GAME_CMD_PANDA_CRASH_STATUS_RESP')] = 130332
    values[(valuesById[130333] = 'GAME_CMD_PANDA_CRASH_START_REQ')] = 130333
    values[(valuesById[130334] = 'GAME_CMD_PANDA_CRASH_START_RESP')] = 130334
    values[(valuesById[130335] = 'GAME_CMD_PANDA_CRASH_PLAY_REQ')] = 130335
    values[(valuesById[130336] = 'GAME_CMD_PANDA_CRASH_PLAY_RESP')] = 130336
    values[(valuesById[130337] = 'GAME_CMD_PANDA_CRASH_PLAYER_SETTLE')] = 130337
    values[(valuesById[130341] = 'GAME_CMD_COIN_MASTER_START_REQ')] = 130341
    values[(valuesById[130342] = 'GAME_CMD_COIN_MASTER_START_RESP')] = 130342
    values[(valuesById[130343] = 'GAME_CMD_COIN_MASTER_PLAY_REQ')] = 130343
    values[(valuesById[130344] = 'GAME_CMD_COIN_MASTER_PLAY_RESP')] = 130344
    values[(valuesById[130345] = 'GAME_CMD_COIN_MASTER_STATUS_REQ')] = 130345
    values[(valuesById[130346] = 'GAME_CMD_COIN_MASTER_STATUS_RESP')] = 130346
    values[(valuesById[130351] = 'GAME_CMD_HIDDEN_TEMPLE_STATUS_REQ')] = 130351
    values[(valuesById[130352] = 'GAME_CMD_HIDDEN_TEMPLE_STATUS_RESP')] = 130352
    values[(valuesById[130353] = 'GAME_CMD_HIDDEN_TEMPLE_START_REQ')] = 130353
    values[(valuesById[130354] = 'GAME_CMD_HIDDEN_TEMPLE_START_RESP')] = 130354
    values[(valuesById[130355] = 'GAME_CMD_HIDDEN_TEMPLE_PLAY_REQ')] = 130355
    values[(valuesById[130356] = 'GAME_CMD_HIDDEN_TEMPLE_PLAY_RESP')] = 130356
    values[(valuesById[130357] = 'GAME_CMD_HIDDEN_MARQUEE_REQ')] = 130357
    values[(valuesById[130358] = 'GAME_CMD_HIDDEN_MARQUEE_RESP')] = 130358
    values[(valuesById[130361] = 'GAME_CMD_FISHING_MASTER_STATUS_REQ')] = 130361
    values[
      (valuesById[130362] = 'GAME_CMD_FISHING_MASTER_STATUS_RESP')
    ] = 130362
    values[(valuesById[130363] = 'GAME_CMD_FISHING_MASTER_START_REQ')] = 130363
    values[(valuesById[130364] = 'GAME_CMD_FISHING_MASTER_START_RESP')] = 130364
    values[(valuesById[130365] = 'GAME_CMD_FISHING_MASTER_LEAVE_REQ')] = 130365
    values[(valuesById[130366] = 'GAME_CMD_FISHING_MASTER_LEAVE_RESP')] = 130366
    values[(valuesById[130371] = 'GAME_CMD_SUSHI_MINES_START_REQ')] = 130371
    values[(valuesById[130372] = 'GAME_CMD_SUSHI_MINES_START_RESP')] = 130372
    values[(valuesById[130373] = 'GAME_CMD_SUSHI_MINES_PLAY_REQ')] = 130373
    values[(valuesById[130374] = 'GAME_CMD_SUSHI_MINES_PLAY_RESP')] = 130374
    values[(valuesById[130375] = 'GAME_CMD_SUSHI_MINES_STATUS_REQ')] = 130375
    values[(valuesById[130376] = 'GAME_CMD_SUSHI_MINES_STATUS_RESP')] = 130376
    values[
      (valuesById[130381] = 'GAME_CMD_TREASURE_HUNT_MOLES_START_REQ')
    ] = 130381
    values[
      (valuesById[130382] = 'GAME_CMD_TREASURE_HUNT_MOLES_START_RESP')
    ] = 130382
    values[
      (valuesById[130383] = 'GAME_CMD_TREASURE_HUNT_MOLES_STATUS_REQ')
    ] = 130383
    values[
      (valuesById[130384] = 'GAME_CMD_TREASURE_HUNT_MOLES_STATUS_RESP')
    ] = 130384
    values[(valuesById[130391] = 'GAME_CMD_SCRATCH_MATCH_STATUS_REQ')] = 130391
    values[(valuesById[130392] = 'GAME_CMD_SCRATCH_MATCH_STATUS_RESP')] = 130392
    values[(valuesById[130393] = 'GAME_CMD_SCRATCH_MATCH_START_REQ')] = 130393
    values[(valuesById[130394] = 'GAME_CMD_SCRATCH_MATCH_START_RESP')] = 130394
    values[(valuesById[130395] = 'GAME_CMD_SCRATCH_MATCH_PLAY_REQ')] = 130395
    values[(valuesById[130396] = 'GAME_CMD_SCRATCH_MATCH_PLAY_RESP')] = 130396
    values[(valuesById[130411] = 'GAME_CMD_LUCKY_FOOTBALL_STATUS_REQ')] = 130411
    values[
      (valuesById[130412] = 'GAME_CMD_LUCKY_FOOTBALL_STATUS_RESP')
    ] = 130412
    values[(valuesById[130413] = 'GAME_CMD_LUCKY_FOOTBALL_START_REQ')] = 130413
    values[(valuesById[130414] = 'GAME_CMD_LUCKY_FOOTBALL_START_RESP')] = 130414
    values[(valuesById[130415] = 'GAME_CMD_LUCKY_FOOTBALL_PLAY_REQ')] = 130415
    values[(valuesById[130416] = 'GAME_CMD_LUCKY_FOOTBALL_PLAY_RESP')] = 130416
    values[
      (valuesById[130421] = 'GAME_CMD_COLOR_REALISTIC_STATUS_REQ')
    ] = 130421
    values[
      (valuesById[130422] = 'GAME_CMD_COLOR_REALISTIC_STATUS_RES')
    ] = 130422
    values[(valuesById[130423] = 'GAME_CMD_COLOR_REALISTIC_START_REQ')] = 130423
    values[(valuesById[130424] = 'GAME_CMD_COLOR_REALISTIC_START_RES')] = 130424
    values[(valuesById[130431] = 'GAME_CMD_SUPER_ACE_STATUS_REQ')] = 130431
    values[(valuesById[130432] = 'GAME_CMD_SUPER_ACE_STATUS_RESP')] = 130432
    values[(valuesById[130433] = 'GAME_CMD_SUPER_ACE_START_REQ')] = 130433
    values[(valuesById[130434] = 'GAME_CMD_SUPER_ACE_START_RESP')] = 130434
    values[(valuesById[130451] = 'GAME_CMD_LUDO_GET_FULL_STATE_REQ')] = 130451
    values[(valuesById[130452] = 'GAME_CMD_LUDO_GET_FULL_STATE_RES')] = 130452
    values[(valuesById[130455] = 'GAME_CMD_LUDO_ROLL_DICE_REQ')] = 130455
    values[(valuesById[130456] = 'GAME_CMD_LUDO_ROLL_DICE_RES')] = 130456
    values[(valuesById[130457] = 'GAME_CMD_LUDO_WALL_CHESS_REQ')] = 130457
    values[(valuesById[130458] = 'GAME_CMD_LUDO_WALL_CHESS_RES')] = 130458
    values[(valuesById[130459] = 'GAME_CMD_LUDO_AUTO_CHANGE_REQ')] = 130459
    values[(valuesById[130460] = 'GAME_CMD_LUDO_AUTO_CHANGE_RES')] = 130460
    values[(valuesById[130461] = 'GAME_CMD_LUDO_LEAVE_REQ')] = 130461
    values[(valuesById[130462] = 'GAME_CMD_LUDO_LEAVE_RES')] = 130462
    values[(valuesById[130463] = 'GAME_CMD_LUDO_BEGIN_MATCH_REQ')] = 130463
    values[(valuesById[130464] = 'GAME_CMD_LUDO_BEGIN_MATCH_RES')] = 130464
    values[(valuesById[130465] = 'GAME_CMD_LUDO_CANCEL_MATCH_REQ')] = 130465
    values[(valuesById[130466] = 'GAME_CMD_LUDO_CANCEL_MATCH_RES')] = 130466
    values[(valuesById[130467] = 'GAME_CMD_LUDO_GAME_ACTION_PUSH')] = 130467
    values[(valuesById[130468] = 'GAME_CMD_LUDO_MATCH_COMPLETE_PUSH')] = 130468
    values[(valuesById[130469] = 'GAME_CMD_LUDO_ROOM_STATE_PUSH')] = 130469
    values[(valuesById[130470] = 'GAME_CMD_LUDO_GAME_COMPLETE_PUSH')] = 130470
    values[(valuesById[130473] = 'GAME_CMD_LUDO_USER_GAME_RECORD_REQ')] = 130473
    values[(valuesById[130474] = 'GAME_CMD_LUDO_USER_GAME_RECORD_RES')] = 130474
    values[(valuesById[130401] = 'GAME_CMD_CLIENT_CONFIG_REQ')] = 130401
    values[(valuesById[130402] = 'GAME_CMD_CLIENT_CONFIG_RES')] = 130402
    values[(valuesById[130403] = 'GAME_CMD_PROVIDER_LIST_REQ')] = 130403
    values[(valuesById[130404] = 'GAME_CMD_PROVIDER_LIST_RES')] = 130404
    values[(valuesById[130405] = 'GAME_CMD_MATCH_ROOM_RANK_LIST_REQ')] = 130405
    values[(valuesById[130406] = 'GAME_CMD_MATCH_ROOM_RANK_LIST_RES')] = 130406
    values[(valuesById[139999] = 'GAME_CMD_END')] = 139999
    values[(valuesById[140000] = 'MERCHANT_CMD_BEGIN')] = 140000
    values[(valuesById[140001] = 'MERCHANT_CMD_CONFIG_LIST_REQ')] = 140001
    values[(valuesById[140002] = 'MERCHANT_CMD_CONFIG_LIST_RSP')] = 140002
    values[(valuesById[140003] = 'MERCHANT_CMD_CONFIG_CREATE_REQ')] = 140003
    values[(valuesById[140004] = 'MERCHANT_CMD_CONFIG_CREATE_RSP')] = 140004
    values[(valuesById[140005] = 'MERCHANT_CMD_CONFIG_OFFON_REQ')] = 140005
    values[(valuesById[140006] = 'MERCHANT_CMD_CONFIG_OFFON_RSP')] = 140006
    values[(valuesById[140007] = 'MERCHANT_CMD_CONFIG_EDIT_REQ')] = 140007
    values[(valuesById[140008] = 'MERCHANT_CMD_CONFIG_EDIT_RSP')] = 140008
    values[(valuesById[140009] = 'MERCHANT_CMD_CONFIG_DETAIL_REQ')] = 140009
    values[(valuesById[140010] = 'MERCHANT_CMD_CONFIG_DETAIL_RSP')] = 140010
    values[
      (valuesById[140101] = 'MERCHANT_CMD_PROXY_TEST_BALANCE_REQ')
    ] = 140101
    values[
      (valuesById[140102] = 'MERCHANT_CMD_PROXY_TEST_BALANCE_RSP')
    ] = 140102
    values[(valuesById[140103] = 'MERCHANT_CMD_PROXY_TEST_DEBIT_REQ')] = 140103
    values[(valuesById[140104] = 'MERCHANT_CMD_PROXY_TEST_DEBIT_RSP')] = 140104
    values[(valuesById[140105] = 'MERCHANT_CMD_PROXY_TEST_CREDIT_REQ')] = 140105
    values[(valuesById[140106] = 'MERCHANT_CMD_PROXY_TEST_CREDIT_RSP')] = 140106
    values[(valuesById[140107] = 'MERCHANT_CMD_PROXY_TEST_SETTLE_REQ')] = 140107
    values[(valuesById[140108] = 'MERCHANT_CMD_PROXY_TEST_SETTLE_RSP')] = 140108
    values[(valuesById[140201] = 'MERCHANT_CMD_ADD_PROVIDER_REQ')] = 140201
    values[(valuesById[140202] = 'MERCHANT_CMD_ADD_PROVIDER_RSP')] = 140202
    values[(valuesById[140203] = 'MERCHANT_CMD_DEL_PROVIDER_REQ')] = 140203
    values[(valuesById[140204] = 'MERCHANT_CMD_DEL_PROVIDER_RSP')] = 140204
    values[(valuesById[149999] = 'MERCHANT_CMD_END')] = 149999
    values[(valuesById[150000] = 'SINK_CMD_BEGIN')] = 150000
    values[
      (valuesById[150001] = 'SINK_CMD_CLIENT_GAME_RECORD_LIST_REQ')
    ] = 150001
    values[
      (valuesById[150002] = 'SINK_CMD_CLIENT_GAME_RECORD_LIST_RESP')
    ] = 150002
    values[
      (valuesById[150003] = 'SINK_CMD_BACKEND_GAME_RECORD_LIST_REQ')
    ] = 150003
    values[
      (valuesById[150004] = 'SINK_CMD_BACKEND_GAME_RECORD_LIST_RESP')
    ] = 150004
    values[
      (valuesById[150005] = 'SINK_CMD_BACKEND_GAME_RECORD_BY_BIZID_REQ')
    ] = 150005
    values[
      (valuesById[150006] = 'SINK_CMD_BACKEND_GAME_RECORD_BY_BIZID_RESP')
    ] = 150006
    values[
      (valuesById[150007] = 'SINK_CMD_BACKEND_GAME_RECORD_BY_BIZIDS_REQ')
    ] = 150007
    values[
      (valuesById[150008] = 'SINK_CMD_BACKEND_GAME_RECORD_BY_BIZIDS_RESP')
    ] = 150008
    values[(valuesById[159999] = 'SINK_CMD_END')] = 159999
    values[(valuesById[160000] = 'PROVIDER_CMD_BEGIN')] = 160000
    values[(valuesById[160001] = 'PROVIDER_CMD_GAME_URL_REQ')] = 160001
    values[(valuesById[160002] = 'PROVIDER_CMD_GAME_URL_RESP')] = 160002
    values[(valuesById[160003] = 'PROVIDER_CMD_GAME_Entity_REQ')] = 160003
    values[(valuesById[160004] = 'PROVIDER_CMD_GAME_Entity_RESP')] = 160004
    values[(valuesById[169999] = 'PROVIDER_CMD_END')] = 169999
    return values
  })()

  return protocols_common
})())

export const protocols_game_fiery_bot = ($root.protocols_game_fiery_bot =
  (() => {
    const protocols_game_fiery_bot = {}

    protocols_game_fiery_bot.FieryBotStatusReqParams = (function () {
      function FieryBotStatusReqParams(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStatusReqParams.create = function create(properties) {
        return new FieryBotStatusReqParams(properties)
      }

      FieryBotStatusReqParams.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        return w
      }

      FieryBotStatusReqParams.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStatusReqParams()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStatusReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return (
          typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStatusReqParams'
        )
      }

      return FieryBotStatusReqParams
    })()

    protocols_game_fiery_bot.FieryBotStatusReq = (function () {
      function FieryBotStatusReq(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStatusReq.prototype.gameId = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0
      FieryBotStatusReq.prototype.currency = ''
      FieryBotStatusReq.prototype.params = null

      FieryBotStatusReq.create = function create(properties) {
        return new FieryBotStatusReq(properties)
      }

      FieryBotStatusReq.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
          w.uint32(8).uint64(m.gameId)
        if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
          w.uint32(18).string(m.currency)
        if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
          $root.protocols_game_fiery_bot.FieryBotStatusReqParams.encode(
            m.params,
            w.uint32(26).fork(),
          ).ldelim()
        return w
      }

      FieryBotStatusReq.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStatusReq()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.gameId = r.uint64()
              break
            }
            case 2: {
              m.currency = r.string()
              break
            }
            case 3: {
              m.params =
                $root.protocols_game_fiery_bot.FieryBotStatusReqParams.decode(
                  r,
                  r.uint32(),
                )
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStatusReq'
      }

      return FieryBotStatusReq
    })()

    protocols_game_fiery_bot.FieryBotStatusResParams = (function () {
      function FieryBotStatusResParams(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStatusResParams.prototype.balance = null
      FieryBotStatusResParams.prototype.hash = ''

      FieryBotStatusResParams.create = function create(properties) {
        return new FieryBotStatusResParams(properties)
      }

      FieryBotStatusResParams.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
          $root.protocols_game.Balance.encode(
            m.balance,
            w.uint32(10).fork(),
          ).ldelim()
        if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
          w.uint32(18).string(m.hash)
        return w
      }

      FieryBotStatusResParams.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStatusResParams()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
              break
            }
            case 2: {
              m.hash = r.string()
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStatusResParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return (
          typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStatusResParams'
        )
      }

      return FieryBotStatusResParams
    })()

    protocols_game_fiery_bot.FieryBotStatusRes = (function () {
      function FieryBotStatusRes(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStatusRes.prototype.code = 0
      FieryBotStatusRes.prototype.msg = ''
      FieryBotStatusRes.prototype.data = null

      FieryBotStatusRes.create = function create(properties) {
        return new FieryBotStatusRes(properties)
      }

      FieryBotStatusRes.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
          w.uint32(8).int32(m.code)
        if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
          w.uint32(18).string(m.msg)
        if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
          $root.protocols_game_fiery_bot.FieryBotStatusResParams.encode(
            m.data,
            w.uint32(26).fork(),
          ).ldelim()
        return w
      }

      FieryBotStatusRes.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStatusRes()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.code = r.int32()
              break
            }
            case 2: {
              m.msg = r.string()
              break
            }
            case 3: {
              m.data =
                $root.protocols_game_fiery_bot.FieryBotStatusResParams.decode(
                  r,
                  r.uint32(),
                )
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStatusRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStatusRes'
      }

      return FieryBotStatusRes
    })()

    protocols_game_fiery_bot.FieryBotStartParams = (function () {
      function FieryBotStartParams(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStartParams.prototype.auto = false

      FieryBotStartParams.create = function create(properties) {
        return new FieryBotStartParams(properties)
      }

      FieryBotStartParams.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
          w.uint32(8).bool(m.auto)
        return w
      }

      FieryBotStartParams.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStartParams()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.auto = r.bool()
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStartParams'
      }

      return FieryBotStartParams
    })()

    protocols_game_fiery_bot.FieryBotStartReq = (function () {
      function FieryBotStartReq(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStartReq.prototype.gameId = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0
      FieryBotStartReq.prototype.currency = ''
      FieryBotStartReq.prototype.amount = ''
      FieryBotStartReq.prototype.params = null

      FieryBotStartReq.create = function create(properties) {
        return new FieryBotStartReq(properties)
      }

      FieryBotStartReq.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
          w.uint32(8).uint64(m.gameId)
        if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
          w.uint32(18).string(m.currency)
        if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
          w.uint32(26).string(m.amount)
        if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
          $root.protocols_game_fiery_bot.FieryBotStartParams.encode(
            m.params,
            w.uint32(34).fork(),
          ).ldelim()
        return w
      }

      FieryBotStartReq.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStartReq()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.gameId = r.uint64()
              break
            }
            case 2: {
              m.currency = r.string()
              break
            }
            case 3: {
              m.amount = r.string()
              break
            }
            case 4: {
              m.params =
                $root.protocols_game_fiery_bot.FieryBotStartParams.decode(
                  r,
                  r.uint32(),
                )
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStartReq'
      }

      return FieryBotStartReq
    })()

    protocols_game_fiery_bot.FieryBotStartResParams = (function () {
      function FieryBotStartResParams(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStartResParams.prototype.result = ''
      FieryBotStartResParams.prototype.balance = null
      FieryBotStartResParams.prototype.amount = ''
      FieryBotStartResParams.prototype.multi = ''
      FieryBotStartResParams.prototype.payout = ''
      FieryBotStartResParams.prototype.roundId = ''

      FieryBotStartResParams.create = function create(properties) {
        return new FieryBotStartResParams(properties)
      }

      FieryBotStartResParams.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
          w.uint32(10).string(m.result)
        if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
          $root.protocols_game.Balance.encode(
            m.balance,
            w.uint32(18).fork(),
          ).ldelim()
        if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
          w.uint32(26).string(m.amount)
        if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
          w.uint32(34).string(m.multi)
        if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
          w.uint32(42).string(m.payout)
        if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
          w.uint32(50).string(m.roundId)
        return w
      }

      FieryBotStartResParams.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStartResParams()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.result = r.string()
              break
            }
            case 2: {
              m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
              break
            }
            case 3: {
              m.amount = r.string()
              break
            }
            case 4: {
              m.multi = r.string()
              break
            }
            case 5: {
              m.payout = r.string()
              break
            }
            case 6: {
              m.roundId = r.string()
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStartResParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return (
          typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStartResParams'
        )
      }

      return FieryBotStartResParams
    })()

    protocols_game_fiery_bot.FieryBotStartRes = (function () {
      function FieryBotStartRes(p) {
        if (p)
          for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
            if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
      }

      FieryBotStartRes.prototype.code = 0
      FieryBotStartRes.prototype.msg = ''
      FieryBotStartRes.prototype.data = null

      FieryBotStartRes.create = function create(properties) {
        return new FieryBotStartRes(properties)
      }

      FieryBotStartRes.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
          w.uint32(8).int32(m.code)
        if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
          w.uint32(18).string(m.msg)
        if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
          $root.protocols_game_fiery_bot.FieryBotStartResParams.encode(
            m.data,
            w.uint32(26).fork(),
          ).ldelim()
        return w
      }

      FieryBotStartRes.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
          m = new $root.protocols_game_fiery_bot.FieryBotStartRes()
        while (r.pos < c) {
          var t = r.uint32()
          switch (t >>> 3) {
            case 1: {
              m.code = r.int32()
              break
            }
            case 2: {
              m.msg = r.string()
              break
            }
            case 3: {
              m.data =
                $root.protocols_game_fiery_bot.FieryBotStartResParams.decode(
                  r,
                  r.uint32(),
                )
              break
            }
            default:
              r.skipType(t & 7)
              break
          }
        }
        return m
      }

      FieryBotStartRes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = 'type.googleapis.com'
        }
        return typeUrlPrefix + '/protocols_game_fiery_bot.FieryBotStartRes'
      }

      return FieryBotStartRes
    })()

    return protocols_game_fiery_bot
  })())

export const protocols_game_id = ($root.protocols_game_id = (() => {
  const protocols_game_id = {}

  protocols_game_id.GameID = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'Null')] = 0
    values[(valuesById[1] = 'Dice')] = 1
    return values
  })()

  return protocols_game_id
})())

export const gate = ($root.gate = (() => {
  const gate = {}

  gate.GateHeartBeatReq = (function () {
    function GateHeartBeatReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHeartBeatReq.prototype.timeStamp = null
    GateHeartBeatReq.prototype.miniGameIp = null

    let $oneOfFields

    Object.defineProperty(GateHeartBeatReq.prototype, '_timeStamp', {
      get: $util.oneOfGetter(($oneOfFields = ['timeStamp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(GateHeartBeatReq.prototype, '_miniGameIp', {
      get: $util.oneOfGetter(($oneOfFields = ['miniGameIp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    GateHeartBeatReq.create = function create(properties) {
      return new GateHeartBeatReq(properties)
    }

    GateHeartBeatReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.timeStamp != null && Object.hasOwnProperty.call(m, 'timeStamp'))
        w.uint32(8).uint64(m.timeStamp)
      if (m.miniGameIp != null && Object.hasOwnProperty.call(m, 'miniGameIp'))
        w.uint32(18).string(m.miniGameIp)
      return w
    }

    GateHeartBeatReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHeartBeatReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.timeStamp = r.uint64()
            break
          }
          case 2: {
            m.miniGameIp = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHeartBeatReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHeartBeatReq'
    }

    return GateHeartBeatReq
  })()

  gate.GateHeartBeatRsp = (function () {
    function GateHeartBeatRsp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHeartBeatRsp.prototype.code = 0
    GateHeartBeatRsp.prototype.msg = ''
    GateHeartBeatRsp.prototype.data = null

    GateHeartBeatRsp.create = function create(properties) {
      return new GateHeartBeatRsp(properties)
    }

    GateHeartBeatRsp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.gate.GateHeartBeatRspBody.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    GateHeartBeatRsp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHeartBeatRsp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.gate.GateHeartBeatRspBody.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHeartBeatRsp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHeartBeatRsp'
    }

    return GateHeartBeatRsp
  })()

  gate.GateHeartBeatRspBody = (function () {
    function GateHeartBeatRspBody(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHeartBeatRspBody.prototype.timeStamp = null

    let $oneOfFields

    Object.defineProperty(GateHeartBeatRspBody.prototype, '_timeStamp', {
      get: $util.oneOfGetter(($oneOfFields = ['timeStamp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    GateHeartBeatRspBody.create = function create(properties) {
      return new GateHeartBeatRspBody(properties)
    }

    GateHeartBeatRspBody.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.timeStamp != null && Object.hasOwnProperty.call(m, 'timeStamp'))
        w.uint32(8).uint64(m.timeStamp)
      return w
    }

    GateHeartBeatRspBody.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHeartBeatRspBody()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.timeStamp = r.uint64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHeartBeatRspBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHeartBeatRspBody'
    }

    return GateHeartBeatRspBody
  })()

  gate.GateCheckLoginReq = (function () {
    function GateCheckLoginReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateCheckLoginReq.prototype.userId = null
    GateCheckLoginReq.prototype.merchantId = null
    GateCheckLoginReq.prototype.token = null

    let $oneOfFields

    Object.defineProperty(GateCheckLoginReq.prototype, '_userId', {
      get: $util.oneOfGetter(($oneOfFields = ['userId'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(GateCheckLoginReq.prototype, '_merchantId', {
      get: $util.oneOfGetter(($oneOfFields = ['merchantId'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(GateCheckLoginReq.prototype, '_token', {
      get: $util.oneOfGetter(($oneOfFields = ['token'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    GateCheckLoginReq.create = function create(properties) {
      return new GateCheckLoginReq(properties)
    }

    GateCheckLoginReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(10).string(m.userId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(18).string(m.merchantId)
      if (m.token != null && Object.hasOwnProperty.call(m, 'token'))
        w.uint32(26).string(m.token)
      return w
    }

    GateCheckLoginReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateCheckLoginReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.userId = r.string()
            break
          }
          case 2: {
            m.merchantId = r.string()
            break
          }
          case 3: {
            m.token = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateCheckLoginReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateCheckLoginReq'
    }

    return GateCheckLoginReq
  })()

  gate.GateCheckLoginRsp = (function () {
    function GateCheckLoginRsp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateCheckLoginRsp.prototype.code = 0
    GateCheckLoginRsp.prototype.msg = ''
    GateCheckLoginRsp.prototype.data = null

    GateCheckLoginRsp.create = function create(properties) {
      return new GateCheckLoginRsp(properties)
    }

    GateCheckLoginRsp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.gate.GateHeartBeatRspBody.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    GateCheckLoginRsp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateCheckLoginRsp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.gate.GateHeartBeatRspBody.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateCheckLoginRsp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateCheckLoginRsp'
    }

    return GateCheckLoginRsp
  })()

  gate.GateCheckLoginRspBody = (function () {
    function GateCheckLoginRspBody(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateCheckLoginRspBody.prototype.timeStamp = null

    let $oneOfFields

    Object.defineProperty(GateCheckLoginRspBody.prototype, '_timeStamp', {
      get: $util.oneOfGetter(($oneOfFields = ['timeStamp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    GateCheckLoginRspBody.create = function create(properties) {
      return new GateCheckLoginRspBody(properties)
    }

    GateCheckLoginRspBody.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.timeStamp != null && Object.hasOwnProperty.call(m, 'timeStamp'))
        w.uint32(8).uint64(m.timeStamp)
      return w
    }

    GateCheckLoginRspBody.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateCheckLoginRspBody()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.timeStamp = r.uint64()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateCheckLoginRspBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateCheckLoginRspBody'
    }

    return GateCheckLoginRspBody
  })()

  gate.GateHandshakeReq = (function () {
    function GateHandshakeReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHandshakeReq.prototype.timeStamp = null
    GateHandshakeReq.prototype.merchantId = ''
    GateHandshakeReq.prototype.roomId = ''

    let $oneOfFields

    Object.defineProperty(GateHandshakeReq.prototype, '_timeStamp', {
      get: $util.oneOfGetter(($oneOfFields = ['timeStamp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    GateHandshakeReq.create = function create(properties) {
      return new GateHandshakeReq(properties)
    }

    GateHandshakeReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.timeStamp != null && Object.hasOwnProperty.call(m, 'timeStamp'))
        w.uint32(8).uint64(m.timeStamp)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(18).string(m.merchantId)
      if (m.roomId != null && Object.hasOwnProperty.call(m, 'roomId'))
        w.uint32(26).string(m.roomId)
      return w
    }

    GateHandshakeReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHandshakeReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.timeStamp = r.uint64()
            break
          }
          case 2: {
            m.merchantId = r.string()
            break
          }
          case 3: {
            m.roomId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHandshakeReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHandshakeReq'
    }

    return GateHandshakeReq
  })()

  gate.GateHandshakeRsp = (function () {
    function GateHandshakeRsp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHandshakeRsp.prototype.code = 0
    GateHandshakeRsp.prototype.msg = ''
    GateHandshakeRsp.prototype.data = null

    GateHandshakeRsp.create = function create(properties) {
      return new GateHandshakeRsp(properties)
    }

    GateHandshakeRsp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.gate.GateHandshakeRspBody.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    GateHandshakeRsp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHandshakeRsp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.gate.GateHandshakeRspBody.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHandshakeRsp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHandshakeRsp'
    }

    return GateHandshakeRsp
  })()

  gate.GateHandshakeRspBody = (function () {
    function GateHandshakeRspBody(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    GateHandshakeRspBody.prototype.needHeartbeat = 0
    GateHandshakeRspBody.prototype.interval = 0

    GateHandshakeRspBody.create = function create(properties) {
      return new GateHandshakeRspBody(properties)
    }

    GateHandshakeRspBody.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (
        m.needHeartbeat != null &&
        Object.hasOwnProperty.call(m, 'needHeartbeat')
      )
        w.uint32(8).int32(m.needHeartbeat)
      if (m.interval != null && Object.hasOwnProperty.call(m, 'interval'))
        w.uint32(16).int32(m.interval)
      return w
    }

    GateHandshakeRspBody.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.GateHandshakeRspBody()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.needHeartbeat = r.int32()
            break
          }
          case 2: {
            m.interval = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    GateHandshakeRspBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.GateHandshakeRspBody'
    }

    return GateHandshakeRspBody
  })()

  gate.BaseBody = (function () {
    function BaseBody(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    BaseBody.create = function create(properties) {
      return new BaseBody(properties)
    }

    BaseBody.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    BaseBody.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.BaseBody()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    BaseBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.BaseBody'
    }

    return BaseBody
  })()

  gate.MsgEncode = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'ME_PLAIN')] = 0
    values[(valuesById[1] = 'ME_GZIP')] = 1
    values[(valuesById[2] = 'ME_ENCRYPT')] = 2
    return values
  })()

  gate.Header = (function () {
    function Header(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    Header.prototype.msgId = null
    Header.prototype.sendSeq = null
    Header.prototype.recvSeq = null
    Header.prototype.stampTime = null
    Header.prototype.bodyLength = null
    Header.prototype.rspSeq = null
    Header.prototype.version = null
    Header.prototype.route = null
    Header.prototype.encodeType = null
    Header.prototype.status = null
    Header.prototype.clientIp = null
    Header.prototype.path = null
    Header.prototype.platform = null
    Header.prototype.clientType = null

    let $oneOfFields

    Object.defineProperty(Header.prototype, '_msgId', {
      get: $util.oneOfGetter(($oneOfFields = ['msgId'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_sendSeq', {
      get: $util.oneOfGetter(($oneOfFields = ['sendSeq'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_recvSeq', {
      get: $util.oneOfGetter(($oneOfFields = ['recvSeq'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_stampTime', {
      get: $util.oneOfGetter(($oneOfFields = ['stampTime'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_bodyLength', {
      get: $util.oneOfGetter(($oneOfFields = ['bodyLength'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_rspSeq', {
      get: $util.oneOfGetter(($oneOfFields = ['rspSeq'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_version', {
      get: $util.oneOfGetter(($oneOfFields = ['version'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_route', {
      get: $util.oneOfGetter(($oneOfFields = ['route'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_encodeType', {
      get: $util.oneOfGetter(($oneOfFields = ['encodeType'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_status', {
      get: $util.oneOfGetter(($oneOfFields = ['status'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_clientIp', {
      get: $util.oneOfGetter(($oneOfFields = ['clientIp'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_path', {
      get: $util.oneOfGetter(($oneOfFields = ['path'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_platform', {
      get: $util.oneOfGetter(($oneOfFields = ['platform'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Object.defineProperty(Header.prototype, '_clientType', {
      get: $util.oneOfGetter(($oneOfFields = ['clientType'])),
      set: $util.oneOfSetter($oneOfFields),
    })

    Header.create = function create(properties) {
      return new Header(properties)
    }

    Header.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.msgId != null && Object.hasOwnProperty.call(m, 'msgId'))
        w.uint32(8).uint32(m.msgId)
      if (m.sendSeq != null && Object.hasOwnProperty.call(m, 'sendSeq'))
        w.uint32(16).uint64(m.sendSeq)
      if (m.recvSeq != null && Object.hasOwnProperty.call(m, 'recvSeq'))
        w.uint32(24).uint64(m.recvSeq)
      if (m.stampTime != null && Object.hasOwnProperty.call(m, 'stampTime'))
        w.uint32(32).uint64(m.stampTime)
      if (m.bodyLength != null && Object.hasOwnProperty.call(m, 'bodyLength'))
        w.uint32(40).uint32(m.bodyLength)
      if (m.rspSeq != null && Object.hasOwnProperty.call(m, 'rspSeq'))
        w.uint32(48).uint64(m.rspSeq)
      if (m.version != null && Object.hasOwnProperty.call(m, 'version'))
        w.uint32(58).string(m.version)
      if (m.route != null && Object.hasOwnProperty.call(m, 'route'))
        w.uint32(64).uint32(m.route)
      if (m.encodeType != null && Object.hasOwnProperty.call(m, 'encodeType'))
        w.uint32(72).int32(m.encodeType)
      if (m.status != null && Object.hasOwnProperty.call(m, 'status'))
        w.uint32(80).uint32(m.status)
      if (m.clientIp != null && Object.hasOwnProperty.call(m, 'clientIp'))
        w.uint32(90).string(m.clientIp)
      if (m.path != null && Object.hasOwnProperty.call(m, 'path'))
        w.uint32(98).string(m.path)
      if (m.platform != null && Object.hasOwnProperty.call(m, 'platform'))
        w.uint32(106).string(m.platform)
      if (m.clientType != null && Object.hasOwnProperty.call(m, 'clientType'))
        w.uint32(114).string(m.clientType)
      return w
    }

    Header.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.gate.Header()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.msgId = r.uint32()
            break
          }
          case 2: {
            m.sendSeq = r.uint64()
            break
          }
          case 3: {
            m.recvSeq = r.uint64()
            break
          }
          case 4: {
            m.stampTime = r.uint64()
            break
          }
          case 5: {
            m.bodyLength = r.uint32()
            break
          }
          case 6: {
            m.rspSeq = r.uint64()
            break
          }
          case 7: {
            m.version = r.string()
            break
          }
          case 8: {
            m.route = r.uint32()
            break
          }
          case 9: {
            m.encodeType = r.int32()
            break
          }
          case 10: {
            m.status = r.uint32()
            break
          }
          case 11: {
            m.clientIp = r.string()
            break
          }
          case 12: {
            m.path = r.string()
            break
          }
          case 13: {
            m.platform = r.string()
            break
          }
          case 14: {
            m.clientType = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    Header.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/gate.Header'
    }

    return Header
  })()

  return gate
})())

export const protocols_jackpot = ($root.protocols_jackpot = (() => {
  const protocols_jackpot = {}

  protocols_jackpot.PoolInfo = (function () {
    function PoolInfo(p) {
      this.values = {}
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    PoolInfo.prototype.guid = ''
    PoolInfo.prototype.gameId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0
    PoolInfo.prototype.merchantId = ''
    PoolInfo.prototype.currency = ''
    PoolInfo.prototype.amount = ''
    PoolInfo.prototype.state = 0
    PoolInfo.prototype.values = $util.emptyObject

    PoolInfo.create = function create(properties) {
      return new PoolInfo(properties)
    }

    PoolInfo.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.guid != null && Object.hasOwnProperty.call(m, 'guid'))
        w.uint32(10).string(m.guid)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(16).uint64(m.gameId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(26).string(m.merchantId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(34).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(42).string(m.amount)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(48).uint32(m.state)
      if (m.values != null && Object.hasOwnProperty.call(m, 'values')) {
        for (var ks = Object.keys(m.values), i = 0; i < ks.length; ++i) {
          w.uint32(58)
            .fork()
            .uint32(10)
            .string(ks[i])
            .uint32(18)
            .string(m.values[ks[i]])
            .ldelim()
        }
      }
      return w
    }

    PoolInfo.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.PoolInfo(),
        k,
        value
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.guid = r.string()
            break
          }
          case 2: {
            m.gameId = r.uint64()
            break
          }
          case 3: {
            m.merchantId = r.string()
            break
          }
          case 4: {
            m.currency = r.string()
            break
          }
          case 5: {
            m.amount = r.string()
            break
          }
          case 6: {
            m.state = r.uint32()
            break
          }
          case 7: {
            if (m.values === $util.emptyObject) m.values = {}
            var c2 = r.uint32() + r.pos
            k = ''
            value = ''
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.string()
                  break
                case 2:
                  value = r.string()
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.values[k] = value
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    PoolInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.PoolInfo'
    }

    return PoolInfo
  })()

  protocols_jackpot.PoolUser = (function () {
    function PoolUser(p) {
      this.values = {}
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    PoolUser.prototype.gameId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0
    PoolUser.prototype.merchantId = ''
    PoolUser.prototype.currency = ''
    PoolUser.prototype.values = $util.emptyObject

    PoolUser.create = function create(properties) {
      return new PoolUser(properties)
    }

    PoolUser.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(18).string(m.merchantId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(26).string(m.currency)
      if (m.values != null && Object.hasOwnProperty.call(m, 'values')) {
        for (var ks = Object.keys(m.values), i = 0; i < ks.length; ++i) {
          w.uint32(34)
            .fork()
            .uint32(10)
            .string(ks[i])
            .uint32(18)
            .string(m.values[ks[i]])
            .ldelim()
        }
      }
      return w
    }

    PoolUser.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.PoolUser(),
        k,
        value
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.merchantId = r.string()
            break
          }
          case 3: {
            m.currency = r.string()
            break
          }
          case 4: {
            if (m.values === $util.emptyObject) m.values = {}
            var c2 = r.uint32() + r.pos
            k = ''
            value = ''
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.string()
                  break
                case 2:
                  value = r.string()
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.values[k] = value
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    PoolUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.PoolUser'
    }

    return PoolUser
  })()

  protocols_jackpot.PoolHis = (function () {
    function PoolHis(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    PoolHis.prototype.guid = ''
    PoolHis.prototype.gameId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0
    PoolHis.prototype.currency = ''
    PoolHis.prototype.amount = ''
    PoolHis.prototype.befort = ''
    PoolHis.prototype.after = ''
    PoolHis.prototype.rewardTime = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    PoolHis.prototype.merchantId = ''
    PoolHis.prototype.userId = ''
    PoolHis.prototype.userName = ''
    PoolHis.prototype.record = ''

    PoolHis.create = function create(properties) {
      return new PoolHis(properties)
    }

    PoolHis.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.guid != null && Object.hasOwnProperty.call(m, 'guid'))
        w.uint32(10).string(m.guid)
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(16).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(26).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(34).string(m.amount)
      if (m.befort != null && Object.hasOwnProperty.call(m, 'befort'))
        w.uint32(42).string(m.befort)
      if (m.after != null && Object.hasOwnProperty.call(m, 'after'))
        w.uint32(50).string(m.after)
      if (m.rewardTime != null && Object.hasOwnProperty.call(m, 'rewardTime'))
        w.uint32(56).uint64(m.rewardTime)
      if (m.merchantId != null && Object.hasOwnProperty.call(m, 'merchantId'))
        w.uint32(66).string(m.merchantId)
      if (m.userId != null && Object.hasOwnProperty.call(m, 'userId'))
        w.uint32(74).string(m.userId)
      if (m.userName != null && Object.hasOwnProperty.call(m, 'userName'))
        w.uint32(82).string(m.userName)
      if (m.record != null && Object.hasOwnProperty.call(m, 'record'))
        w.uint32(90).string(m.record)
      return w
    }

    PoolHis.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.PoolHis()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.guid = r.string()
            break
          }
          case 2: {
            m.gameId = r.uint64()
            break
          }
          case 3: {
            m.currency = r.string()
            break
          }
          case 4: {
            m.amount = r.string()
            break
          }
          case 5: {
            m.befort = r.string()
            break
          }
          case 6: {
            m.after = r.string()
            break
          }
          case 7: {
            m.rewardTime = r.uint64()
            break
          }
          case 8: {
            m.merchantId = r.string()
            break
          }
          case 9: {
            m.userId = r.string()
            break
          }
          case 10: {
            m.userName = r.string()
            break
          }
          case 11: {
            m.record = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    PoolHis.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.PoolHis'
    }

    return PoolHis
  })()

  protocols_jackpot.Switch = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'UNSET')] = 0
    values[(valuesById[1] = 'ON')] = 1
    values[(valuesById[2] = 'OFF')] = 2
    return values
  })()

  protocols_jackpot.PoolInfoResp = (function () {
    function PoolInfoResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    PoolInfoResp.prototype.pool = null
    PoolInfoResp.prototype.user = null
    PoolInfoResp.prototype.his = null
    PoolInfoResp.prototype.balance = null
    PoolInfoResp.prototype['switch'] = 0

    PoolInfoResp.create = function create(properties) {
      return new PoolInfoResp(properties)
    }

    PoolInfoResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.pool != null && Object.hasOwnProperty.call(m, 'pool'))
        $root.protocols_jackpot.PoolInfo.encode(
          m.pool,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.user != null && Object.hasOwnProperty.call(m, 'user'))
        $root.protocols_jackpot.PoolUser.encode(
          m.user,
          w.uint32(18).fork(),
        ).ldelim()
      if (m.his != null && Object.hasOwnProperty.call(m, 'his'))
        $root.protocols_jackpot.PoolHis.encode(
          m.his,
          w.uint32(26).fork(),
        ).ldelim()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(34).fork(),
        ).ldelim()
      if (m['switch'] != null && Object.hasOwnProperty.call(m, 'switch'))
        w.uint32(40).int32(m['switch'])
      return w
    }

    PoolInfoResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.PoolInfoResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.pool = $root.protocols_jackpot.PoolInfo.decode(r, r.uint32())
            break
          }
          case 2: {
            m.user = $root.protocols_jackpot.PoolUser.decode(r, r.uint32())
            break
          }
          case 3: {
            m.his = $root.protocols_jackpot.PoolHis.decode(r, r.uint32())
            break
          }
          case 4: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 5: {
            m['switch'] = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    PoolInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.PoolInfoResp'
    }

    return PoolInfoResp
  })()

  protocols_jackpot.JackpotPoolCliReq = (function () {
    function JackpotPoolCliReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    JackpotPoolCliReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    JackpotPoolCliReq.prototype.currency = ''

    JackpotPoolCliReq.create = function create(properties) {
      return new JackpotPoolCliReq(properties)
    }

    JackpotPoolCliReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      return w
    }

    JackpotPoolCliReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.JackpotPoolCliReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    JackpotPoolCliReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.JackpotPoolCliReq'
    }

    return JackpotPoolCliReq
  })()

  protocols_jackpot.JackpotPoolCliResp = (function () {
    function JackpotPoolCliResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    JackpotPoolCliResp.prototype.code = 0
    JackpotPoolCliResp.prototype.msg = ''
    JackpotPoolCliResp.prototype.data = null

    JackpotPoolCliResp.create = function create(properties) {
      return new JackpotPoolCliResp(properties)
    }

    JackpotPoolCliResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_jackpot.PoolInfoResp.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    JackpotPoolCliResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.JackpotPoolCliResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_jackpot.PoolInfoResp.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    JackpotPoolCliResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.JackpotPoolCliResp'
    }

    return JackpotPoolCliResp
  })()

  protocols_jackpot.JackpotHisCliReq = (function () {
    function JackpotHisCliReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    JackpotHisCliReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    JackpotHisCliReq.prototype.currency = ''
    JackpotHisCliReq.prototype.count = 0
    JackpotHisCliReq.prototype.self = false

    JackpotHisCliReq.create = function create(properties) {
      return new JackpotHisCliReq(properties)
    }

    JackpotHisCliReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.count != null && Object.hasOwnProperty.call(m, 'count'))
        w.uint32(24).uint32(m.count)
      if (m.self != null && Object.hasOwnProperty.call(m, 'self'))
        w.uint32(32).bool(m.self)
      return w
    }

    JackpotHisCliReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.JackpotHisCliReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.count = r.uint32()
            break
          }
          case 4: {
            m.self = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    JackpotHisCliReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.JackpotHisCliReq'
    }

    return JackpotHisCliReq
  })()

  protocols_jackpot.JackpotHisCliResp = (function () {
    function JackpotHisCliResp(p) {
      this.data = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    JackpotHisCliResp.prototype.code = 0
    JackpotHisCliResp.prototype.msg = ''
    JackpotHisCliResp.prototype.data = $util.emptyArray

    JackpotHisCliResp.create = function create(properties) {
      return new JackpotHisCliResp(properties)
    }

    JackpotHisCliResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && m.data.length) {
        for (var i = 0; i < m.data.length; ++i)
          $root.protocols_jackpot.PoolHis.encode(
            m.data[i],
            w.uint32(26).fork(),
          ).ldelim()
      }
      return w
    }

    JackpotHisCliResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.JackpotHisCliResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            if (!(m.data && m.data.length)) m.data = []
            m.data.push($root.protocols_jackpot.PoolHis.decode(r, r.uint32()))
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    JackpotHisCliResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.JackpotHisCliResp'
    }

    return JackpotHisCliResp
  })()

  protocols_jackpot.JackpotHisPushResp = (function () {
    function JackpotHisPushResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    JackpotHisPushResp.prototype.code = 0
    JackpotHisPushResp.prototype.msg = ''
    JackpotHisPushResp.prototype.data = null

    JackpotHisPushResp.create = function create(properties) {
      return new JackpotHisPushResp(properties)
    }

    JackpotHisPushResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_jackpot.PoolHis.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    JackpotHisPushResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_jackpot.JackpotHisPushResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_jackpot.PoolHis.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    JackpotHisPushResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_jackpot.JackpotHisPushResp'
    }

    return JackpotHisPushResp
  })()

  return protocols_jackpot
})())

export const protocols_keno = ($root.protocols_keno = (() => {
  const protocols_keno = {}

  protocols_keno.KenoRiesgo = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'RIESGOUNKNOW')] = 0
    values[(valuesById[1] = 'LOW')] = 1
    values[(valuesById[2] = 'MEDIUM')] = 2
    values[(valuesById[3] = 'HIGH')] = 3
    return values
  })()

  protocols_keno.KenoStartReq = (function () {
    function KenoStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStartReq.prototype.currency = ''
    KenoStartReq.prototype.amount = ''
    KenoStartReq.prototype.params = null

    KenoStartReq.create = function create(properties) {
      return new KenoStartReq(properties)
    }

    KenoStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(18).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_keno.KenoParams.encode(
          m.params,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    KenoStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.amount = r.string()
            break
          }
          case 3: {
            m.params = $root.protocols_keno.KenoParams.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStartReq'
    }

    return KenoStartReq
  })()

  protocols_keno.KenoParams = (function () {
    function KenoParams(p) {
      this.numbers = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoParams.prototype.riesgo = 0
    KenoParams.prototype.numbers = $util.emptyArray
    KenoParams.prototype.auto = false

    KenoParams.create = function create(properties) {
      return new KenoParams(properties)
    }

    KenoParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.riesgo != null && Object.hasOwnProperty.call(m, 'riesgo'))
        w.uint32(8).int32(m.riesgo)
      if (m.numbers != null && m.numbers.length) {
        w.uint32(18).fork()
        for (var i = 0; i < m.numbers.length; ++i) w.uint32(m.numbers[i])
        w.ldelim()
      }
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(24).bool(m.auto)
      return w
    }

    KenoParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.riesgo = r.int32()
            break
          }
          case 2: {
            if (!(m.numbers && m.numbers.length)) m.numbers = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.numbers.push(r.uint32())
            } else m.numbers.push(r.uint32())
            break
          }
          case 3: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoParams'
    }

    return KenoParams
  })()

  protocols_keno.KenoStartResp = (function () {
    function KenoStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStartResp.prototype.code = 0
    KenoStartResp.prototype.msg = ''
    KenoStartResp.prototype.data = null

    KenoStartResp.create = function create(properties) {
      return new KenoStartResp(properties)
    }

    KenoStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_keno.KenoStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    KenoStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_keno.KenoStartRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStartResp'
    }

    return KenoStartResp
  })()

  protocols_keno.KenoStartRespParams = (function () {
    function KenoStartRespParams(p) {
      this.numbers = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStartRespParams.prototype.balance = null
    KenoStartRespParams.prototype.multiplier = ''
    KenoStartRespParams.prototype.numbers = $util.emptyArray
    KenoStartRespParams.prototype.hash = ''
    KenoStartRespParams.prototype.payOut = ''
    KenoStartRespParams.prototype.result = 0
    KenoStartRespParams.prototype.roundId = ''

    KenoStartRespParams.create = function create(properties) {
      return new KenoStartRespParams(properties)
    }

    KenoStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(18).string(m.multiplier)
      if (m.numbers != null && m.numbers.length) {
        w.uint32(26).fork()
        for (var i = 0; i < m.numbers.length; ++i) w.uint32(m.numbers[i])
        w.ldelim()
      }
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(34).string(m.hash)
      if (m.payOut != null && Object.hasOwnProperty.call(m, 'payOut'))
        w.uint32(42).string(m.payOut)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(48).uint32(m.result)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(58).string(m.roundId)
      return w
    }

    KenoStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.multiplier = r.string()
            break
          }
          case 3: {
            if (!(m.numbers && m.numbers.length)) m.numbers = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.numbers.push(r.uint32())
            } else m.numbers.push(r.uint32())
            break
          }
          case 4: {
            m.hash = r.string()
            break
          }
          case 5: {
            m.payOut = r.string()
            break
          }
          case 6: {
            m.result = r.uint32()
            break
          }
          case 7: {
            m.roundId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStartRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStartRespParams'
    }

    return KenoStartRespParams
  })()

  protocols_keno.KenoStatusReq = (function () {
    function KenoStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStatusReq.prototype.currency = ''
    KenoStatusReq.prototype.params = null

    KenoStatusReq.create = function create(properties) {
      return new KenoStatusReq(properties)
    }

    KenoStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(10).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_keno.KenoParams.encode(
          m.params,
          w.uint32(18).fork(),
        ).ldelim()
      return w
    }

    KenoStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.currency = r.string()
            break
          }
          case 2: {
            m.params = $root.protocols_keno.KenoParams.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStatusReq'
    }

    return KenoStatusReq
  })()

  protocols_keno.KenoStatusResp = (function () {
    function KenoStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStatusResp.prototype.code = 0
    KenoStatusResp.prototype.msg = ''
    KenoStatusResp.prototype.data = null

    KenoStatusResp.create = function create(properties) {
      return new KenoStatusResp(properties)
    }

    KenoStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_keno.KenoStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    KenoStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_keno.KenoStatusRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStatusResp'
    }

    return KenoStatusResp
  })()

  protocols_keno.KenoStatusRespParams = (function () {
    function KenoStatusRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    KenoStatusRespParams.prototype.balance = null
    KenoStatusRespParams.prototype.hash = ''
    KenoStatusRespParams.prototype.multiConfig = ''

    KenoStatusRespParams.create = function create(properties) {
      return new KenoStatusRespParams(properties)
    }

    KenoStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(18).string(m.hash)
      if (m.multiConfig != null && Object.hasOwnProperty.call(m, 'multiConfig'))
        w.uint32(26).string(m.multiConfig)
      return w
    }

    KenoStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_keno.KenoStatusRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.hash = r.string()
            break
          }
          case 3: {
            m.multiConfig = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    KenoStatusRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_keno.KenoStatusRespParams'
    }

    return KenoStatusRespParams
  })()

  return protocols_keno
})())

export const protocols_limbo = ($root.protocols_limbo = (() => {
  const protocols_limbo = {}

  protocols_limbo.LimboStartReq = (function () {
    function LimboStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    LimboStartReq.prototype.currency = ''
    LimboStartReq.prototype.amount = ''
    LimboStartReq.prototype.params = null

    LimboStartReq.create = function create(properties) {
      return new LimboStartReq(properties)
    }

    LimboStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_limbo.LimboStartParams.encode(
          m.params,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    LimboStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.params = $root.protocols_limbo.LimboStartParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStartReq'
    }

    return LimboStartReq
  })()

  protocols_limbo.LimboStartParams = (function () {
    function LimboStartParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStartParams.prototype.prediction = 0
    LimboStartParams.prototype.auto = false

    LimboStartParams.create = function create(properties) {
      return new LimboStartParams(properties)
    }

    LimboStartParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.prediction != null && Object.hasOwnProperty.call(m, 'prediction'))
        w.uint32(13).float(m.prediction)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(16).bool(m.auto)
      return w
    }

    LimboStartParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStartParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.prediction = r.float()
            break
          }
          case 2: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStartParams'
    }

    return LimboStartParams
  })()

  protocols_limbo.LimboStartResp = (function () {
    function LimboStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStartResp.prototype.code = 0
    LimboStartResp.prototype.msg = ''
    LimboStartResp.prototype.data = null

    LimboStartResp.create = function create(properties) {
      return new LimboStartResp(properties)
    }

    LimboStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_limbo.LimboStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    LimboStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_limbo.LimboStartRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStartResp'
    }

    return LimboStartResp
  })()

  protocols_limbo.LimboStartRespParams = (function () {
    function LimboStartRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStartRespParams.prototype.balance = null
    LimboStartRespParams.prototype.result = 0
    LimboStartRespParams.prototype.hash = ''
    LimboStartRespParams.prototype.payOut = ''
    LimboStartRespParams.prototype.roundId = ''

    LimboStartRespParams.create = function create(properties) {
      return new LimboStartRespParams(properties)
    }

    LimboStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(21).float(m.result)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(26).string(m.hash)
      if (m.payOut != null && Object.hasOwnProperty.call(m, 'payOut'))
        w.uint32(34).string(m.payOut)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(42).string(m.roundId)
      return w
    }

    LimboStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.result = r.float()
            break
          }
          case 3: {
            m.hash = r.string()
            break
          }
          case 4: {
            m.payOut = r.string()
            break
          }
          case 5: {
            m.roundId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStartRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStartRespParams'
    }

    return LimboStartRespParams
  })()

  protocols_limbo.LimboStatusReq = (function () {
    function LimboStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStatusReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    LimboStatusReq.prototype.currency = ''
    LimboStatusReq.prototype.params = null

    LimboStatusReq.create = function create(properties) {
      return new LimboStatusReq(properties)
    }

    LimboStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_limbo.LimboStatusParams.encode(
          m.params,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    LimboStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.params = $root.protocols_limbo.LimboStatusParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStatusReq'
    }

    return LimboStatusReq
  })()

  protocols_limbo.LimboStatusParams = (function () {
    function LimboStatusParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStatusParams.create = function create(properties) {
      return new LimboStatusParams(properties)
    }

    LimboStatusParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    LimboStatusParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStatusParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStatusParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStatusParams'
    }

    return LimboStatusParams
  })()

  protocols_limbo.LimboStatusResp = (function () {
    function LimboStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStatusResp.prototype.code = 0
    LimboStatusResp.prototype.msg = ''
    LimboStatusResp.prototype.data = null

    LimboStatusResp.create = function create(properties) {
      return new LimboStatusResp(properties)
    }

    LimboStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_limbo.LimboStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    LimboStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_limbo.LimboStatusRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStatusResp'
    }

    return LimboStatusResp
  })()

  protocols_limbo.LimboStatusRespParams = (function () {
    function LimboStatusRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    LimboStatusRespParams.prototype.balance = null
    LimboStatusRespParams.prototype.hash = ''

    LimboStatusRespParams.create = function create(properties) {
      return new LimboStatusRespParams(properties)
    }

    LimboStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(18).string(m.hash)
      return w
    }

    LimboStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_limbo.LimboStatusRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.hash = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    LimboStatusRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_limbo.LimboStatusRespParams'
    }

    return LimboStatusRespParams
  })()

  return protocols_limbo
})())

export const protocols_mines = ($root.protocols_mines = (() => {
  const protocols_mines = {}

  protocols_mines.MinesStartReq = (function () {
    function MinesStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesStartReq.prototype.currency = ''
    MinesStartReq.prototype.amount = ''
    MinesStartReq.prototype.params = null

    MinesStartReq.create = function create(properties) {
      return new MinesStartReq(properties)
    }

    MinesStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_mines.MinesStartParams.encode(
          m.params,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    MinesStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.params = $root.protocols_mines.MinesStartParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStartReq'
    }

    return MinesStartReq
  })()

  protocols_mines.MinesStartParams = (function () {
    function MinesStartParams(p) {
      this.positions = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStartParams.prototype.bombCount = 0
    MinesStartParams.prototype.auto = false
    MinesStartParams.prototype.positions = $util.emptyArray

    MinesStartParams.create = function create(properties) {
      return new MinesStartParams(properties)
    }

    MinesStartParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.bombCount != null && Object.hasOwnProperty.call(m, 'bombCount'))
        w.uint32(8).uint32(m.bombCount)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(16).bool(m.auto)
      if (m.positions != null && m.positions.length) {
        w.uint32(26).fork()
        for (var i = 0; i < m.positions.length; ++i) w.uint32(m.positions[i])
        w.ldelim()
      }
      return w
    }

    MinesStartParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStartParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.bombCount = r.uint32()
            break
          }
          case 2: {
            m.auto = r.bool()
            break
          }
          case 3: {
            if (!(m.positions && m.positions.length)) m.positions = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.positions.push(r.uint32())
            } else m.positions.push(r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStartParams'
    }

    return MinesStartParams
  })()

  protocols_mines.MinesStartResp = (function () {
    function MinesStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStartResp.prototype.code = 0
    MinesStartResp.prototype.msg = ''
    MinesStartResp.prototype.data = null

    MinesStartResp.create = function create(properties) {
      return new MinesStartResp(properties)
    }

    MinesStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_mines.MinesStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    MinesStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_mines.MinesStartRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStartResp'
    }

    return MinesStartResp
  })()

  protocols_mines.MinesStartRespParams = (function () {
    function MinesStartRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStartRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesStartRespParams.prototype.state = 0
    MinesStartRespParams.prototype.multiplier = ''
    MinesStartRespParams.prototype.bombCount = 0
    MinesStartRespParams.prototype.hash = ''
    MinesStartRespParams.prototype.asset = null
    MinesStartRespParams.prototype.balance = null
    MinesStartRespParams.prototype.roundId = ''

    MinesStartRespParams.create = function create(properties) {
      return new MinesStartRespParams(properties)
    }

    MinesStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(8).uint64(m.createdAt)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).uint32(m.state)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(26).string(m.multiplier)
      if (m.bombCount != null && Object.hasOwnProperty.call(m, 'bombCount'))
        w.uint32(32).uint32(m.bombCount)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      if (m.asset != null && Object.hasOwnProperty.call(m, 'asset'))
        $root.protocols_game.AssetInfo.encode(
          m.asset,
          w.uint32(50).fork(),
        ).ldelim()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(58).fork(),
        ).ldelim()
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(66).string(m.roundId)
      return w
    }

    MinesStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.createdAt = r.uint64()
            break
          }
          case 2: {
            m.state = r.uint32()
            break
          }
          case 3: {
            m.multiplier = r.string()
            break
          }
          case 4: {
            m.bombCount = r.uint32()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          case 6: {
            m.asset = $root.protocols_game.AssetInfo.decode(r, r.uint32())
            break
          }
          case 7: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 8: {
            m.roundId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStartRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStartRespParams'
    }

    return MinesStartRespParams
  })()

  protocols_mines.MinesPlayReq = (function () {
    function MinesPlayReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesPlayReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesPlayReq.prototype.currency = ''
    MinesPlayReq.prototype.params = null

    MinesPlayReq.create = function create(properties) {
      return new MinesPlayReq(properties)
    }

    MinesPlayReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_mines.MinesPlayReqParams.encode(
          m.params,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    MinesPlayReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesPlayReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.params = $root.protocols_mines.MinesPlayReqParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesPlayReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesPlayReq'
    }

    return MinesPlayReq
  })()

  protocols_mines.MinesPlayReqParams = (function () {
    function MinesPlayReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesPlayReqParams.prototype.code = 0
    MinesPlayReqParams.prototype.position = 0

    MinesPlayReqParams.create = function create(properties) {
      return new MinesPlayReqParams(properties)
    }

    MinesPlayReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.position != null && Object.hasOwnProperty.call(m, 'position'))
        w.uint32(16).uint32(m.position)
      return w
    }

    MinesPlayReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesPlayReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.position = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesPlayReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesPlayReqParams'
    }

    return MinesPlayReqParams
  })()

  protocols_mines.MinesPlayResp = (function () {
    function MinesPlayResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesPlayResp.prototype.code = 0
    MinesPlayResp.prototype.msg = ''
    MinesPlayResp.prototype.data = null

    MinesPlayResp.create = function create(properties) {
      return new MinesPlayResp(properties)
    }

    MinesPlayResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_mines.MinesPlayRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    MinesPlayResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesPlayResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_mines.MinesPlayRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesPlayResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesPlayResp'
    }

    return MinesPlayResp
  })()

  protocols_mines.MinesPlayRespParams = (function () {
    function MinesPlayRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesPlayRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesPlayRespParams.prototype.state = 0
    MinesPlayRespParams.prototype.multiplier = ''
    MinesPlayRespParams.prototype.opened = ''
    MinesPlayRespParams.prototype.asset = null
    MinesPlayRespParams.prototype.balance = null

    MinesPlayRespParams.create = function create(properties) {
      return new MinesPlayRespParams(properties)
    }

    MinesPlayRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(8).uint64(m.createdAt)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).uint32(m.state)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(26).string(m.multiplier)
      if (m.opened != null && Object.hasOwnProperty.call(m, 'opened'))
        w.uint32(34).string(m.opened)
      if (m.asset != null && Object.hasOwnProperty.call(m, 'asset'))
        $root.protocols_game.AssetInfo.encode(
          m.asset,
          w.uint32(42).fork(),
        ).ldelim()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(50).fork(),
        ).ldelim()
      return w
    }

    MinesPlayRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesPlayRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.createdAt = r.uint64()
            break
          }
          case 2: {
            m.state = r.uint32()
            break
          }
          case 3: {
            m.multiplier = r.string()
            break
          }
          case 4: {
            m.opened = r.string()
            break
          }
          case 5: {
            m.asset = $root.protocols_game.AssetInfo.decode(r, r.uint32())
            break
          }
          case 6: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesPlayRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesPlayRespParams'
    }

    return MinesPlayRespParams
  })()

  protocols_mines.MinesStatusReq = (function () {
    function MinesStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStatusReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesStatusReq.prototype.currency = ''
    MinesStatusReq.prototype.params = null

    MinesStatusReq.create = function create(properties) {
      return new MinesStatusReq(properties)
    }

    MinesStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_mines.MinesStatusParams.encode(
          m.params,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    MinesStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.params = $root.protocols_mines.MinesStatusParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStatusReq'
    }

    return MinesStatusReq
  })()

  protocols_mines.MinesStatusParams = (function () {
    function MinesStatusParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStatusParams.prototype.bombCount = 0

    MinesStatusParams.create = function create(properties) {
      return new MinesStatusParams(properties)
    }

    MinesStatusParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.bombCount != null && Object.hasOwnProperty.call(m, 'bombCount'))
        w.uint32(8).uint32(m.bombCount)
      return w
    }

    MinesStatusParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStatusParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.bombCount = r.uint32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStatusParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStatusParams'
    }

    return MinesStatusParams
  })()

  protocols_mines.MinesStatusResp = (function () {
    function MinesStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStatusResp.prototype.code = 0
    MinesStatusResp.prototype.msg = ''
    MinesStatusResp.prototype.data = null

    MinesStatusResp.create = function create(properties) {
      return new MinesStatusResp(properties)
    }

    MinesStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_mines.MinesStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    MinesStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_mines.MinesStatusRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStatusResp'
    }

    return MinesStatusResp
  })()

  protocols_mines.MinesStatusRespParams = (function () {
    function MinesStatusRespParams(p) {
      this.rates = []
      this.ratesMap = {}
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesStatusRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    MinesStatusRespParams.prototype.state = 0
    MinesStatusRespParams.prototype.multiplier = ''
    MinesStatusRespParams.prototype.bombCount = 0
    MinesStatusRespParams.prototype.hash = ''
    MinesStatusRespParams.prototype.opened = ''
    MinesStatusRespParams.prototype.rates = $util.emptyArray
    MinesStatusRespParams.prototype.amount = ''
    MinesStatusRespParams.prototype.roundId = ''
    MinesStatusRespParams.prototype.asset = null
    MinesStatusRespParams.prototype.balance = null
    MinesStatusRespParams.prototype.ratesMap = $util.emptyObject

    MinesStatusRespParams.create = function create(properties) {
      return new MinesStatusRespParams(properties)
    }

    MinesStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(8).uint64(m.createdAt)
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).uint32(m.state)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(26).string(m.multiplier)
      if (m.bombCount != null && Object.hasOwnProperty.call(m, 'bombCount'))
        w.uint32(32).uint32(m.bombCount)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      if (m.opened != null && Object.hasOwnProperty.call(m, 'opened'))
        w.uint32(50).string(m.opened)
      if (m.rates != null && m.rates.length) {
        w.uint32(58).fork()
        for (var i = 0; i < m.rates.length; ++i) w.double(m.rates[i])
        w.ldelim()
      }
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(66).string(m.amount)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(74).string(m.roundId)
      if (m.asset != null && Object.hasOwnProperty.call(m, 'asset'))
        $root.protocols_game.AssetInfo.encode(
          m.asset,
          w.uint32(82).fork(),
        ).ldelim()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(90).fork(),
        ).ldelim()
      if (m.ratesMap != null && Object.hasOwnProperty.call(m, 'ratesMap')) {
        for (var ks = Object.keys(m.ratesMap), i = 0; i < ks.length; ++i) {
          w.uint32(98).fork().uint32(8).uint32(ks[i])
          $root.protocols_mines.MinesRates.encode(
            m.ratesMap[ks[i]],
            w.uint32(18).fork(),
          )
            .ldelim()
            .ldelim()
        }
      }
      return w
    }

    MinesStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesStatusRespParams(),
        k,
        value
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.createdAt = r.uint64()
            break
          }
          case 2: {
            m.state = r.uint32()
            break
          }
          case 3: {
            m.multiplier = r.string()
            break
          }
          case 4: {
            m.bombCount = r.uint32()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          case 6: {
            m.opened = r.string()
            break
          }
          case 7: {
            if (!(m.rates && m.rates.length)) m.rates = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.rates.push(r.double())
            } else m.rates.push(r.double())
            break
          }
          case 8: {
            m.amount = r.string()
            break
          }
          case 9: {
            m.roundId = r.string()
            break
          }
          case 10: {
            m.asset = $root.protocols_game.AssetInfo.decode(r, r.uint32())
            break
          }
          case 11: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 12: {
            if (m.ratesMap === $util.emptyObject) m.ratesMap = {}
            var c2 = r.uint32() + r.pos
            k = 0
            value = null
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.uint32()
                  break
                case 2:
                  value = $root.protocols_mines.MinesRates.decode(r, r.uint32())
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.ratesMap[k] = value
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesStatusRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesStatusRespParams'
    }

    return MinesStatusRespParams
  })()

  protocols_mines.MinesRates = (function () {
    function MinesRates(p) {
      this.rates = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    MinesRates.prototype.rates = $util.emptyArray

    MinesRates.create = function create(properties) {
      return new MinesRates(properties)
    }

    MinesRates.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.rates != null && m.rates.length) {
        w.uint32(58).fork()
        for (var i = 0; i < m.rates.length; ++i) w.double(m.rates[i])
        w.ldelim()
      }
      return w
    }

    MinesRates.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_mines.MinesRates()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 7: {
            if (!(m.rates && m.rates.length)) m.rates = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.rates.push(r.double())
            } else m.rates.push(r.double())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    MinesRates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_mines.MinesRates'
    }

    return MinesRates
  })()

  return protocols_mines
})())

export const protocols_ring = ($root.protocols_ring = (() => {
  const protocols_ring = {}

  protocols_ring.RingRiesgo = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'RIESGOUNKNOW')] = 0
    values[(valuesById[1] = 'LOW')] = 1
    values[(valuesById[2] = 'MEDIUM')] = 2
    values[(valuesById[3] = 'HIGH')] = 3
    values[(valuesById[4] = 'EXTREME')] = 4
    return values
  })()

  protocols_ring.RingSegmentos = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'SEGMENTOSUNKNOW')] = 0
    values[(valuesById[10] = 'TEN')] = 10
    values[(valuesById[20] = 'TWENTY')] = 20
    values[(valuesById[30] = 'THIRTY')] = 30
    values[(valuesById[40] = 'FORTY')] = 40
    values[(valuesById[50] = 'FIFTY')] = 50
    return values
  })()

  protocols_ring.RingStartReq = (function () {
    function RingStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStartReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    RingStartReq.prototype.currency = ''
    RingStartReq.prototype.amount = ''
    RingStartReq.prototype.params = null

    RingStartReq.create = function create(properties) {
      return new RingStartReq(properties)
    }

    RingStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(26).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_ring.RingStartParams.encode(
          m.params,
          w.uint32(34).fork(),
        ).ldelim()
      return w
    }

    RingStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          case 3: {
            m.amount = r.string()
            break
          }
          case 4: {
            m.params = $root.protocols_ring.RingStartParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStartReq'
    }

    return RingStartReq
  })()

  protocols_ring.RingStartParams = (function () {
    function RingStartParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStartParams.prototype.riesgo = 0
    RingStartParams.prototype.segmentos = 0
    RingStartParams.prototype.auto = false

    RingStartParams.create = function create(properties) {
      return new RingStartParams(properties)
    }

    RingStartParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.riesgo != null && Object.hasOwnProperty.call(m, 'riesgo'))
        w.uint32(8).int32(m.riesgo)
      if (m.segmentos != null && Object.hasOwnProperty.call(m, 'segmentos'))
        w.uint32(16).int32(m.segmentos)
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(24).bool(m.auto)
      return w
    }

    RingStartParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStartParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.riesgo = r.int32()
            break
          }
          case 2: {
            m.segmentos = r.int32()
            break
          }
          case 3: {
            m.auto = r.bool()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStartParams'
    }

    return RingStartParams
  })()

  protocols_ring.RingStartResp = (function () {
    function RingStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStartResp.prototype.code = 0
    RingStartResp.prototype.msg = ''
    RingStartResp.prototype.data = null

    RingStartResp.create = function create(properties) {
      return new RingStartResp(properties)
    }

    RingStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_ring.RingStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    RingStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_ring.RingStartRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStartResp'
    }

    return RingStartResp
  })()

  protocols_ring.RingStartRespParams = (function () {
    function RingStartRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStartRespParams.prototype.balance = null
    RingStartRespParams.prototype.multiplier = ''
    RingStartRespParams.prototype.number = 0
    RingStartRespParams.prototype.hash = ''
    RingStartRespParams.prototype.payOut = ''
    RingStartRespParams.prototype.result = 0
    RingStartRespParams.prototype.roundId = ''

    RingStartRespParams.create = function create(properties) {
      return new RingStartRespParams(properties)
    }

    RingStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(18).string(m.multiplier)
      if (m.number != null && Object.hasOwnProperty.call(m, 'number'))
        w.uint32(24).uint32(m.number)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(34).string(m.hash)
      if (m.payOut != null && Object.hasOwnProperty.call(m, 'payOut'))
        w.uint32(42).string(m.payOut)
      if (m.result != null && Object.hasOwnProperty.call(m, 'result'))
        w.uint32(48).uint32(m.result)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(58).string(m.roundId)
      return w
    }

    RingStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.multiplier = r.string()
            break
          }
          case 3: {
            m.number = r.uint32()
            break
          }
          case 4: {
            m.hash = r.string()
            break
          }
          case 5: {
            m.payOut = r.string()
            break
          }
          case 6: {
            m.result = r.uint32()
            break
          }
          case 7: {
            m.roundId = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStartRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStartRespParams'
    }

    return RingStartRespParams
  })()

  protocols_ring.RingStatusReq = (function () {
    function RingStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStatusReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    RingStatusReq.prototype.currency = ''

    RingStatusReq.create = function create(properties) {
      return new RingStatusReq(properties)
    }

    RingStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      return w
    }

    RingStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStatusReq'
    }

    return RingStatusReq
  })()

  protocols_ring.RingStatusResp = (function () {
    function RingStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStatusResp.prototype.code = 0
    RingStatusResp.prototype.msg = ''
    RingStatusResp.prototype.data = null

    RingStatusResp.create = function create(properties) {
      return new RingStatusResp(properties)
    }

    RingStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_ring.RingStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    RingStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_ring.RingStatusRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStatusResp'
    }

    return RingStatusResp
  })()

  protocols_ring.RingStatusRespParams = (function () {
    function RingStatusRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingStatusRespParams.prototype.balance = null
    RingStatusRespParams.prototype.hash = ''
    RingStatusRespParams.prototype.rates = ''

    RingStatusRespParams.create = function create(properties) {
      return new RingStatusRespParams(properties)
    }

    RingStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(18).string(m.hash)
      if (m.rates != null && Object.hasOwnProperty.call(m, 'rates'))
        w.uint32(26).string(m.rates)
      return w
    }

    RingStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingStatusRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.hash = r.string()
            break
          }
          case 3: {
            m.rates = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingStatusRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingStatusRespParams'
    }

    return RingStatusRespParams
  })()

  protocols_ring.RingRatesReq = (function () {
    function RingRatesReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingRatesReq.prototype.gameId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    RingRatesReq.prototype.currency = ''

    RingRatesReq.create = function create(properties) {
      return new RingRatesReq(properties)
    }

    RingRatesReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.gameId != null && Object.hasOwnProperty.call(m, 'gameId'))
        w.uint32(8).uint64(m.gameId)
      if (m.currency != null && Object.hasOwnProperty.call(m, 'currency'))
        w.uint32(18).string(m.currency)
      return w
    }

    RingRatesReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingRatesReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.gameId = r.uint64()
            break
          }
          case 2: {
            m.currency = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingRatesReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingRatesReq'
    }

    return RingRatesReq
  })()

  protocols_ring.RingRatesResp = (function () {
    function RingRatesResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingRatesResp.prototype.code = 0
    RingRatesResp.prototype.msg = ''
    RingRatesResp.prototype.data = null

    RingRatesResp.create = function create(properties) {
      return new RingRatesResp(properties)
    }

    RingRatesResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_ring.RingRatesRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    RingRatesResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingRatesResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data = $root.protocols_ring.RingRatesRespParams.decode(
              r,
              r.uint32(),
            )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingRatesResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingRatesResp'
    }

    return RingRatesResp
  })()

  protocols_ring.RingRatesRespParams = (function () {
    function RingRatesRespParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    RingRatesRespParams.prototype.rates = ''

    RingRatesRespParams.create = function create(properties) {
      return new RingRatesRespParams(properties)
    }

    RingRatesRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.rates != null && Object.hasOwnProperty.call(m, 'rates'))
        w.uint32(10).string(m.rates)
      return w
    }

    RingRatesRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_ring.RingRatesRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.rates = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    RingRatesRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_ring.RingRatesRespParams'
    }

    return RingRatesRespParams
  })()

  return protocols_ring
})())

export const protocols_scratch_match = ($root.protocols_scratch_match = (() => {
  const protocols_scratch_match = {}

  protocols_scratch_match.ScratchMatchPlayCode = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'NULL')] = 0
    values[(valuesById[1] = 'Play')] = 1
    return values
  })()

  protocols_scratch_match.ScratchMatchType = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'UnKnowLottery')] = 0
    values[(valuesById[1] = 'LineLottery')] = 1
    values[(valuesById[2] = 'GraphicLottery')] = 2
    values[(valuesById[3] = 'DigitalLottery')] = 3
    values[(valuesById[4] = 'GoBananasLottery')] = 4
    values[(valuesById[5] = 'RedHot7sLottery')] = 5
    values[(valuesById[6] = 'GoForGoLdLottery')] = 6
    return values
  })()

  protocols_scratch_match.ScratchMatchStatus = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'UnKnowStatus')] = 0
    values[(valuesById[1] = 'Pending')] = 1
    values[(valuesById[2] = 'Win')] = 2
    values[(valuesById[3] = 'Lose')] = 3
    return values
  })()

  protocols_scratch_match.ScratchMatchLineLotteryType = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'line_0')] = 0
    values[(valuesById[1] = 'horizontal_1')] = 1
    values[(valuesById[2] = 'horizontal_2')] = 2
    values[(valuesById[3] = 'horizontal_3')] = 3
    values[(valuesById[4] = 'vertical_1')] = 4
    values[(valuesById[5] = 'vertical_2')] = 5
    values[(valuesById[6] = 'vertical_3')] = 6
    values[(valuesById[7] = 'incline_1')] = 7
    values[(valuesById[8] = 'incline_2')] = 8
    return values
  })()

  protocols_scratch_match.ScratchMatchScrapeType = (function () {
    const valuesById = {},
      values = Object.create(valuesById)
    values[(valuesById[0] = 'ScrapePart')] = 0
    values[(valuesById[1] = 'ScrapeAll')] = 1
    return values
  })()

  protocols_scratch_match.ScratchMatchStatusReq = (function () {
    function ScratchMatchStatusReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStatusReq.prototype.params = null

    ScratchMatchStatusReq.create = function create(properties) {
      return new ScratchMatchStatusReq(properties)
    }

    ScratchMatchStatusReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_scratch_match.ScratchMatchStatusParams.encode(
          m.params,
          w.uint32(10).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchStatusReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStatusReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.params =
              $root.protocols_scratch_match.ScratchMatchStatusParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStatusReq'
    }

    return ScratchMatchStatusReq
  })()

  protocols_scratch_match.ScratchMatchStatusParams = (function () {
    function ScratchMatchStatusParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStatusParams.create = function create(properties) {
      return new ScratchMatchStatusParams(properties)
    }

    ScratchMatchStatusParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      return w
    }

    ScratchMatchStatusParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStatusParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStatusParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStatusParams'
    }

    return ScratchMatchStatusParams
  })()

  protocols_scratch_match.ScratchMatchStatusResp = (function () {
    function ScratchMatchStatusResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStatusResp.prototype.code = 0
    ScratchMatchStatusResp.prototype.msg = ''
    ScratchMatchStatusResp.prototype.data = null

    ScratchMatchStatusResp.create = function create(properties) {
      return new ScratchMatchStatusResp(properties)
    }

    ScratchMatchStatusResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_scratch_match.ScratchMatchStatusRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchStatusResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStatusResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data =
              $root.protocols_scratch_match.ScratchMatchStatusRespParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStatusResp'
    }

    return ScratchMatchStatusResp
  })()

  protocols_scratch_match.ScratchMatchStatusRespParams = (function () {
    function ScratchMatchStatusRespParams(p) {
      this.scratchTypeList = []
      this.priceMap = {}
      this.maxWinMap = {}
      this.history = []
      this.lineLotteryRateMap = {}
      this.graphicLotteryRateList = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStatusRespParams.prototype.balance = null
    ScratchMatchStatusRespParams.prototype.state = 0
    ScratchMatchStatusRespParams.prototype.roundId = ''
    ScratchMatchStatusRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    ScratchMatchStatusRespParams.prototype.hash = ''
    ScratchMatchStatusRespParams.prototype.amount = ''
    ScratchMatchStatusRespParams.prototype.multiplier = ''
    ScratchMatchStatusRespParams.prototype.payout = ''
    ScratchMatchStatusRespParams.prototype.scratchTypeList = $util.emptyArray
    ScratchMatchStatusRespParams.prototype.notOpenedNum = 0
    ScratchMatchStatusRespParams.prototype.lotteryTotal = 0
    ScratchMatchStatusRespParams.prototype.scratchType = 0
    ScratchMatchStatusRespParams.prototype.priceMap = $util.emptyObject
    ScratchMatchStatusRespParams.prototype.maxWinMap = $util.emptyObject
    ScratchMatchStatusRespParams.prototype.history = $util.emptyArray
    ScratchMatchStatusRespParams.prototype.defaultScratchType = 0
    ScratchMatchStatusRespParams.prototype.currentLotteryNo = ''
    ScratchMatchStatusRespParams.prototype.nextWinData = null
    ScratchMatchStatusRespParams.prototype.lineLotteryRateMap =
      $util.emptyObject
    ScratchMatchStatusRespParams.prototype.graphicLotteryRateList =
      $util.emptyArray
    ScratchMatchStatusRespParams.prototype.sumPayout = ''

    ScratchMatchStatusRespParams.create = function create(properties) {
      return new ScratchMatchStatusRespParams(properties)
    }

    ScratchMatchStatusRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).int32(m.state)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(26).string(m.roundId)
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(32).uint64(m.createdAt)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(50).string(m.amount)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(58).string(m.multiplier)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(66).string(m.payout)
      if (m.scratchTypeList != null && m.scratchTypeList.length) {
        w.uint32(74).fork()
        for (var i = 0; i < m.scratchTypeList.length; ++i)
          w.int32(m.scratchTypeList[i])
        w.ldelim()
      }
      if (
        m.notOpenedNum != null &&
        Object.hasOwnProperty.call(m, 'notOpenedNum')
      )
        w.uint32(80).int32(m.notOpenedNum)
      if (
        m.lotteryTotal != null &&
        Object.hasOwnProperty.call(m, 'lotteryTotal')
      )
        w.uint32(88).int32(m.lotteryTotal)
      if (m.scratchType != null && Object.hasOwnProperty.call(m, 'scratchType'))
        w.uint32(96).int32(m.scratchType)
      if (m.priceMap != null && Object.hasOwnProperty.call(m, 'priceMap')) {
        for (var ks = Object.keys(m.priceMap), i = 0; i < ks.length; ++i) {
          w.uint32(106)
            .fork()
            .uint32(8)
            .int32(ks[i])
            .uint32(18)
            .string(m.priceMap[ks[i]])
            .ldelim()
        }
      }
      if (m.maxWinMap != null && Object.hasOwnProperty.call(m, 'maxWinMap')) {
        for (var ks = Object.keys(m.maxWinMap), i = 0; i < ks.length; ++i) {
          w.uint32(114)
            .fork()
            .uint32(8)
            .int32(ks[i])
            .uint32(18)
            .string(m.maxWinMap[ks[i]])
            .ldelim()
        }
      }
      if (m.history != null && m.history.length) {
        for (var i = 0; i < m.history.length; ++i)
          $root.protocols_scratch_match.ScratchMatchHistory.encode(
            m.history[i],
            w.uint32(122).fork(),
          ).ldelim()
      }
      if (
        m.defaultScratchType != null &&
        Object.hasOwnProperty.call(m, 'defaultScratchType')
      )
        w.uint32(128).int32(m.defaultScratchType)
      if (
        m.currentLotteryNo != null &&
        Object.hasOwnProperty.call(m, 'currentLotteryNo')
      )
        w.uint32(138).string(m.currentLotteryNo)
      if (m.nextWinData != null && Object.hasOwnProperty.call(m, 'nextWinData'))
        $root.protocols_scratch_match.ScratchMatchWinData.encode(
          m.nextWinData,
          w.uint32(146).fork(),
        ).ldelim()
      if (
        m.lineLotteryRateMap != null &&
        Object.hasOwnProperty.call(m, 'lineLotteryRateMap')
      ) {
        for (
          var ks = Object.keys(m.lineLotteryRateMap), i = 0;
          i < ks.length;
          ++i
        ) {
          w.uint32(154)
            .fork()
            .uint32(8)
            .int32(ks[i])
            .uint32(18)
            .string(m.lineLotteryRateMap[ks[i]])
            .ldelim()
        }
      }
      if (m.sumPayout != null && Object.hasOwnProperty.call(m, 'sumPayout'))
        w.uint32(170).string(m.sumPayout)
      if (m.graphicLotteryRateList != null && m.graphicLotteryRateList.length) {
        for (var i = 0; i < m.graphicLotteryRateList.length; ++i)
          $root.protocols_scratch_match.ScratchMatchRate.encode(
            m.graphicLotteryRateList[i],
            w.uint32(178).fork(),
          ).ldelim()
      }
      return w
    }

    ScratchMatchStatusRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStatusRespParams(),
        k,
        value
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.state = r.int32()
            break
          }
          case 3: {
            m.roundId = r.string()
            break
          }
          case 4: {
            m.createdAt = r.uint64()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          case 6: {
            m.amount = r.string()
            break
          }
          case 7: {
            m.multiplier = r.string()
            break
          }
          case 8: {
            m.payout = r.string()
            break
          }
          case 9: {
            if (!(m.scratchTypeList && m.scratchTypeList.length))
              m.scratchTypeList = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.scratchTypeList.push(r.int32())
            } else m.scratchTypeList.push(r.int32())
            break
          }
          case 10: {
            m.notOpenedNum = r.int32()
            break
          }
          case 11: {
            m.lotteryTotal = r.int32()
            break
          }
          case 12: {
            m.scratchType = r.int32()
            break
          }
          case 13: {
            if (m.priceMap === $util.emptyObject) m.priceMap = {}
            var c2 = r.uint32() + r.pos
            k = 0
            value = ''
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.int32()
                  break
                case 2:
                  value = r.string()
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.priceMap[k] = value
            break
          }
          case 14: {
            if (m.maxWinMap === $util.emptyObject) m.maxWinMap = {}
            var c2 = r.uint32() + r.pos
            k = 0
            value = ''
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.int32()
                  break
                case 2:
                  value = r.string()
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.maxWinMap[k] = value
            break
          }
          case 15: {
            if (!(m.history && m.history.length)) m.history = []
            m.history.push(
              $root.protocols_scratch_match.ScratchMatchHistory.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 16: {
            m.defaultScratchType = r.int32()
            break
          }
          case 17: {
            m.currentLotteryNo = r.string()
            break
          }
          case 18: {
            m.nextWinData =
              $root.protocols_scratch_match.ScratchMatchWinData.decode(
                r,
                r.uint32(),
              )
            break
          }
          case 19: {
            if (m.lineLotteryRateMap === $util.emptyObject)
              m.lineLotteryRateMap = {}
            var c2 = r.uint32() + r.pos
            k = 0
            value = ''
            while (r.pos < c2) {
              var tag2 = r.uint32()
              switch (tag2 >>> 3) {
                case 1:
                  k = r.int32()
                  break
                case 2:
                  value = r.string()
                  break
                default:
                  r.skipType(tag2 & 7)
                  break
              }
            }
            m.lineLotteryRateMap[k] = value
            break
          }
          case 22: {
            if (!(m.graphicLotteryRateList && m.graphicLotteryRateList.length))
              m.graphicLotteryRateList = []
            m.graphicLotteryRateList.push(
              $root.protocols_scratch_match.ScratchMatchRate.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 21: {
            m.sumPayout = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStatusRespParams.getTypeUrl = function getTypeUrl(
      typeUrlPrefix,
    ) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return (
        typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStatusRespParams'
      )
    }

    return ScratchMatchStatusRespParams
  })()

  protocols_scratch_match.ScratchMatchStartReq = (function () {
    function ScratchMatchStartReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStartReq.prototype.amount = ''
    ScratchMatchStartReq.prototype.params = null

    ScratchMatchStartReq.create = function create(properties) {
      return new ScratchMatchStartReq(properties)
    }

    ScratchMatchStartReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(10).string(m.amount)
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_scratch_match.ScratchMatchStartParams.encode(
          m.params,
          w.uint32(18).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchStartReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStartReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.amount = r.string()
            break
          }
          case 2: {
            m.params =
              $root.protocols_scratch_match.ScratchMatchStartParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStartReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStartReq'
    }

    return ScratchMatchStartReq
  })()

  protocols_scratch_match.ScratchMatchStartParams = (function () {
    function ScratchMatchStartParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStartParams.prototype.auto = false
    ScratchMatchStartParams.prototype.scratchType = 0
    ScratchMatchStartParams.prototype.lotteryTotal = 0

    ScratchMatchStartParams.create = function create(properties) {
      return new ScratchMatchStartParams(properties)
    }

    ScratchMatchStartParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.auto != null && Object.hasOwnProperty.call(m, 'auto'))
        w.uint32(8).bool(m.auto)
      if (m.scratchType != null && Object.hasOwnProperty.call(m, 'scratchType'))
        w.uint32(16).int32(m.scratchType)
      if (
        m.lotteryTotal != null &&
        Object.hasOwnProperty.call(m, 'lotteryTotal')
      )
        w.uint32(24).int32(m.lotteryTotal)
      return w
    }

    ScratchMatchStartParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStartParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.auto = r.bool()
            break
          }
          case 2: {
            m.scratchType = r.int32()
            break
          }
          case 3: {
            m.lotteryTotal = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStartParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStartParams'
    }

    return ScratchMatchStartParams
  })()

  protocols_scratch_match.ScratchMatchStartResp = (function () {
    function ScratchMatchStartResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStartResp.prototype.code = 0
    ScratchMatchStartResp.prototype.msg = ''
    ScratchMatchStartResp.prototype.data = null

    ScratchMatchStartResp.create = function create(properties) {
      return new ScratchMatchStartResp(properties)
    }

    ScratchMatchStartResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_scratch_match.ScratchMatchStartRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchStartResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStartResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data =
              $root.protocols_scratch_match.ScratchMatchStartRespParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStartResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStartResp'
    }

    return ScratchMatchStartResp
  })()

  protocols_scratch_match.ScratchMatchStartRespParams = (function () {
    function ScratchMatchStartRespParams(p) {
      this.history = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchStartRespParams.prototype.balance = null
    ScratchMatchStartRespParams.prototype.state = 0
    ScratchMatchStartRespParams.prototype.roundId = ''
    ScratchMatchStartRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    ScratchMatchStartRespParams.prototype.hash = ''
    ScratchMatchStartRespParams.prototype.amount = ''
    ScratchMatchStartRespParams.prototype.multiplier = ''
    ScratchMatchStartRespParams.prototype.payout = ''
    ScratchMatchStartRespParams.prototype.lotteryTotal = 0
    ScratchMatchStartRespParams.prototype.notOpenedNum = 0
    ScratchMatchStartRespParams.prototype.scratchType = 0
    ScratchMatchStartRespParams.prototype.nextLotteryNo = ''
    ScratchMatchStartRespParams.prototype.price = ''
    ScratchMatchStartRespParams.prototype.nextWinData = null
    ScratchMatchStartRespParams.prototype.history = $util.emptyArray
    ScratchMatchStartRespParams.prototype.sumPayout = ''

    ScratchMatchStartRespParams.create = function create(properties) {
      return new ScratchMatchStartRespParams(properties)
    }

    ScratchMatchStartRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).int32(m.state)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(26).string(m.roundId)
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(32).uint64(m.createdAt)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(50).string(m.amount)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(58).string(m.multiplier)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(66).string(m.payout)
      if (
        m.lotteryTotal != null &&
        Object.hasOwnProperty.call(m, 'lotteryTotal')
      )
        w.uint32(72).int32(m.lotteryTotal)
      if (
        m.notOpenedNum != null &&
        Object.hasOwnProperty.call(m, 'notOpenedNum')
      )
        w.uint32(80).int32(m.notOpenedNum)
      if (m.scratchType != null && Object.hasOwnProperty.call(m, 'scratchType'))
        w.uint32(88).int32(m.scratchType)
      if (
        m.nextLotteryNo != null &&
        Object.hasOwnProperty.call(m, 'nextLotteryNo')
      )
        w.uint32(98).string(m.nextLotteryNo)
      if (m.price != null && Object.hasOwnProperty.call(m, 'price'))
        w.uint32(106).string(m.price)
      if (m.nextWinData != null && Object.hasOwnProperty.call(m, 'nextWinData'))
        $root.protocols_scratch_match.ScratchMatchWinData.encode(
          m.nextWinData,
          w.uint32(122).fork(),
        ).ldelim()
      if (m.history != null && m.history.length) {
        for (var i = 0; i < m.history.length; ++i)
          $root.protocols_scratch_match.ScratchMatchHistory.encode(
            m.history[i],
            w.uint32(130).fork(),
          ).ldelim()
      }
      if (m.sumPayout != null && Object.hasOwnProperty.call(m, 'sumPayout'))
        w.uint32(138).string(m.sumPayout)
      return w
    }

    ScratchMatchStartRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchStartRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.state = r.int32()
            break
          }
          case 3: {
            m.roundId = r.string()
            break
          }
          case 4: {
            m.createdAt = r.uint64()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          case 6: {
            m.amount = r.string()
            break
          }
          case 7: {
            m.multiplier = r.string()
            break
          }
          case 8: {
            m.payout = r.string()
            break
          }
          case 9: {
            m.lotteryTotal = r.int32()
            break
          }
          case 10: {
            m.notOpenedNum = r.int32()
            break
          }
          case 11: {
            m.scratchType = r.int32()
            break
          }
          case 12: {
            m.nextLotteryNo = r.string()
            break
          }
          case 13: {
            m.price = r.string()
            break
          }
          case 15: {
            m.nextWinData =
              $root.protocols_scratch_match.ScratchMatchWinData.decode(
                r,
                r.uint32(),
              )
            break
          }
          case 16: {
            if (!(m.history && m.history.length)) m.history = []
            m.history.push(
              $root.protocols_scratch_match.ScratchMatchHistory.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 17: {
            m.sumPayout = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchStartRespParams.getTypeUrl = function getTypeUrl(
      typeUrlPrefix,
    ) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return (
        typeUrlPrefix + '/protocols_scratch_match.ScratchMatchStartRespParams'
      )
    }

    return ScratchMatchStartRespParams
  })()

  protocols_scratch_match.ScratchMatchPlayReq = (function () {
    function ScratchMatchPlayReq(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchPlayReq.prototype.params = null

    ScratchMatchPlayReq.create = function create(properties) {
      return new ScratchMatchPlayReq(properties)
    }

    ScratchMatchPlayReq.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.params != null && Object.hasOwnProperty.call(m, 'params'))
        $root.protocols_scratch_match.ScratchMatchPlayReqParams.encode(
          m.params,
          w.uint32(10).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchPlayReq.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchPlayReq()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.params =
              $root.protocols_scratch_match.ScratchMatchPlayReqParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchPlayReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchPlayReq'
    }

    return ScratchMatchPlayReq
  })()

  protocols_scratch_match.ScratchMatchPlayReqParams = (function () {
    function ScratchMatchPlayReqParams(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchPlayReqParams.prototype.code = 0
    ScratchMatchPlayReqParams.prototype.type = 0

    ScratchMatchPlayReqParams.create = function create(properties) {
      return new ScratchMatchPlayReqParams(properties)
    }

    ScratchMatchPlayReqParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.type != null && Object.hasOwnProperty.call(m, 'type'))
        w.uint32(16).int32(m.type)
      return w
    }

    ScratchMatchPlayReqParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchPlayReqParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.type = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchPlayReqParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return (
        typeUrlPrefix + '/protocols_scratch_match.ScratchMatchPlayReqParams'
      )
    }

    return ScratchMatchPlayReqParams
  })()

  protocols_scratch_match.ScratchMatchPlayResp = (function () {
    function ScratchMatchPlayResp(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchPlayResp.prototype.code = 0
    ScratchMatchPlayResp.prototype.msg = ''
    ScratchMatchPlayResp.prototype.data = null

    ScratchMatchPlayResp.create = function create(properties) {
      return new ScratchMatchPlayResp(properties)
    }

    ScratchMatchPlayResp.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.code != null && Object.hasOwnProperty.call(m, 'code'))
        w.uint32(8).int32(m.code)
      if (m.msg != null && Object.hasOwnProperty.call(m, 'msg'))
        w.uint32(18).string(m.msg)
      if (m.data != null && Object.hasOwnProperty.call(m, 'data'))
        $root.protocols_scratch_match.ScratchMatchPlayRespParams.encode(
          m.data,
          w.uint32(26).fork(),
        ).ldelim()
      return w
    }

    ScratchMatchPlayResp.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchPlayResp()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.code = r.int32()
            break
          }
          case 2: {
            m.msg = r.string()
            break
          }
          case 3: {
            m.data =
              $root.protocols_scratch_match.ScratchMatchPlayRespParams.decode(
                r,
                r.uint32(),
              )
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchPlayResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchPlayResp'
    }

    return ScratchMatchPlayResp
  })()

  protocols_scratch_match.ScratchMatchPlayRespParams = (function () {
    function ScratchMatchPlayRespParams(p) {
      this.history = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchPlayRespParams.prototype.balance = null
    ScratchMatchPlayRespParams.prototype.state = 0
    ScratchMatchPlayRespParams.prototype.roundId = ''
    ScratchMatchPlayRespParams.prototype.createdAt = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0
    ScratchMatchPlayRespParams.prototype.hash = ''
    ScratchMatchPlayRespParams.prototype.amount = ''
    ScratchMatchPlayRespParams.prototype.multiplier = ''
    ScratchMatchPlayRespParams.prototype.payout = ''
    ScratchMatchPlayRespParams.prototype.lotteryTotal = 0
    ScratchMatchPlayRespParams.prototype.notOpenedNum = 0
    ScratchMatchPlayRespParams.prototype.scratchType = 0
    ScratchMatchPlayRespParams.prototype.thisPayout = ''
    ScratchMatchPlayRespParams.prototype.history = $util.emptyArray
    ScratchMatchPlayRespParams.prototype.nextLotteryNo = ''
    ScratchMatchPlayRespParams.prototype.winData = null
    ScratchMatchPlayRespParams.prototype.nextWinData = null
    ScratchMatchPlayRespParams.prototype.sumPayout = ''

    ScratchMatchPlayRespParams.create = function create(properties) {
      return new ScratchMatchPlayRespParams(properties)
    }

    ScratchMatchPlayRespParams.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.balance != null && Object.hasOwnProperty.call(m, 'balance'))
        $root.protocols_game.Balance.encode(
          m.balance,
          w.uint32(10).fork(),
        ).ldelim()
      if (m.state != null && Object.hasOwnProperty.call(m, 'state'))
        w.uint32(16).int32(m.state)
      if (m.roundId != null && Object.hasOwnProperty.call(m, 'roundId'))
        w.uint32(26).string(m.roundId)
      if (m.createdAt != null && Object.hasOwnProperty.call(m, 'createdAt'))
        w.uint32(32).uint64(m.createdAt)
      if (m.hash != null && Object.hasOwnProperty.call(m, 'hash'))
        w.uint32(42).string(m.hash)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(50).string(m.amount)
      if (m.multiplier != null && Object.hasOwnProperty.call(m, 'multiplier'))
        w.uint32(58).string(m.multiplier)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(66).string(m.payout)
      if (
        m.lotteryTotal != null &&
        Object.hasOwnProperty.call(m, 'lotteryTotal')
      )
        w.uint32(72).int32(m.lotteryTotal)
      if (
        m.notOpenedNum != null &&
        Object.hasOwnProperty.call(m, 'notOpenedNum')
      )
        w.uint32(80).int32(m.notOpenedNum)
      if (m.scratchType != null && Object.hasOwnProperty.call(m, 'scratchType'))
        w.uint32(88).int32(m.scratchType)
      if (m.thisPayout != null && Object.hasOwnProperty.call(m, 'thisPayout'))
        w.uint32(98).string(m.thisPayout)
      if (m.history != null && m.history.length) {
        for (var i = 0; i < m.history.length; ++i)
          $root.protocols_scratch_match.ScratchMatchHistory.encode(
            m.history[i],
            w.uint32(106).fork(),
          ).ldelim()
      }
      if (
        m.nextLotteryNo != null &&
        Object.hasOwnProperty.call(m, 'nextLotteryNo')
      )
        w.uint32(114).string(m.nextLotteryNo)
      if (m.winData != null && Object.hasOwnProperty.call(m, 'winData'))
        $root.protocols_scratch_match.ScratchMatchWinData.encode(
          m.winData,
          w.uint32(122).fork(),
        ).ldelim()
      if (m.nextWinData != null && Object.hasOwnProperty.call(m, 'nextWinData'))
        $root.protocols_scratch_match.ScratchMatchWinData.encode(
          m.nextWinData,
          w.uint32(130).fork(),
        ).ldelim()
      if (m.sumPayout != null && Object.hasOwnProperty.call(m, 'sumPayout'))
        w.uint32(138).string(m.sumPayout)
      return w
    }

    ScratchMatchPlayRespParams.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchPlayRespParams()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.balance = $root.protocols_game.Balance.decode(r, r.uint32())
            break
          }
          case 2: {
            m.state = r.int32()
            break
          }
          case 3: {
            m.roundId = r.string()
            break
          }
          case 4: {
            m.createdAt = r.uint64()
            break
          }
          case 5: {
            m.hash = r.string()
            break
          }
          case 6: {
            m.amount = r.string()
            break
          }
          case 7: {
            m.multiplier = r.string()
            break
          }
          case 8: {
            m.payout = r.string()
            break
          }
          case 9: {
            m.lotteryTotal = r.int32()
            break
          }
          case 10: {
            m.notOpenedNum = r.int32()
            break
          }
          case 11: {
            m.scratchType = r.int32()
            break
          }
          case 12: {
            m.thisPayout = r.string()
            break
          }
          case 13: {
            if (!(m.history && m.history.length)) m.history = []
            m.history.push(
              $root.protocols_scratch_match.ScratchMatchHistory.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 14: {
            m.nextLotteryNo = r.string()
            break
          }
          case 15: {
            m.winData =
              $root.protocols_scratch_match.ScratchMatchWinData.decode(
                r,
                r.uint32(),
              )
            break
          }
          case 16: {
            m.nextWinData =
              $root.protocols_scratch_match.ScratchMatchWinData.decode(
                r,
                r.uint32(),
              )
            break
          }
          case 17: {
            m.sumPayout = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchPlayRespParams.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return (
        typeUrlPrefix + '/protocols_scratch_match.ScratchMatchPlayRespParams'
      )
    }

    return ScratchMatchPlayRespParams
  })()

  protocols_scratch_match.ScratchMatchWinData = (function () {
    function ScratchMatchWinData(p) {
      this.lotteryResult = []
      this.lotteryResultStatus = []
      this.lotteryResultList = []
      this.showNums = []
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchWinData.prototype.lotteryNo = ''
    ScratchMatchWinData.prototype.payout = ''
    ScratchMatchWinData.prototype.price = ''
    ScratchMatchWinData.prototype.multi = ''
    ScratchMatchWinData.prototype.lotteryResult = $util.emptyArray
    ScratchMatchWinData.prototype.lotteryResultStatus = $util.emptyArray
    ScratchMatchWinData.prototype.lotteryResultList = $util.emptyArray
    ScratchMatchWinData.prototype.showNums = $util.emptyArray

    ScratchMatchWinData.create = function create(properties) {
      return new ScratchMatchWinData(properties)
    }

    ScratchMatchWinData.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.lotteryNo != null && Object.hasOwnProperty.call(m, 'lotteryNo'))
        w.uint32(10).string(m.lotteryNo)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(18).string(m.payout)
      if (m.price != null && Object.hasOwnProperty.call(m, 'price'))
        w.uint32(26).string(m.price)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(34).string(m.multi)
      if (m.lotteryResult != null && m.lotteryResult.length) {
        for (var i = 0; i < m.lotteryResult.length; ++i)
          w.uint32(42).string(m.lotteryResult[i])
      }
      if (m.lotteryResultStatus != null && m.lotteryResultStatus.length) {
        w.uint32(50).fork()
        for (var i = 0; i < m.lotteryResultStatus.length; ++i)
          w.int32(m.lotteryResultStatus[i])
        w.ldelim()
      }
      if (m.lotteryResultList != null && m.lotteryResultList.length) {
        for (var i = 0; i < m.lotteryResultList.length; ++i)
          $root.protocols_scratch_match.ScratchMatchLotteryResult.encode(
            m.lotteryResultList[i],
            w.uint32(58).fork(),
          ).ldelim()
      }
      if (m.showNums != null && m.showNums.length) {
        for (var i = 0; i < m.showNums.length; ++i)
          w.uint32(66).string(m.showNums[i])
      }
      return w
    }

    ScratchMatchWinData.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchWinData()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.lotteryNo = r.string()
            break
          }
          case 2: {
            m.payout = r.string()
            break
          }
          case 3: {
            m.price = r.string()
            break
          }
          case 4: {
            m.multi = r.string()
            break
          }
          case 5: {
            if (!(m.lotteryResult && m.lotteryResult.length))
              m.lotteryResult = []
            m.lotteryResult.push(r.string())
            break
          }
          case 6: {
            if (!(m.lotteryResultStatus && m.lotteryResultStatus.length))
              m.lotteryResultStatus = []
            if ((t & 7) === 2) {
              var c2 = r.uint32() + r.pos
              while (r.pos < c2) m.lotteryResultStatus.push(r.int32())
            } else m.lotteryResultStatus.push(r.int32())
            break
          }
          case 7: {
            if (!(m.lotteryResultList && m.lotteryResultList.length))
              m.lotteryResultList = []
            m.lotteryResultList.push(
              $root.protocols_scratch_match.ScratchMatchLotteryResult.decode(
                r,
                r.uint32(),
              ),
            )
            break
          }
          case 8: {
            if (!(m.showNums && m.showNums.length)) m.showNums = []
            m.showNums.push(r.string())
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchWinData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchWinData'
    }

    return ScratchMatchWinData
  })()

  protocols_scratch_match.ScratchMatchHistory = (function () {
    function ScratchMatchHistory(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchHistory.prototype.lotteryNo = ''
    ScratchMatchHistory.prototype.payout = ''
    ScratchMatchHistory.prototype.price = ''
    ScratchMatchHistory.prototype.multi = ''
    ScratchMatchHistory.prototype.scratchType = 0
    ScratchMatchHistory.prototype.scratchStatus = 0

    ScratchMatchHistory.create = function create(properties) {
      return new ScratchMatchHistory(properties)
    }

    ScratchMatchHistory.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.lotteryNo != null && Object.hasOwnProperty.call(m, 'lotteryNo'))
        w.uint32(10).string(m.lotteryNo)
      if (m.payout != null && Object.hasOwnProperty.call(m, 'payout'))
        w.uint32(18).string(m.payout)
      if (m.price != null && Object.hasOwnProperty.call(m, 'price'))
        w.uint32(26).string(m.price)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(34).string(m.multi)
      if (m.scratchType != null && Object.hasOwnProperty.call(m, 'scratchType'))
        w.uint32(40).int32(m.scratchType)
      if (
        m.scratchStatus != null &&
        Object.hasOwnProperty.call(m, 'scratchStatus')
      )
        w.uint32(48).int32(m.scratchStatus)
      return w
    }

    ScratchMatchHistory.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchHistory()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.lotteryNo = r.string()
            break
          }
          case 2: {
            m.payout = r.string()
            break
          }
          case 3: {
            m.price = r.string()
            break
          }
          case 4: {
            m.multi = r.string()
            break
          }
          case 5: {
            m.scratchType = r.int32()
            break
          }
          case 6: {
            m.scratchStatus = r.int32()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchHistory'
    }

    return ScratchMatchHistory
  })()

  protocols_scratch_match.ScratchMatchRate = (function () {
    function ScratchMatchRate(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchRate.prototype.rate = ''
    ScratchMatchRate.prototype.item = ''

    ScratchMatchRate.create = function create(properties) {
      return new ScratchMatchRate(properties)
    }

    ScratchMatchRate.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.rate != null && Object.hasOwnProperty.call(m, 'rate'))
        w.uint32(10).string(m.rate)
      if (m.item != null && Object.hasOwnProperty.call(m, 'item'))
        w.uint32(18).string(m.item)
      return w
    }

    ScratchMatchRate.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchRate()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.rate = r.string()
            break
          }
          case 2: {
            m.item = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchRate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return typeUrlPrefix + '/protocols_scratch_match.ScratchMatchRate'
    }

    return ScratchMatchRate
  })()

  protocols_scratch_match.ScratchMatchLotteryResult = (function () {
    function ScratchMatchLotteryResult(p) {
      if (p)
        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
          if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    ScratchMatchLotteryResult.prototype.number = ''
    ScratchMatchLotteryResult.prototype.amount = ''
    ScratchMatchLotteryResult.prototype.multi = ''

    ScratchMatchLotteryResult.create = function create(properties) {
      return new ScratchMatchLotteryResult(properties)
    }

    ScratchMatchLotteryResult.encode = function encode(m, w) {
      if (!w) w = $Writer.create()
      if (m.number != null && Object.hasOwnProperty.call(m, 'number'))
        w.uint32(10).string(m.number)
      if (m.amount != null && Object.hasOwnProperty.call(m, 'amount'))
        w.uint32(18).string(m.amount)
      if (m.multi != null && Object.hasOwnProperty.call(m, 'multi'))
        w.uint32(26).string(m.multi)
      return w
    }

    ScratchMatchLotteryResult.decode = function decode(r, l) {
      if (!(r instanceof $Reader)) r = $Reader.create(r)
      var c = l === undefined ? r.len : r.pos + l,
        m = new $root.protocols_scratch_match.ScratchMatchLotteryResult()
      while (r.pos < c) {
        var t = r.uint32()
        switch (t >>> 3) {
          case 1: {
            m.number = r.string()
            break
          }
          case 2: {
            m.amount = r.string()
            break
          }
          case 3: {
            m.multi = r.string()
            break
          }
          default:
            r.skipType(t & 7)
            break
        }
      }
      return m
    }

    ScratchMatchLotteryResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com'
      }
      return (
        typeUrlPrefix + '/protocols_scratch_match.ScratchMatchLotteryResult'
      )
    }

    return ScratchMatchLotteryResult
  })()

  return protocols_scratch_match
})())

export { $root as default }

import type { ComponentStyleConfig } from '@chakra-ui/react'

import {
  NAME,
  PARTS,
} from '@/modules/inhouse/common/components/bet-button/BetButton'

import theme from './inhouse'

const { buttonNormalBg, buttonNormalColor } = theme
const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgImage: buttonNormalBg,
    },
    maintext: {
      color: buttonNormalColor,
      _disabled: {
        color: 'gray.900',
      },
    },
    subtext: {
      _disabled: {
        color: 'gray.900',
      },
    },
  },
  variants: {
    blue: {
      box: {
        bg: 'linear-gradient(180deg, #3E91FF 0%, #1376FA 100%)',
      },
    },
    green: {
      box: {
        bg: 'linear-gradient(0deg, #02724B 0%, #0F9D6B 100%)',
      },
    },
    red: {
      box: {
        bg: 'linear-gradient(180deg, #FF466D 0%, #FC2552 100%)',
      },
    },
  },
}

export const name = NAME
export default componentStyle

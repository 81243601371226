import * as Sentry from '@sentry/react'

import type { Cmd_Proto_Type } from '@/common/cmd-proto'
import { GameReqMsgId2RespMsgId } from '@/common/cmd-proto'
import { isProduction } from '@/common/env'
import GmpSocket from '@/common/gmp-ws'
import { warn } from '@/common/logger'
import { getSocket } from '@/common/ws'
import {
  getUserId,
  getUserToken,
  removeUserId,
  removeUserToken,
} from '@/modules/user/storage'

import type { BetInfo, HashSeedHistory } from '../logic/atoms/common-state'
import type { RequestRes } from './request'
import request from './request'

export function getApiUrl(suffix?: string) {
  return suffix
    ? window.GAME_INFO.apiBaseUrl + suffix
    : window.GAME_INFO.apiBaseUrl
}

export function getWssUrl(suffix?: string) {
  return suffix
    ? window.GAME_INFO.wssBaseUrl + suffix
    : window.GAME_INFO.wssBaseUrl
}

export function getGameUrl() {
  return window.GAME_INFO.gameBaseUrl
}

export const post = async (url: string, data: any, check_login?: boolean) => {
  const res = await request({
    method: 'post',
    url: getApiUrl(url),
    timeout: 60000,
    data,
  })
  if (!check_login && res?.data?.code === 1002) {
    try {
      if (isProduction()) {
        Sentry.captureMessage('auth_error', {
          extra: {
            err_msg: JSON.stringify(res || {}),
            err_params: JSON.stringify(data || {}),
            err_url: url,
          },
        })
      }
    } catch (e) {
      //
    }
    setTimeout(() => {
      window.location.reload()
    }, 500)
    return
  }

  return res?.data
}

export async function postWS(
  command: any,
  ...params: any
): Promise<RequestRes> {
  const socket = getSocket().socket

  function doPost(resolve: (res: any) => void, reject: (err: string) => void) {
    if (socket) {
      socket.emit(command, ...params, (res: RequestRes) => {
        if (res.code === 519) {
          setTimeout(() => doPost(resolve, reject), 300)
        } else if (res.code === 1002) {
          try {
            if (isProduction()) {
              Sentry.captureMessage('inhouse_ws_error', {
                extra: {
                  err_msg: JSON.stringify(res || {}),
                  err_command: command,
                  err_params: JSON.stringify(params || {}),
                },
              })
            }
          } catch (e) {
            //
          }
          warn(JSON.stringify(res))
        } else {
          resolve(res)
        }
      })
    } else {
      reject('FAIL_TO_CONNECT')
    }
  }

  return new Promise(doPost)
}

export async function postGmpWS<T>(
  command: Cmd_Proto_Type,
  params: any,
): Promise<RequestRes<T>> {
  // 超时时间
  const TIMEOUT = 10000
  const RespMsgId = GameReqMsgId2RespMsgId[command]
  GmpSocket.off(RespMsgId, () => {
    //
  })

  function doPost(resolve: (res: any) => void, reject: (err: string) => void) {
    try {
      GmpSocket.send(command, params)
      const promise1 = new Promise(resolve => {
        GmpSocket.on(RespMsgId, res => {
          resolve(res)
        })
      })
      const promise2 = new Promise(resolve => {
        // 设置请求超时响应时间
        setTimeout(() => {
          resolve({
            rsp: {
              code: 100000,
              msg: 'Request Timeout',
            },
          })
        }, TIMEOUT)
      })
      Promise.race([promise1, promise2]).then(res => {
        resolve(res)
      })
    } catch (e) {
      reject(e as string)
    }
  }
  return new Promise(doPost)
}

export const postTakeToken = async (
  url: string,
  data: any,
  login?: boolean,
) => {
  const headConfig = { Authorization: `${getUserId()};${getUserToken()}` }
  const res = await request({
    method: 'post',
    url: getApiUrl() + url,
    timeout: 60000,
    headers: headConfig,
    data,
  })

  if (!login && res?.data?.code === 1002) {
    removeUserId()
    removeUserToken()
    try {
      if (isProduction()) {
        Sentry.captureMessage('auth_error', {
          extra: {
            err_msg: JSON.stringify(res || {}),
            err_headers: JSON.stringify(headConfig || {}),
            err_params: JSON.stringify(data || {}),
            err_url: url,
          },
        })
      }
    } catch (e) {
      //
    }
    setTimeout(() => {
      window.location.reload()
    }, 500)

    return
  }

  return res?.data
}

const URL_ALL_HISTORY = '/game/list_all'
export async function getAllBetHistory(
  game: string,
): Promise<RequestRes<BetInfo[]>> {
  return postTakeToken(URL_ALL_HISTORY, {
    size: 30,
    game_id: game,
  })
}

const URL_HISTORY = '/game/list'
export async function getBetHistory(
  game: string,
  listType: 'mine' | 'all',
): Promise<RequestRes<BetInfo[]>> {
  if (!getUserToken() || !getUserId()) {
    return Promise.resolve({ code: 400, data: [] })
  }
  return postTakeToken(URL_HISTORY, {
    token: getUserToken(),
    user_id: getUserId(),
    list_type: listType,
    game_id: game,
  })
}

export async function getGameHashHistory(
  game_id: number,
  num: number,
): Promise<RequestRes<HashSeedHistory[]>> {
  if (!getUserToken() || !getUserId()) {
    return Promise.resolve({ code: 400, data: [] })
  }
  return postTakeToken('/gmp/record_seed', {
    user_token: getUserToken(),
    user_id: getUserId(),
    num: num || 10,
    game_id: game_id,
  })
}

interface MybetParams {
  game_id: string
  token: string
  user_id: string
  start_time: number
  end_time: number
  order: boolean
  page: {
    size: number
    current: number
  }
}
/** 获取我的下注历史 */
export const getMyBetHistoryGmp = async (
  data: MybetParams,
): Promise<RequestRes> => {
  return post('/gmp/game_record/list', data)
}

interface AllBetsParams {
  game_id: string
  size: number
}

/** 获取全部下注历史 */
export async function getAllBetsHistoryGmp(
  data: AllBetsParams,
): Promise<RequestRes> {
  return post('/game/list_all', data)
}

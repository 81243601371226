import {
  gate,
  protocols_dice,
  protocols_game,
  protocols_game_config,
  protocols_game_fiery_bot,
  protocols_keno,
  protocols_limbo,
  protocols_mines,
  protocols_jackpot,
  protocols_ring,
  protocols_scratch_match,
} from '../../assets/script/protobuf/protobuf'

/** 手动维护 */
export const Cmd_Proto = {
  100001: gate.GateHeartBeatReq,
  100002: gate.GateHeartBeatRsp,

  100003: gate.GateCheckLoginReq,
  100004: gate.GateCheckLoginRsp,

  100005: gate.GateHandshakeReq,
  100006: gate.GateHandshakeRsp,

  // 金额变动推送
  130006: protocols_game.BalanceResp,
  // 游戏记录推送
  130007: protocols_game.PushGameRecord,

  // 获取所有用户最近游戏记录
  150011: protocols_game.GetLastestGameRecordReq,
  150012: protocols_game.GetLastestGameRecordResp,

  130017: protocols_game_config.GameConfigReq,
  130018: protocols_game_config.GameConfigRes,

  130401: protocols_game_config.ClientConfigReq,
  130402: protocols_game_config.ClientConfigRes,

  130021: protocols_jackpot.JackpotPoolCliReq,
  130022: protocols_jackpot.JackpotPoolCliResp,

  130391: protocols_scratch_match.ScratchMatchStatusReq,
  130392: protocols_scratch_match.ScratchMatchStatusResp,

  130393: protocols_scratch_match.ScratchMatchStartReq,
  130394: protocols_scratch_match.ScratchMatchStartResp,

  130395: protocols_scratch_match.ScratchMatchPlayReq,
  130396: protocols_scratch_match.ScratchMatchPlayResp,

  // -------------------------------- Dice ------------------------------------
  130151: protocols_dice.DiceStartReq,
  130152: protocols_dice.DiceStartResp,

  130153: protocols_dice.DiceStatusReq,
  130154: protocols_dice.DiceStatusResp,
  // -------------------------------- Mines ------------------------------------
  130045: protocols_mines.MinesStatusReq,
  130046: protocols_mines.MinesStatusResp,
  130041: protocols_mines.MinesStartReq,
  130042: protocols_mines.MinesStartResp,
  130043: protocols_mines.MinesPlayReq,
  130044: protocols_mines.MinesPlayResp,

  // -------------------------------- Keno -------------------------------------
  130173: protocols_keno.KenoStatusReq,
  130174: protocols_keno.KenoStatusResp,
  130171: protocols_keno.KenoStartReq,
  130172: protocols_keno.KenoStartResp,

  // -------------------------------- Limbo ------------------------------------
  130143: protocols_limbo.LimboStatusReq,
  130144: protocols_limbo.LimboStatusResp,
  130141: protocols_limbo.LimboStartReq,
  130142: protocols_limbo.LimboStartResp,

  // -------------------------------- Fierybot ------------------------------------
  130259: protocols_game_fiery_bot.FieryBotStartReq,
  130260: protocols_game_fiery_bot.FieryBotStartRes,

  130257: protocols_game_fiery_bot.FieryBotStatusReq,
  130258: protocols_game_fiery_bot.FieryBotStatusRes,

  // -------------------------------- Ring ------------------------------------
  130161: protocols_ring.RingStartReq,
  130162: protocols_ring.RingStartResp,

  130163: protocols_ring.RingStatusReq,
  130164: protocols_ring.RingStatusResp,

  130165: protocols_ring.RingRatesReq,
  130166: protocols_ring.RingRatesResp,
} as const

export type Cmd_Proto_Type = keyof typeof Cmd_Proto

/** Req MsgId map Resp MsgId  */
export const GameReqMsgId2RespMsgId: {
  [key: number | Cmd_Proto_Type]: Cmd_Proto_Type
} = {
  //  dice
  130151: 130152,
  130153: 130154,
  130045: 130046,
  130173: 130174,
  130041: 130042,
  130043: 130044,
  130171: 130172,
  130143: 130144,
  130141: 130142,
  130259: 130260,
  130257: 130258,
  150011: 150012,
  130161: 130162,
  130163: 130164,
  130165: 130166,
}

export const GameStatusName2MsgId: { [key: string]: Cmd_Proto_Type } = {
  dice: 130153,
  limbo: 130143,
  ring: 130163,
  fierybot: 130257,
  mines: 130045,
  keno: 130173,
}

export const GameOtherInfo2MsgId: { [key: string]: Cmd_Proto_Type } = {
  ring: 130165,
}

import { VStack } from '@chakra-ui/react'
import { memo, useEffect } from 'react'

import { getVariable } from '@/common/env'
import { FeatureToggle, isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import Advantage from '@/modules/advantage'
import FloatButtons from '@/modules/app-layout/FloatButtons'
import HomeGames from '@/modules/game/logic/home-games/HomeGames'
import HomeApiGames from '@/modules/game/logic/HomeApiGames'
import Ranking from '@/modules/game/ranking'
import property from '@/websites/current/property.json'

import FloatCenterButton from '../app-layout/FloatCenterButton'
import ErrorBoundary from '../error/ErrorBoundary'
import { CollectionGamesProvider } from '../my-games/useGameGrid'
import SearchTigger from '../search-game/SearchTigger'
import HeroSection, {
  BottomH24HoursRank,
  MobileV24HoursRank,
} from './HeroSection'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import GameRecordsAndCollection from './GameRecordsAndCollection'
import withIsUserLogin from './withIsUserLogin'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { GlobalModalKey } from '../global-modal/types'
import { useAppDispatch, useAppSelector } from '../app/store'
import { selecAlltUserInviteUrl } from '../new-referral/referralSlice'
import { getDeviceType } from '@/utils/tools'
import { useMobile } from '@/utils/hooks'
import { isSameOrigin } from '../download-app-guide-modal/downloadAppGuide'
const EnhancedGameRecordsAndCollection = withIsUserLogin(
  GameRecordsAndCollection,
)

const CURRENCY = getCurrencyCode()

const { locale, features } = property

const HIDE_HOME_RANK = getVariable('HIDE_HOME_RANK')

function _Home() {
  const dispatch = useAppDispatch()
  const shareAllUrl = useAppSelector(selecAlltUserInviteUrl)
  const currentUrl = window.location.origin
  const isShareLink = shareAllUrl.some(url => isSameOrigin(url, currentUrl))
  const deviceType = getDeviceType()
  const isMobile = useMobile()

  const showRank =
    !HIDE_HOME_RANK || (HIDE_HOME_RANK && locale.currencies.includes(CURRENCY))
    
    function openAddHomeScreenModal() {
      dispatch(
        openModal({
          key: GlobalModalKey.AddToHomeScreenGuide,
        }),
      )
    }
    //isShareLink && deivceType === 'ios'
  useEffect(() => {
    if(isFeatureEnabledV2('enable_goto_mobile') && isShareLink && deviceType === 'ios' && isMobile) {
      openAddHomeScreenModal()
    }
  }, [isShareLink, deviceType])
  return (
    <VStack alignItems='stretch' spacing='5' mt={{ base: '2.5', md: '5' }}>
      <FeatureToggle feature='gameSearch'>
        <SearchTigger colorScheme='gray' />
      </FeatureToggle>

      <ErrorBoundary>
        <CollectionGamesProvider>
          <HeroSection />

          {isFeatureEnabledV2('game_records_collections') && (
            <EnhancedGameRecordsAndCollection />
          )}

          <BottomH24HoursRank />

          {isFeatureEnabled('gameApiReplaceJson') ? (
            <HomeApiGames />
          ) : (
            <HomeGames />
          )}
        </CollectionGamesProvider>
      </ErrorBoundary>

      {isFeatureEnabled('advantagePage') ? (
        <VStack>
          <Advantage />
        </VStack>
      ) : null}

      <MobileV24HoursRank />

      {showRank && <Ranking />}
      {features.addToHomeScreenBtn ? <FloatCenterButton /> : null}
      <FloatButtons />
    </VStack>
  )
}

const Home = memo(_Home)

export default Home

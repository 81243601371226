import { produce } from 'immer'
import { floor, min, toNumber } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import type { AutoConfig } from '../../../common/components/auto-bet'
import getLimitedResult from '../../../common/utils/getLimitedResult'
import { balanceInfoAtom, gameInfoAtom } from '../../atoms/common-state'
import { useAmountControl } from '..'
import AutoBetConfig from './AutoBetConfig'
import {
  autoBetConfigAtom,
  autoBetInAutoAtom,
  autoBetInputDisabledAtom,
  autoBetRecordAtom,
  autoBetRoundAtom,
  isManualTabAtom,
} from './state'

interface UseAutoBetReturn {
  /** tab mode */
  isManualTab: boolean

  /** 是否处于自动下注状态： true or false */
  isAutoMode: boolean

  /** number of bets */
  numberOfBets: number

  /** bet amount */
  betAmount: number

  /** start  */
  start: (initialBetAmount: number) => void

  stop: () => void

  /** 游戏客户端调用 */
  onResult: (changeResult: number) => void

  inputDisabled: boolean

  // 是否应该停止自动下注
  shouldAutoStop: boolean

  // 是否是无限自动下注
  isInfiniteAutoBet: boolean

  disableInput: () => void

  enableInput: () => void

  getAutoBetConfigComponent: () => typeof AutoBetConfig

  toManualTab: () => void

  toAutoTab: () => void
}

export const RAISE_BASE = 100
let initBetAmount = 1

export function useAutoBet(): UseAutoBetReturn {
  const [isManualTab, setIsManualTab] = useRecoilState(isManualTabAtom)
  const [inputDisabled, setInputDisabled] = useRecoilState(
    autoBetInputDisabledAtom,
  )
  const [isAutoMode, setInAuto] = useRecoilState(autoBetInAutoAtom)
  const [autoBetRound, setAutoRound] = useRecoilState(autoBetRoundAtom)
  const [autoBetConfig, setAutoBetConfig] = useRecoilState(autoBetConfigAtom)
  const [
    { inititalNumberOfBets, betAmount, totalResult, initialBetAmount },
    setRecord,
  ] = useRecoilState(autoBetRecordAtom)

  const { gameId = '' } = useParams()

  const resetAutoBetRound = useResetRecoilState(autoBetRoundAtom)

  const getAutoBetConfigComponent = useCallback(() => AutoBetConfig, [])
  const balance = useRecoilValue(balanceInfoAtom)
  const { config } = useRecoilValue(gameInfoAtom)
  const { to: setAmountControl } = useAmountControl()

  const isInfiniteAutoBet = useMemo(() => {
    return inititalNumberOfBets === 0
  }, [inititalNumberOfBets])

  const onResult = useCallback(
    (changeResult: number) => {
      if (isAutoMode) {
        /** new bet amount */
        const newBetAmount = getLimit(
          // Calculates n rounded down to precision.
          floor(
            getNewBetAmount(
              betAmount,
              changeResult,
              initialBetAmount,
              autoBetConfig,
            ),
          ),
          balance,
          autoBetConfig.max,
          config.maxBetAmount || Infinity,
          config.minBetAmount,
        )

        setAutoRound(autoBetRound + 1)
        setRecord(
          produce(state => {
            state.totalResult = state.totalResult + changeResult
            state.betAmount = newBetAmount
          }),
        )
        setAmountControl(newBetAmount)

        if (inititalNumberOfBets > 0) {
          setAutoBetConfig(
            produce(state => {
              state.count -= 1
            }),
          )
        }
      }
    },
    [
      isAutoMode,
      autoBetRound,
      setAutoRound,
      setRecord,
      initialBetAmount,
      setAutoBetConfig,
      autoBetConfig,
      betAmount,
      balance,
      config.maxBetAmount,
      config.minBetAmount,
      inititalNumberOfBets,
      setAmountControl,
    ],
  )

  const shouldAutoStop: boolean = useMemo(() => {
    const countShouldStop =
      inititalNumberOfBets !== 0 && autoBetConfig.count === 0

    const winShouldStop =
      autoBetConfig.stopWin > 0 && totalResult >= autoBetConfig.stopWin

    const lossShouldStop =
      autoBetConfig.stopLoss > 0 && totalResult <= -autoBetConfig.stopLoss

    return (countShouldStop || winShouldStop || lossShouldStop) && isAutoMode
  }, [
    autoBetConfig.count,
    autoBetConfig.stopLoss,
    autoBetConfig.stopWin,
    inititalNumberOfBets,
    isAutoMode,
    totalResult,
  ])

  const disableInput = useCallback(() => {
    setInputDisabled(true)
  }, [])

  const enableInput = useCallback(() => {
    setInputDisabled(false)
  }, [])

  const toManualTab = useCallback(() => {
    setIsManualTab(true)
  }, [])

  const toAutoTab = useCallback(() => {
    setIsManualTab(false)
  }, [])

  const start = useCallback(
    (initialBetAmount: number) => {
      // console.log('initialBetAmount', initialBetAmount)
      /** 开始的时候需要初始化所有依赖的状态 */
      initBetAmount = initialBetAmount
      setInAuto(true)
      setRecord({
        inititalNumberOfBets: autoBetConfig.count,
        initialBetAmount,
        betAmount: initialBetAmount,
        totalResult: 0,
      })
      resetAutoBetRound()
    },
    [autoBetConfig.count, setInAuto, setRecord, resetAutoBetRound],
  )

  const stop = useCallback(() => {
    try {
      ;(gameId || '')?.toLowerCase() === 'doble' &&
        setAmountControl(initBetAmount)
    } catch (e) {
      //
    }
    setInAuto(false)
    enableInput()
  }, [setInAuto, enableInput, setAmountControl])

  useEffect(() => {
    if (shouldAutoStop) {
      stop()
    }
  }, [shouldAutoStop, stop])

  return useMemo(
    () => ({
      isManualTab,
      isInfiniteAutoBet,
      shouldAutoStop,
      isAutoMode,
      numberOfBets: autoBetConfig.count,
      betAmount,
      start,
      stop,
      onResult,
      getAutoBetConfigComponent,
      inputDisabled,
      disableInput,
      enableInput,
      toManualTab,
      toAutoTab,
    }),
    [
      isManualTab,
      isInfiniteAutoBet,
      shouldAutoStop,
      isAutoMode,
      autoBetConfig.count,
      betAmount,
      start,
      stop,
      onResult,
      getAutoBetConfigComponent,
      inputDisabled,
      disableInput,
      enableInput,
      toManualTab,
      toAutoTab,
    ],
  )
}

/**
 *
 * @param prevBetAmoun 上一次下注金额
 * @param result 游戏结果，可正、负g
 * @param initialBetAmount
 * @param AutoConfig
 * @returns
 */
function getNewBetAmount(
  prevBetAmoun: number,
  result: number,
  initialBetAmount: number,
  { raiseWin, raiseWinOn, raiseLoss, raiseLossOn }: AutoConfig,
): number {
  let newAmount = 0
  if (result > 0) {
    newAmount =
      raiseWinOn && raiseWin > 0
        ? prevBetAmoun * (1 + raiseWin / RAISE_BASE)
        : initialBetAmount
  } else {
    newAmount =
      raiseLossOn && raiseLoss > 0
        ? prevBetAmoun * (1 + raiseLoss / RAISE_BASE)
        : initialBetAmount
  }

  return newAmount
}

function getLimit(
  bet: number,
  balance: number,
  autoMax: number,
  configMax: number,
  configMin: number,
): number {
  const maxArray = []
  if (autoMax > 0) maxArray.push(autoMax)
  if (configMax > 0) maxArray.push(configMax)
  if (balance > 0) maxArray.push(balance)

  const maxValue = maxArray.length === 0 ? 0 : min(maxArray)
  const minValue = configMin
  const result = getLimitedResult(bet, maxValue, minValue)

  // console.log('bet', bet)
  // console.log('balance', balance)
  // console.log('autoMax', autoMax)
  // console.log('configMax', configMax)
  // console.log('configMin', configMin)
  // console.log('result', result)
  return toNumber(result)
}

import { gate, protocols_common } from '../../assets/script/protobuf/protobuf'
import type { Cmd_Proto_Type } from './cmd-proto'
import { Cmd_Proto, GameStatusName2MsgId } from './cmd-proto'
import { getGmpWssBaseUrl } from './env'

export enum TApiType {
  Status = 'status',
  Start = 'start',
  Play = 'play',
  Cashout = 'cashout',
}

export interface TickInfo {
  sendMsgId: number
  apiType: TApiType
  time?: number // 为了上报游戏加载时间
}

export const MshIdMap = {
  //start
  130393: {
    rspMsgId: 130394,
    apiTpye: TApiType.Start,
  },
  //status
  130391: {
    rspMsgId: 130392,
    apiTpye: TApiType.Status,
  },
  //play
  130395: {
    rspMsgId: 130396,
    apiTpye: TApiType.Play,
  },
}

export interface IResponse {
  msgId: number
  rsp: any
}

const RETRY_WAIT_TIME = 3000 // wait for 3000ms before retrying
const API_HOST = getGmpWssBaseUrl() ?? ''
const INHOUSE_PAHT = '/ws'
const WS_BASE_URL = API_HOST
const WS_BASE_URL_COMMON = `${WS_BASE_URL}${INHOUSE_PAHT}`
class GmpSocket {
  private static instance: GmpSocket
  private socket: WebSocket | null = null
  private intervalId: any | null = null
  private isOnline = false
  private token: string | null = null
  private gameId: string | null = null
  private userId: string | null = null
  private openCb: (() => void) | null | undefined = null
  private currency: string | null = null
  private merchantId: string | null = null
  private isActiveClose: boolean = false
  // 重连间隔时间
  private interval = 15000
  // 最大重连次数
  private retryLeft = 5
  // ...

  private listeners: {
    [key: string]: ((data: any) => void)[]
  } = {}
  // 断线情况下发送消息暂存
  private sendListeners: {
    [key: string]: ((data: any) => void)[]
  } = {}

  constructor(private url: string) {}

  public static getInstance(url: string): GmpSocket {
    if (!GmpSocket.instance) {
      GmpSocket.instance = new GmpSocket(url)
    }
    return GmpSocket.instance
  }

  /**
   * 是否连接
   * @returns
   */
  public isConnect() {
    return this.socket && this.socket.readyState === WebSocket.OPEN
  }

  /**
   * 初始化
   * @param token
   * @param interval
   */
  public init({
    token,
    merchantId,
    gameId,
    userId,
    currency,
    wsUrl,
    openCb,
  }: {
    token: string
    merchantId: string | null
    gameId: string | null
    userId: string | null
    currency: string | null
    wsUrl: string | null | undefined
    openCb?: () => void
  }) {
    this.interval = 15000
    this.token = token || ''
    this.merchantId = merchantId
    this.gameId = gameId
    this.userId = userId
    this.currency = currency
    this.openCb = openCb
    this.url = wsUrl || this.url
    this.connect()
  }

  /**
   * 连接socket
   * @returns
   */
  public connect() {
    if (this.socket) {
      return
    }

    this.isActiveClose = false
    this.socket = new WebSocket(this.url)
    this.socket.binaryType = 'arraybuffer'
    this.socket.addEventListener('open', () => {
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.login()
      }
    })

    this.socket.addEventListener('message', this.handleMessage)

    this.socket.addEventListener('close', event => {
      if (!!this.isActiveClose) return
      this.stopPolling()
      this.socket = null
      this.retry()
    })

    this.socket.addEventListener('error', event => {
      if (!!this.isActiveClose) return
      this.stopPolling()
      this.socket = null
      this.retry()
    })
  }

  /**
   * 发送登录token信息
   */
  public login() {
    this.send(protocols_common.MsgID.GATE_CMD_HANDSHAKE_REQ, {
      merchantId: this.merchantId,
    })
  }

  /**
   * 发送消息
   * @param data
   */
  public send(msgId: Cmd_Proto_Type, msg: any) {
    if (this.socket) {
      if (!this.isConnect()) {
        // 未连接
        return
      }
      if (msgId !== protocols_common.MsgID.GATE_CMD_HEARTBEAT_REQ) {
        console.log(msgId, msg)
      }
      const sendBuf = this._msgToBuffer(msgId, msg)
      this.socket && this.socket.send(sendBuf)
    } else {
      if (!this.sendListeners[msgId]) {
        this.sendListeners[msgId] = []
      }
      this.sendListeners[msgId].push(msg)
      this.connect()
    }
  }

  /**
   * 发送因为断线而暂存的消息
   */
  public sendStashMsg() {
    for (let MsgId in this.sendListeners) {
      const msgArr = this.sendListeners[MsgId] || []
      msgArr.forEach(item => {
        this.send(MsgId as unknown as Cmd_Proto_Type, item)
      })
    }
  }

  /**
   * 监听消息
   * @param msgId
   * @param callback
   */
  public on<D = any>(msgId: Cmd_Proto_Type, callback: (data: D) => void) {
    if (!this.listeners[msgId]) {
      this.listeners[msgId] = []
    }
    this.listeners[msgId].push(callback)
    if (!this.socket) {
      this.connect()
    }
  }

  public off(msgId: Cmd_Proto_Type, callback: (data: any) => void) {
    const callbacks = this.listeners[msgId]
    if (callbacks) {
      const index = callbacks.indexOf(callback)
      if (index !== -1) {
        callbacks.splice(index, 1)
      }
      if (callbacks.length === 0) {
        delete this.listeners[msgId]
        if (this.socket) {
          this.socket.removeEventListener('message', this.handleMessage)
        }
      }
    }
  }

  public offAll(exclude?: Cmd_Proto_Type[]) {
    exclude = exclude || []
    for (const MsgId in this.listeners) {
      if (!exclude.includes(MsgId as unknown as Cmd_Proto_Type)) {
        this.listeners[MsgId] = []
      }
    }
  }

  private handleMessage = (event: MessageEvent) => {
    const msg = this._bufferToMsg(event.data)
    console.log(msg)
    if (msg.msgId === protocols_common.MsgID.GATE_CMD_HEARTBEAT_RSP) {
      // 心跳检测
    }

    // 如果收到了消息，已经上线了，开始定时器，发送心跳包
    if (msg.msgId === protocols_common.MsgID.GATE_CMD_HANDSHAKE_RSP) {
      this.sendStashMsg()
      this.startPolling()
      // 请求游戏配置信息
      this.send(protocols_common.MsgID.GAME_CMD_CLIENT_CONFIG_REQ, {
        gameId: this.gameId,
        currency: this.currency,
      })

      // 请求登录验证
      this.send(protocols_common.MsgID.GATE_CMD_AUTH_REQ, {
        merchantId: this.merchantId,
        token: this.token,
        userId: this.userId,
      })

      // 请求奖池配置信息
      this.send(protocols_common.MsgID.GAME_CMD_JACKPOT_POOL_CLI_REQ, {
        gameId: this.gameId,
        currency: this.currency,
      })
      this.openCb && this.openCb()
    }
    // 只要收到消息，说明是online状态
    this.isOnline = true
    if (msg.msgId) {
      const callbacks = this.listeners[msg.msgId]
      if (callbacks) {
        callbacks.forEach(callback => callback(msg))
      }
    }
  }

  /**
   * 关闭socket连接
   */
  public close(isActiveClose?: boolean) {
    this.isActiveClose = !!isActiveClose
    if (this.socket) {
      this.socket.close()
      this.stopPolling()
      this.socket = null
    }
  }

  /**
   * 重试连接
   */
  public retry() {
    this.isOnline = false
    setTimeout(() => this.connect(), RETRY_WAIT_TIME)
    if (this.retryLeft > 0) {
      this.retryLeft--
      this.connect()
    }
  }

  /**
   * 开始心跳检测
   */
  private startPolling() {
    this.intervalId = setInterval(() => {
      if (!this.isOnline) {
        this.close()
        if (this.retryLeft > 0) {
          this.retry()
        }
      } else {
        // 如果是上线状态，那先给他离线，然后发现心跳，让心跳响应把它变回在线
        this.isOnline = false
        if (this.socket?.readyState === WebSocket.OPEN) {
          this.send(protocols_common.MsgID.GATE_CMD_HEARTBEAT_REQ, {})
        }
      }
    }, this.interval)
  }

  /**
   * 停止心跳检测
   */
  private stopPolling() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  private _bufferToMsg(recvBuf: Uint8Array): IResponse {
    const data = new Uint8Array(recvBuf)
    const unpackedData = data.slice(2)
    const unpackedDataLength = unpackedData[0]
    const headerBytes = unpackedData.slice(1, unpackedDataLength + 1)
    const dataLength = data[3]
    const header = gate.Header.decode(headerBytes)

    const msgId = header.msgId as Cmd_Proto_Type

    const msg = data.slice(unpackedDataLength + 3)
    const rsp = Cmd_Proto[msgId] && Cmd_Proto[msgId].decode(msg)
    return { msgId, rsp }
  }

  private _msgToBuffer<T>(msgId: Cmd_Proto_Type, msg: T): Uint8Array {
    const body =
      Cmd_Proto[msgId] && Cmd_Proto[msgId].encode(msg as any).finish()
    const bodyLength = body.length
    const header = gate.Header.encode({ msgId, bodyLength }).finish()
    const len = 2 + 1 + header.length + bodyLength
    const packedData = new Uint8Array(len)
    packedData.set(len > 255 ? [len / 256, len % 256] : [0, len], 0)
    packedData.set([header.length], 2)
    packedData.set(header, 3)
    packedData.set(body, 3 + header.length)
    return packedData
  }
}

const commonSocketIntance = GmpSocket.getInstance(WS_BASE_URL_COMMON)

export default commonSocketIntance

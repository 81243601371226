import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FaWallet } from 'react-icons/fa'

import warningPng from '@/assets/images/notification/warning.png'
import { getAppConfig } from '@/common/app-config'
import { getVariable } from '@/common/env'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { trackClickWallet, trackFirstOpen } from '@/common/tracker'
import AtomBadge from '@/components/atom-badge/AtomBadge'
import BuiltInAvatar from '@/components/built-in-avatar/BuiltInAvatar'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  getIsBindCurrency,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import SportTab from '@/modules/sport/SportTab'
import UserProfilePopover, {
  MemoUserProfilePopover,
} from '@/modules/user/UserProfilePopover'
import {
  selectAvatarId,
  selectBalanceChanged,
  selectUserBalance,
  selectUserIsLogin,
  selectUserRechargeAmount,
  setUserInfoLoaded,
} from '@/modules/user/userSlice'
import { setWithdrawCurrency } from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'
import useGlobalModal from '@/utils/hooks/useGlobalModal'
import { localStorage } from '@/utils/localStorage'
import { formatLocalMoney, getDeviceType } from '@/utils/tools'

import { openWallet } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import LoginModalTab from '../login/LoginModalTab'
import {
  selectBonusShowPosition,
  selectBonusText,
} from '../login/New/newLoginAndSignUpConfigSlice'
import type { Message } from '../notification/types'
import { useLoginLogMutation } from '../user/userApi'
import {
  createDrawerOpenSelector,
  DrawerKey,
  selectMessageList,
  toggleDrawerStart,
} from './app-layout-slice'
import { usePageChangeSubscription } from './usePageChangeNotification'
import WebsiteLogo from './WebsiteLogo'
import { AccountType, useLoginByTelegramAccountMutation } from '../new-login/loginApi'
import { pick } from 'lodash-es'
import { hideGlobalLoading } from '@/components/global-loading/global-loading-slice'
import { selectThirdLoginAuthConfig } from '../new-login/loginAndSignUpAuthConfigSlice'

interface TelegramWebAppUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
}

interface TelegramWebApp {
  initData: string;
  initDataUnsafe: {
      user: TelegramWebAppUser;
      query_id: string;
      chat: {
          id: number;
          type: string;
      };
      theme_params: {
          [key: string]: string;
      };
      [key: string]: any;
  };
  ready(): void;
}
interface Window {
  Telegram: {
      WebApp: TelegramWebApp;
  };
}
function _Header() {
  const { t } = useTranslation()
  const isLogin = useAppSelector(selectUserIsLogin)
  const thirdPartLogin = useAppSelector(selectThirdLoginAuthConfig)
  const { enable_telegram } = thirdPartLogin
   
  const avatarId = useAppSelector(selectAvatarId)
  const { open } = useGlobalModal()
  const [userId, token] = useUserAuth()

  const handleOpenLoginModal = (val: LoginModalTab) => {
    open({
      key: GlobalModalKey.Login,
      data: { index: val },
    })
  }
  const dispatch = useAppDispatch()
  const toast = useToast()

  const [loginLog, { isSuccess: isLoginLogSuccess }] = useLoginLogMutation()

  // const { error: isLoadingLoginAndSignUpConfigError } =
  //   useGeLoginAndSignUpConfigQuery()

  // 保活登录埋点
  const loginBurialPoint = useCallback(() => {
    if (!isLogin || !userId || !token) return
    // 从缓存中获取下次埋点时间戳
    const nextLoginLogTimeStamp = localStorage.getItem('nextLoginLogTimeStamp')
    if (!nextLoginLogTimeStamp) {
      localStorage.setItem(
        'nextLoginLogTimeStamp',
        dayjs().add(1, 'day').startOf('day').valueOf().toString(),
      )
    }
    if (
      nextLoginLogTimeStamp &&
      dayjs().valueOf() > Number(nextLoginLogTimeStamp)
    ) {
      // 如果缓存中的时间戳小于当前时间戳，说明是第二天了，直接埋点
      loginLog({
        user_id: userId,
        token,
      })
    } else {
      if (dayjs().valueOf() > Number(nextLoginLogTimeStamp)) {
        // 如果缓存中的时间戳小于当前时间戳，说明是第二天了，直接埋点
        loginLog({
          user_id: userId,
          token,
        })
      }
    }
  }, [isLogin, loginLog, token, userId])

  // 首次打开埋点
  const firstOpenBurialPoint = useCallback(() => {
    if (!isLogin || !userId || !token) return
    const firstOpen = localStorage.getItem('firstOpen')
    if (!firstOpen) {
      localStorage.setItem('firstOpen', 'true')
      trackFirstOpen()
    }
  }, [isLogin, token, userId])

  useEffect(() => {
    firstOpenBurialPoint()
  }, [firstOpenBurialPoint])
  const [telegramLogin, { error: newLoginError } ] = useLoginByTelegramAccountMutation()

  
  const handleLogin = useCallback(async (user: any, web_app_data: string) => {
    try {
      if (user) {
        dispatch(setUserInfoLoaded(false))
        await telegramLogin({
          account_type: AccountType.AT_TELEGRAM,
          account_value: JSON.stringify(
            pick(user, ['id', 'first_name', 'photo_url', 'hash', 'auth_date', 'username', 'last_name']),
          ),
          web_app_data
        }).unwrap()

      }
    } catch (error: any) {
      toast({
        title: t(error?.message || 'LOGIN_EXCEPTION'),
        status: 'error',
      })
      dispatch(setUserInfoLoaded(true))
    } finally {
      dispatch(hideGlobalLoading())
    }
  }, [])
  useEffect(() => {
    if (window?.Telegram?.WebApp?.initDataUnsafe?.user && !isLogin && enable_telegram) {
      // The site is being opened as a Telegram Mini App
      const data = {
        ...window?.Telegram?.WebApp?.initDataUnsafe?.user,
        hash: window?.Telegram?.WebApp?.initDataUnsafe?.hash,
        auth_date: +window?.Telegram?.WebApp?.initDataUnsafe?.auth_date,
      }

      handleLogin(data, window?.Telegram?.WebApp?.initData)
      console.log('Opened as a Telegram Mini App');
  } else {
      // The site is not being opened as a Telegram Mini App
      console.log('Not opened as a Telegram Mini App');
  }
  }, [enable_telegram, isLogin, window?.Telegram?.WebApp?.initDataUnsafe?.user])
  useEffect(() => {
    // 如果埋点成功，设置下次埋点时间戳为明天凌晨
    if (isLoginLogSuccess) {
      localStorage.setItem(
        'nextLoginLogTimeStamp',
        dayjs().add(1, 'day').startOf('day').valueOf().toString(),
      )
    }
  }, [isLoginLogSuccess])

  usePageChangeSubscription(loginBurialPoint)

  const headerRightElemsRender = () => {
    if (isLogin) {
      return (
        <HStack h='full' spacing='3'>
          <Flex
            borderRadius='md'
            bg='gray.200'
            alignItems='center'
            pos='relative'
          >
            <WalletBonus />
            <UserBalanceText />
            <WalletButton />
          </Flex>
          {isFeatureEnabledV2('new_personal_center') ? (
            <UserProfilePopover>
              <Center h='full'>
                <BuiltInAvatar
                  avatarId={avatarId}
                  size='sm'
                  width='30px'
                  height='30px'
                />
              </Center>
            </UserProfilePopover>
          ) : (
            <MemoUserProfilePopover>
              <Center h='full'>
                <BuiltInAvatar
                  avatarId={avatarId}
                  size='sm'
                  width='30px'
                  height='30px'
                />
              </Center>
            </MemoUserProfilePopover>
          )}
          <RightDrawerButton />
        </HStack>
      )
    } else {
      return (
        <HStack h='full' spacing='1'>
          <Button
            variant='ghost'
            size='mdmd'
            pl='0'
            onClick={() => {
              // if (isLoadingLoginAndSignUpConfigError) {
              //   dispatch(
              //     openModal({ key: GlobalModalKey.LoginAndSignUpConfigError }),
              //   )
              //   return
              // }
              handleOpenLoginModal(LoginModalTab.SignIn)
            }}
          >
            {t('USER_LOGIN')}
          </Button>
          <Button
            colorScheme={getAppConfig(
              'header.signUpButton.colorScheme',
              'fill-seco',
            )}
            size='mdmd'
            onClick={() => {
              // if (isLoadingLoginAndSignUpConfigError) {
              //   dispatch(
              //     openModal({ key: GlobalModalKey.LoginAndSignUpConfigError }),
              //   )
              //   return
              // }
              handleOpenLoginModal(LoginModalTab.SignUp)
            }}
          >
            {t('USER_SIGNUP')}
          </Button>
        </HStack>
      )
    }
  }

  return (
    <HStack h='full' w='full' bg='bg.appLayout.header' spacing='0' px='3'>
      <MenuButton />
      <WebsiteLogo />
      <SportTab type='header' />
      <Spacer />
      {headerRightElemsRender()}
    </HStack>
  )
}

const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')

function UserBalanceText() {
  const balance = useAppSelector(selectUserBalance)
  const balanceChanged = useAppSelector(selectBalanceChanged)
  const topBalanceContainerRef = useRef<HTMLDivElement | null>(null)
  const balanceChangedCountRef = useRef(0)

  useEffect(() => {
    const createPayoutEffectText = (payout: number): HTMLSpanElement => {
      const span = document.createElement('span')
      span.innerText = `+ ${formatLocalMoney(payout || 0)}`
      span.classList.add('payoutEffectText')
      span.addEventListener('animationend', () => animationEnd(span))
      return span
    }

    const animationEnd = (span: HTMLSpanElement) => {
      topBalanceContainerRef.current?.removeChild(span)
      balanceChangedCountRef.current -= 1
    }

    const MAX_CHANGE_NUMBER = 5

    if (
      topBalanceContainerRef.current === null ||
      !balanceChanged?.amount ||
      balanceChangedCountRef.current > MAX_CHANGE_NUMBER
    ) {
      return
    } else {
      topBalanceContainerRef.current.appendChild(
        createPayoutEffectText(balanceChanged?.amount),
      )
      balanceChangedCountRef.current += 1
    }
  }, [balanceChanged])

  return (
    <div ref={topBalanceContainerRef}>
      <Text px='3' textStyle='h6' whiteSpace='nowrap' color='text.accent'>
        {`${formatLocalMoney(balance?.amount || 0)}`}
      </Text>
    </div>
  )
}
const DEVICE = getDeviceType()

function WalletButton() {
  const dispatch = useAppDispatch()
  const isBindCurrency = useAppSelector(getIsBindCurrency)

  const walletClick = () => {
    // 点击wallet埋点
    trackClickWallet()
    if (isBindCurrency || !SHOW_BIND_SELECT) {
      dispatch(openWallet())
      return
    }
    dispatch(switchBindModal())
  }
  return (
    <IconButton
      colorScheme={getAppConfig('header.walletButton.colorScheme', 'fill-seco')}
      height='32px'
      width='44px'
      borderRadius='md'
      icon={<Icon as={FaWallet} boxSize={4} />}
      aria-label='wallet'
      onClick={() => {
        dispatch(setWithdrawCurrency(''))
        walletClick()
      }}
    />
  )
}

function MenuButton() {
  const dispatch = useAppDispatch()

  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )

  const handleToggleLeftDrawerBtnClick = () => {
    dispatch(toggleDrawerStart(DrawerKey.Left))
  }

  return (
    <IconButton
      display={['none', 'inline-flex']}
      size='md'
      icon={
        <Icon
          boxSize='30px'
          as={isLeftDrawerOpen ? SharedIcon.Meunl : SharedIcon.Meunr}
        />
      }
      color='gray.700'
      variant='ghost'
      aria-label='menu fold'
      onClick={handleToggleLeftDrawerBtnClick}
    />
  )
}

function rewardIsExpire(item: Message) {
  const expireTimestamp = dayjs(
    item?.created_at ? item.created_at * 1000 : '',
  ).add(item?.rewards?.expire_day || 0, 'day')

  const nowTimestamp = dayjs().valueOf()
  if (expireTimestamp.valueOf() <= nowTimestamp) {
    return true
  }
  return false
}

function RightDrawerButton() {
  const dispatch = useAppDispatch()
  const messageList = useAppSelector(selectMessageList)

  const unReadNum = useMemo(() => {
    return (
      messageList.find(
        item =>
          !item.is_claim_rewards && item.rewards?.num && !rewardIsExpire(item),
      ) || messageList.filter(item => !item.is_read).length
    )
  }, [messageList])

  const hasUnread = useMemo(() => {
    return typeof unReadNum === 'number' && unReadNum > 0
  }, [unReadNum])

  const handleToggleRightDrawerBtnClick = () => {
    dispatch(toggleDrawerStart(DrawerKey.Right))
  }

  return (
    <IconButton
      variant='unstyled'
      aria-label='menu fold'
      onClick={handleToggleRightDrawerBtnClick}
      icon={
        typeof unReadNum === 'number' ? (
          <AtomBadge
            show={hasUnread}
            size='md'
            display='inline'
            color='text.base'
            showNumber
            number={unReadNum}
            scene='header'
          >
            <Icon as={SharedIcon.Email} boxSize='6' />
          </AtomBadge>
        ) : (
          <Box position='relative'>
            <Image
              src={warningPng}
              w='16px'
              h='16px'
              position='absolute'
              top='-4px'
              right='-2px'
            />
            <Icon as={SharedIcon.Email} boxSize='6' />
          </Box>
        )
      }
    />
  )
}

const WalletBonus = () => {
  const useRechargeAmount = useAppSelector(selectUserRechargeAmount)

  const bonusShowPosition = useAppSelector(selectBonusShowPosition) ?? []

  const bonusText = useAppSelector(selectBonusText)

  const showBonus =
    bonusShowPosition.includes('wallet') && useRechargeAmount === 0
  return (
    <>
      {showBonus ? (
        <Box
          position={'absolute'}
          borderRadius='12px'
          bgColor='prim.500'
          zIndex='4'
          px='6px'
          py='3px'
          top='-16px'
          left='-2'
          _after={{
            content: "''",
            position: 'absolute',
            left: '50%',
            top: '100%',
            width: '0px',
            height: '0',
            borderLeft: '6px solid transparent',
            borderRight: '4px solid transparent',
            borderTop: '4px solid var(--chakra-colors-prim-500)',
          }}
        >
          <Text
            fontSize='8px'
            color='white'
            textAlign='center'
            whiteSpace='nowrap'
          >
            🎁 {bonusText}
          </Text>
        </Box>
      ) : null}
    </>
  )
}

const Header = memo(_Header)

export default Header

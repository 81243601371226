import axios from 'axios'

export interface RequestRes<T = unknown> {
  code: number
  message?: string
  data: T
  rsp: {
    code?: number
    data: T
    msg?: string
  }
}

export default axios.create({})

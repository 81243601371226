import { atom } from 'recoil'

import type { AutoConfig } from '../../../common/components/auto-bet'

export const autoBetInputDisabledAtom = atom<boolean>({
  key: 'autoBetInputDisabledAtom',
  default: false,
})

export const autoBetRoundAtom = atom<number>({
  key: 'autoBetRoundAtom',
  default: 0,
})

export const isManualTabAtom = atom<boolean>({
  key: 'isManualTabAtom',
  default: true,
})

export const autoBetInAutoAtom = atom<boolean>({
  key: 'autoBetInAutoAtom',
  default: false,
})

export const autoBetConfigAtom = atom<AutoConfig>({
  key: 'autoBetConfigAtom',
  default: {
    count: 0,
    stopWin: 0,
    stopLoss: 0,
    max: 0,
    raiseWin: 0,
    raiseLoss: 0,
    raiseWinOn: false,
    raiseLossOn: false,
    initAmount: 1,
  },
})

export interface AutoModeGameRecords {
  inititalNumberOfBets: number

  /** first amount value in auto */
  initialBetAmount: number

  /** bet amount */
  betAmount: number

  /** cumulative results 可正可负 */
  totalResult: number
}

export const autoBetRecordAtom = atom<AutoModeGameRecords>({
  key: 'autoBetRecordAtom',
  default: {
    inititalNumberOfBets: 0,
    initialBetAmount: 0,
    betAmount: 0,
    totalResult: 0,
  },
})

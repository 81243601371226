import { floor } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { gameInfoAtom } from '../../atoms/common-state'
import { useFinalMaxMin } from '../useMaxMin'
import amountControlStateAtom from './amount-control-state'
import LogicAmountControl from './component'

export interface useAmountControlReturnType {
  value: number
  toMax: () => void
  toMin: () => void
  toHalf: () => void
  toDouble: () => void

  /** setter */
  to: (value: number, noLimit?: boolean) => void

  /** 重置状态，防止传递到其他游戏 */
  reset: () => void

  getComponent: () => typeof LogicAmountControl
}

export function useAmountControl(): useAmountControlReturnType {
  const [amount, setAmount] = useRecoilState(amountControlStateAtom)
  const resetAmount = useResetRecoilState(amountControlStateAtom)
  const { config } = useRecoilValue(gameInfoAtom)
  const { getMax, getMin } = useFinalMaxMin()

  const integer = config.integer ?? true

  const toMax = useCallback(() => {
    setAmount(integer ? floor(getMax()) : getMax())
  }, [getMax, integer, setAmount])

  const toMin = useCallback(() => {
    setAmount(getMin())
  }, [setAmount, getMin])

  const toDouble = useCallback(() => {
    let result = amount * 2

    if (result > getMax()) result = getMax()

    setAmount(integer ? floor(result) : result)
  }, [amount, setAmount, integer, getMax])

  const toHalf = useCallback(() => {
    let result = amount / 2

    if (result < getMin()) result = getMin()

    setAmount(integer ? floor(result) : result)
  }, [amount, setAmount, integer, getMin])

  const getComponent = useCallback(() => {
    return LogicAmountControl
  }, [])

  const to = useCallback(
    (value: number, noLimit?: boolean) => {
      let result = value
      if (!noLimit) {
        if (result > getMax()) result = getMax()
        if (result < getMin()) result = getMin()
      }
      return setAmount(integer ? floor(result) : result)
    },
    [setAmount, getMax, getMin, integer],
  )

  return useMemo(
    () => ({
      value: amount,
      getComponent,
      toMax,
      toMin,
      toDouble,
      toHalf,
      to,
      reset: resetAmount,
    }),
    [amount, getComponent, toMax, toMin, toDouble, toHalf, to, resetAmount],
  )
}
